import { useLazyIsPpoAvailableQuery } from '../../../shared/api/medicare';
import { useDebouncedEffect } from '@coverright/utils';
import { PlansFilterInput } from '@coverright/data-access/types/medicare';
import * as React from 'react';

export default function useIsPpoAvailable(
  zip?: string,
  countyName?: string,
  filters?: PlansFilterInput
) {
  const [getIsPPOAvailable, data] = useLazyIsPpoAvailableQuery();

  useDebouncedEffect(
    () => {
      if (zip && countyName && filters) {
        getIsPPOAvailable({
          filter: { ...filters, zip, countyName, planYear: filters.planYear as any },
        });
      }
    },
    500,
    []
  );

  return React.useMemo(() => {
    return (data.data?.planTypes.find((t) => t.name === 'PPO')?.count || 0) > 0;
  }, [data]);
}
