import { MedigapPlanOutputWithDetails } from '@coverright/data-access/medigap';
import { Typography } from '@mui/material';
import { MedigapPlanName } from '@coverright/data-access/types/medigap';
import { Tooltip } from '@coverright/ui/inputs';
import React from 'react';

export const Deductibles = ({
  plan,
}: {
  plan?: MedigapPlanOutputWithDetails;
}) => {
  if (!plan) return null;

  return (
    <div>
      <Typography className={'bold fs-14 mb-8 lh-14'}>Deductibles:</Typography>
      {[MedigapPlanName.Hdf, MedigapPlanName.Hdg].includes(
        plan.planName as MedigapPlanName
      ) && (
        <Tooltip
          placement={'top'}
          arrow
          title={
            'This is the amount you must pay out of pocket under Original Medicare for your hospital (Part A) costs before your plan coverage begins.  This amount covers your first 60 days regardless of how long your hospital stay is.  Medicare refers to this as the Part A (hospital) deductible.'
          }
        >
          <Typography className={'fs-12 lh-15 mb-8'}>
            <strong>{plan.deductibles.partA}</strong> annual deductible
          </Typography>
        </Tooltip>
      )}
      {![MedigapPlanName.Hdf, MedigapPlanName.Hdg].includes(
        plan.planName as MedigapPlanName
      ) && (
        <Tooltip
          placement={'top'}
          arrow
          title={
            'The amount you must pay out of pocket for your Part A (Hospital) costs before your plan coverage begins.'
          }
        >
          <Typography className={'fs-12 lh-15 mb-8'}>
            <strong>{plan.deductibles.partA}</strong> Hospital (Part A
            deductible)
          </Typography>
        </Tooltip>
      )}
      {plan.deductibles.partB && (
        <Tooltip
          placement={'top'}
          arrow
          title={
            'The amount you must pay out of pocket for your Part B (Medical) costs before your plan coverage begins.'
          }
        >
          <Typography className={'fs-12 lh-15'}>
            <strong>{plan.deductibles.partB}</strong> Medical (Part B
            deductible)
          </Typography>
        </Tooltip>
      )}
    </div>
  );
};
