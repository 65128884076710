import { Box, Button, Card, Typography } from '@mui/material';
import { PlayCircle } from '@phosphor-icons/react';
import React from 'react';
import VideoModal from '../../components/video-modal';

interface Props {
  title: string;
  id: string;
  thumb: string;
  video: string;
  modalTitle: string;
}

export function VideoCta(props: Props) {
  const [videoOpen, setVideoOpen] = React.useState(false);
  return (
    <>
      <VideoModal
        title={props.modalTitle}
        url={props.video}
        open={videoOpen}
        onClose={() => setVideoOpen(false)}
      />
      <Card sx={{ p: 4, maxWidth: { lg: 294 }, minWidth: 294 }}>
        <Typography variant={'h4'} className={'fs-24 lh-24'}>
          {props.title}
        </Typography>
        <Box sx={{ my: 3, width: 1 }} component={'img'} src={props.thumb} />

        <Button
          fullWidth
          variant={'contained'}
          onClick={() => setVideoOpen(true)}
          id={props.id}
          sx={{ justifyContent: 'flex-start', px: 2, fontWeight: 500 }}
          startIcon={<PlayCircle size={24} color="#FFF" weight="fill" />}
        >
          Watch Video
        </Button>
      </Card>
    </>
  );
}
