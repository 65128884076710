import { MaPlanQuery } from '../../../shared/api/medicare';

export function buildBenefitDescriptions(plan?: MaPlanQuery['plan']) {
  const result = [];
  if (plan) {
    if (plan.dental?.maxPlanCoverageAmount) {
      result.push({
        label: `$${plan.dental?.maxPlanCoverageAmount} towards dental treatment`,
        link: 'dental_vision_hearing/dental',
      });
    }
    if (plan.vision?.allEyewearMaxMount || plan.vision?.eyeExamsMaxAmount) {
      result.push({
        label: [
          plan.vision?.allEyewearMaxMount
            ? `$${plan.vision?.allEyewearMaxMount} towards eyewear`
            : '',
          plan.vision?.eyeExamsMaxAmount
            ? `$${plan.vision?.eyeExamsMaxAmount} towards vision`
            : '',
        ].join(' '),
        link: 'dental_vision_hearing/hearing',
      });
    }
    if (plan.hearing?.coverageAmountForEars) {
      result.push({
        label: `$${plan.hearing?.coverageAmountForEars} towards hearing treatment`,
        link: 'dental_vision_hearing/hearing',
      });
    }
  }
  return result;
}
