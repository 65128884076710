import { Button } from '@mui/material';
import * as React from 'react';
import {
  hasEnrollments,
  postEnrollmentNavIndex,
  useAppSelector,
} from '../../../shared';
import { useNavigate } from 'react-router';
import { routes } from '../../../app/router-new';

export function NavAppBarButtons() {
  const navigate = useNavigate();
  const activeIndex = useAppSelector(postEnrollmentNavIndex);
  const active = useAppSelector(hasEnrollments);

  if (!active) {
    return null;
  }

  return (
    <>
      <Button
        variant={'rounded'}
        sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
        color={activeIndex === 1 ? 'success' : undefined}
        onClick={() => navigate(routes.planFinder.value)}
        size={'small'}
      >
        Shop Medicare Plans
      </Button>
      <Button
        variant={'rounded'}
        sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
        color={activeIndex === 0 ? 'success' : undefined}
        onClick={() => navigate(routes.dashboard.value)}
        size={'small'}
      >
        Health Dashboard
      </Button>
    </>
  );
}
