import React from 'react';
import { useLazyPlansCountByZipQuery } from '../../../shared/api/medicare';

export default function usePlansCount(zip?: string) {
  const [getPlansCount, plansCountData] = useLazyPlansCountByZipQuery();

  React.useEffect(() => {
    if (zip) {
      getPlansCount({ zip });
    }
  }, [zip]);

  const maPlansPercent = React.useMemo(() => {
    if (plansCountData.data) {
      return Math.floor(
        (plansCountData.data!.plansCountByZip.maPlansCount * 100) /
          plansCountData.data!.plansCountByZip.maPlansTotalCount
      );
    }
    return null;
  }, [plansCountData]);

  const maPlansCount = React.useMemo(() => {
    if (plansCountData.data) {
      return plansCountData.data!.plansCountByZip.maPlansCount;
    }
    return null;
  }, [plansCountData]);

  const maOrgCount = React.useMemo(() => {
    if (plansCountData.data) {
      return plansCountData.data!.plansCountByZip.maPlansParentOrgCount;
    }
    return null;
  }, [plansCountData]);

  const pdpPlansCount = React.useMemo(() => {
    if (plansCountData.data) {
      return plansCountData.data!.plansCountByZip.pdpPlansCount;
    }
    return null;
  }, [plansCountData]);

  const pdpOrgCount = React.useMemo(() => {
    if (plansCountData.data) {
      return plansCountData.data!.plansCountByZip.pdpPlansParentOrgCount;
    }
    return null;
  }, [plansCountData]);

  return {
    maPlansPercent,
    maPlansCount,
    maOrgCount,
    pdpPlansCount,
    pdpOrgCount,
    loading: plansCountData.isLoading || plansCountData.isUninitialized,
  };
}
