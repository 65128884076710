import { PlanFilter } from './plan-filter';
import React from 'react';
import { FilterProps, ListItem } from '../../../shared';

export function CompanyFilter({
  value,
  companies,
  onChange,
}: FilterProps & { companies: ListItem[] }) {
  return (
    <PlanFilter
      value={value}
      onChange={onChange}
      label={'Insurance'}
      data-test={'company-filter'}
      options={companies.map((t) => ({
        ...t,
        checked: value?.includes(t.value),
      }))}
      multiple
    />
  );
}
