import React from 'react';
import { getAgeByBirthdate, getStoredUser, tooltips } from '@coverright/utils';
import { Box, Typography } from '@mui/material';
import { Tooltip } from '@coverright/ui/inputs';
import useMedigapPremiumRanges from '../api/use-medigap-premium-ranges';
import { useAppSelector } from '../../../shared';
import { profile } from '../../../entities/profile';

export default function MedigapPremiumRangesDisclamer() {
  const user = useAppSelector(profile);
  const medigapPremiumRanges = useMedigapPremiumRanges();

  return React.useMemo(() => {
    if (getAgeByBirthdate(user?.birthDate) >= 63) {
      return (
        <Typography>
          <Box
            component={'span'}
            sx={{ display: !medigapPremiumRanges ? 'none' : undefined }}
          >
            {medigapPremiumRanges}
          </Box>
          <Box
            sx={{
              pl: '17px',
              width: '30px',
              height: '10px',
              display: !medigapPremiumRanges ? 'inline-block' : 'none',
            }}
            component={'span'}
          >
            <span className="dot-flashing"></span>
          </Box>{' '}
          per month - based on your zip code, this is the minimum you would need
          to pay in order to purchase comprehensive coverage with limited-to-no
          out-of-pocket costs. This is in addition to your{' '}
          <Tooltip title={tooltips.standardPartBPremium} arrow>
            <span className={'underline'}>standard Part B Premium</span>
          </Tooltip>
          .
        </Typography>
      );
    } else {
      return (
        <Typography>
          It's looks like you are currently under 65. Depending on your
          location, private plan options may be more limited for those who are
          eligible for Medicare and under the age of 65.
        </Typography>
      );
    }
  }, [medigapPremiumRanges, user]);
}
