import { Box, Typography } from '@mui/material';
import { CheckCircle } from '@phosphor-icons/react';
import React from 'react';

const HasNoDiscountsAlert = () => (
  <Box
    sx={{
      borderRadius: '16px',
      border: '1px dashed #F9E69A',
      background: '#E8ECED',
      p: 3,
      maxWidth: 866,
    }}
  >
    <Box
      data-test={'HasNoDiscountsAlert'}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <CheckCircle size={24} color={'#0B741C'} weight={'fill'} />
        <Typography className={'fs-24 semi-bold lh-32'}>
          Status: You are receiving the best deal
        </Typography>
      </Box>
    </Box>

    <Typography>
      You are currently getting the best possible deal we can find for the drugs
      you have added to your CoverRight profile! Remember to keep your drugs up
      to date in your profile.
    </Typography>
  </Box>
);

export default HasNoDiscountsAlert;
