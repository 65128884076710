import { useLazyMedigapPremiumRangesQuery } from '../../../shared/api/medigap';
import React, { useEffect } from 'react';
import { useAppSelector } from '../../../shared';
import { profile as profileSelector } from '../../../entities/profile';
import {
  getAgeByBirthdate,
  getNumbersFromString,
  isPersonEligible,
  showRange,
  toCurrency,
} from '@coverright/utils';
import {
  CsgGender,
  MedigapPlanName,
} from '@coverright/data-access/types/medigap';
import * as _ from 'lodash';

export default function useMedigapPremiumRanges() {
  const profile = useAppSelector(profileSelector);
  const [getRanges, { data }] = useLazyMedigapPremiumRangesQuery();

  useEffect(() => {
    if (profile?.zip) {
      getRanges({
        zip: profile.zip,
        age: getAgeByBirthdate(profile.birthDate),
        tobacco: !!profile.tobacco,
        gender: profile.gender as CsgGender,
      });
    }
  }, [profile]);

  return React.useMemo(() => {
    const ranges = data?.medigapPremiumRanges
      .filter((r) =>
        [
          MedigapPlanName.F,
          MedigapPlanName.G,
          MedigapPlanName.N,
          MedigapPlanName.Hdg,
          MedigapPlanName.Hdf,
        ].includes(r.planName)
      )
      .filter((r) =>
        isPersonEligible(profile?.birthDate)
          ? true
          : ![
              MedigapPlanName.F,
              MedigapPlanName.C,
              MedigapPlanName.Hdf,
            ].includes(r.planName)
      );
    if (ranges) {
      const minPremium = Math.round(
        _.min(
          _.flatten(ranges.map((v) => getNumbersFromString(v.rangeDescription)))
        ) as number
      );
      const maxPremium = Math.round(
        _.max(
          _.flatten(ranges.map((v) => getNumbersFromString(v.rangeDescription)))
        ) as number
      );
      return showRange(
        toCurrency(minPremium),
        toCurrency(maxPremium),
        'per month for Medicare Supplement'
      );
    }
    return '... per month for Medicare Supplement';
  }, [data]);
}
