import { Typography } from '@mui/material';
import * as React from 'react';
import { convertBold, convertList } from '@coverright/utils';

export type ExtraBenefitValue =
  | undefined
  | null
  | string
  | JSX.Element
  | string[]
  | JSX.Element[];

export function convertExtraBenefitValue(
  value: ExtraBenefitValue
): JSX.Element | undefined {
  if (!value || (Array.isArray(value) && value.length == 0)) {
    return undefined;
  } else if (typeof value === 'string') {
    return <Typography>{value}</Typography>;
  } else if (Array.isArray(value)) {
    return (
      <>
        {value.map((line, i) => (
          <React.Fragment key={i}>
            {typeof line === 'string' && (
              <Typography
                sx={{ minHeight: 24 }}
                dangerouslySetInnerHTML={{
                  __html: convertList(convertBold(line)),
                }}
              ></Typography>
            )}
            {typeof line !== 'string' && <>{line}</>}
          </React.Fragment>
        ))}
      </>
    );
  } else {
    return value;
  }
}
``;
