import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../../shared';
import { pdpFilters, setPdpFilter } from '../../../entities';
import { PropsWithChildren, useContext } from 'react';
import { PdpPlansFilterInput } from '@coverright/data-access/types/medicare';

interface IPdpFiltersContext {
  filters?: PdpPlansFilterInput;
  buildHandler: (key: keyof PdpPlansFilterInput) => (value: any) => void;
}

export const PdpFiltersContext = React.createContext<IPdpFiltersContext>({
  buildHandler: () => () => {},
});

const Provider = ({ children }: PropsWithChildren) => {
  const filters = useAppSelector(pdpFilters);
  const dispatch = useAppDispatch();

  const buildHandler = (key: keyof PdpPlansFilterInput) => (value: any) => {
    dispatch(setPdpFilter({ key, value }));
  };

  return (
    <PdpFiltersContext.Provider value={{ buildHandler, filters }}>
      {children}
    </PdpFiltersContext.Provider>
  );
};

export const withPdpFiltersContext = (WrappedComponent: any) => (props: any) =>
  (
    <Provider>
      <WrappedComponent {...props} />
    </Provider>
  );

export function usePdpFilters() {
  return useContext(PdpFiltersContext);
}
