import { useSaveMedicareQuoteMutation } from '../../../shared/api/medicare';
import { useAppSelector } from '../../../shared';
import {
  medicareQuote,
  customAnswers as customAnswersSelector,
} from '../../../entities';

export default function useSaveCustomAnswers() {
  const [save, { isLoading }] = useSaveMedicareQuoteMutation();
  const quote = useAppSelector(medicareQuote);
  const customAnswers = useAppSelector(customAnswersSelector);

  return () =>
    save({
      data: {
        customAnswers: JSON.stringify(customAnswers),
        id: quote?.id,
      },
    });
}
