import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Collapse, Divider, Skeleton, Stack } from '@mui/material';
import * as _ from 'lodash';
import {
  useAppSelector,
  enrolledPlan as enrolledPlanSelector,
  useAppDispatch,
  SideLayout,
} from '../../shared';
import {
  medicareQuote,
  fetchBenefits,
  benefits as benefitsSelector,
} from '../../entities';
import Banner from './ui/banner';
import { DashboardPlanCardContent } from '../../components/dashboard/dashboard-plan-card';
import ToggleAllButton from './ui/toggle-all-button';
import { Benefit } from './ui/benefit';
import useToggle from './lib/use-toggle';

export default function (props: any) {
  const quote = useAppSelector(medicareQuote);
  const benefits = useAppSelector(benefitsSelector);
  const enrolledPlan = useAppSelector((state) =>
    enrolledPlanSelector(state, 'MA')
  );
  const dispatch = useAppDispatch();

  const [alertOpen, setAlertOpen] = useState(false);

  const { toggleOpen, openedBlocks, toggleAll, exclusiveOpen } = useToggle(
    Object.keys(benefits).map(_.snakeCase)
  );

  useEffect(() => {
    if (enrolledPlan && quote?.id) {
      dispatch(fetchBenefits({ enrolledPlan, quoteId: quote?.id }));
    }
  }, [enrolledPlan, quote]);

  const benefitsLength = Object.keys(benefits).length;

  useEffect(() => {
    if (benefitsLength) {
      const params = new URLSearchParams(document.location.search);
      if (params.has('benefit')) {
        goToBenefit(params.get('benefit')!, 600);
      }
      setAlertOpen(true);
    }
  }, [benefitsLength]);

  const goToBenefit = (benefitName: string, delay = 0) => {
    exclusiveOpen(
      benefitName.includes('/') ? benefitName.split('/')[0] : benefitName
    );
    setTimeout(
      () =>
        document
          .getElementById(benefitName)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
      delay
    );
  };

  return (
    <SideLayout
      title={'Benefits & Coverage'}
      subtitle={
        'CoverRight ensures transparency and guarantees no hidden benefits.'
      }
      slots={{
        headerSide: (
          <div>
            <ToggleAllButton
              onClick={toggleAll}
              opened={openedBlocks.length === benefitsLength}
            />
          </div>
        ),
        start: (
          <Banner
            open={alertOpen}
            onClick={goToBenefit}
            onClose={() => setAlertOpen(false)}
          />
        ),
      }}
    >
      <Card sx={{ p: 3 }}>
        <DashboardPlanCardContent plan={enrolledPlan} hideButton />

        <Collapse in={!!benefitsLength}>
          <Stack sx={{ mt: 4 }} spacing={4}>
            {Object.keys(benefits)
              .sort()
              .map((key, i) => (
                <Benefit
                  key={i}
                  open={openedBlocks.includes(_.snakeCase(key))}
                  onClick={() => toggleOpen(_.snakeCase(key))}
                  title={key}
                  id={_.snakeCase(key)}
                  benefits={benefits[key]}
                />
              ))}
          </Stack>
        </Collapse>

        {!benefitsLength && (
          <Stack sx={{ mt: 4 }} spacing={2}>
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
          </Stack>
        )}
      </Card>
    </SideLayout>
  );
}

const LoadingRow = () => (
  <Stack spacing={0.6}>
    <Skeleton width={'60%'} height={64} />
    <Divider sx={{ color: '#B3B3B3' }} className={'mt-8'} />
  </Stack>
);
