import { Box, Typography } from '@mui/material';
import { WarningCircle } from '@phosphor-icons/react';
import React from 'react';

const PartDModalContent = () => (
  <Box
    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    data-test={'part-d-modal'}
  >
    <Typography
      className={'epilogue fs-24 lh-30 semi-bold mb-16'}
      align={'center'}
    >
      Are you sure you want to see plans with no Prescription Drug coverage?
    </Typography>
    <WarningCircle
      size={80}
      color="#1C434F"
      className={'mb-16'}
      weight="fill"
    />
    <Typography className={'epilogue fs-20 lh-30 medium mb-8'} align={'center'}>
      You may incur Late Enrollment Penalties
    </Typography>
    <Typography align={'center'} className={'fs-18 lh-22'}>
      Penalties can apply if you do not have Medicare drug coverage (Part D)
      after you first become eligible for Medicare. The only exception is if you
      have other qualifying (or 'creditable') coverage that provides drug
      coverage as good as Medicare's (such as from an employer, TRICARE or VA).
      <br />
      <br />
      You must have drug coverage to avoid penalties even if you are not
      currently taking any prescription drugs.
    </Typography>
  </Box>
);

export default PartDModalContent;
