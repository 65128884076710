import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import {
  HasEnrollmentsDocument,
  HasEnrollmentsQuery,
  HasEnrollmentsQueryVariables,
  MyEnrollmentsDocument,
  MyEnrollmentsQuery,
  MyEnrollmentsQueryVariables,
  PatchClientPersonalInfoDocument,
  PatchClientPersonalInfoMutation,
  PatchClientPersonalInfoMutationVariables,
  SaveUserProfileDocument,
  SaveUserProfileMutation,
  SaveUserProfileMutationVariables,
  UserProfileDocument,
  UserProfileQuery,
  UserProfileQueryVariables,
} from './enrollment-rtk';
import { api as medicareApi, MEDICARE_TAGS } from '../medicare';
import { setEnrolledPlans } from '../../root-slice';
import { getEnrolledPlans } from '../../root-slice/lib/get-enrolled-plans';
import { getToken } from '@coverright/shared/keycloak';
import { getStoredUser, setStoredUser } from '@coverright/utils';

// Define a service using a base URL and expected endpoints
export const api = createApi({
  reducerPath: 'enrollmentApi',
  tagTypes: ['PROFILE'],
  baseQuery: graphqlRequestBaseQuery({
    url: process.env.NX_ENROLLMENT_GRAPHQL as string,
    prepareHeaders: async (headers) => {
      const token = await getToken().catch(() => {});

      return {
        authorization: token ? `Bearer ${token}` : '',
        ...headers,
      };
    },
  }),
  endpoints: (build) => ({
    saveUserProfile: build.mutation<
      SaveUserProfileMutation,
      SaveUserProfileMutationVariables
    >({
      query: (variables) => ({ document: SaveUserProfileDocument, variables }),
      invalidatesTags: ['PROFILE'],
    }),
    userProfile: build.query<
      UserProfileQuery,
      UserProfileQueryVariables | void
    >({
      query: (variables) => ({ document: UserProfileDocument, variables }),
      providesTags: ['PROFILE'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.userProfile) {
            setStoredUser({ ...getStoredUser(), ...(data.userProfile as any) });
          }
        } catch (err) {
          throw Error('userProfile error');
        }
      },
    }),
    hasEnrollments: build.query<
      HasEnrollmentsQuery,
      HasEnrollmentsQueryVariables | void
    >({
      query: (variables) => ({ document: HasEnrollmentsDocument, variables }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.hasEnrollments) {
            dispatch(api.endpoints.myEnrollments.initiate());
          }
        } catch (err) {
          throw Error('hasEnrollments error');
        }
      },
    }),
    myEnrollments: build.query<
      MyEnrollmentsQuery,
      MyEnrollmentsQueryVariables | void
    >({
      query: (variables) => ({ document: MyEnrollmentsDocument, variables }),
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          if (data.myEnrollments) {
            dispatch(
              setEnrolledPlans(
                await getEnrolledPlans(dispatch, data.myEnrollments)
              )
            );
          }
        } catch (err) {
          throw Error('patchClientPersonalInfo error');
        }
      },
    }),
    patchClientPersonalInfo: build.mutation<
      PatchClientPersonalInfoMutation,
      PatchClientPersonalInfoMutationVariables
    >({
      query: (variables) => ({
        document: PatchClientPersonalInfoDocument,
        variables,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.patchClientPersonalInfo) {
            dispatch(
              medicareApi.util.invalidateTags([
                MEDICARE_TAGS.MA_QUOTE,
                MEDICARE_TAGS.PDP_QUOTE,
                MEDICARE_TAGS.PLAN_DISCOUNTS,
                MEDICARE_TAGS.PLAN_LIST,
              ])
            );
          }
        } catch (err) {
          throw Error('patchClientPersonalInfo error');
        }
      },
    }),
  }),
});
