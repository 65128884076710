import { Box, Divider } from '@mui/material';
import Message from './message';
import { ChatMessage } from '../lib';
import * as _ from 'lodash';
import React, { MutableRefObject, useEffect, useMemo, useRef } from 'react';

interface Props {
  messages: ChatMessage[];
  directionDetect: (m: ChatMessage) => 'outgoing' | 'incoming';
  lastReadIndex?: number;
}

export default function MessageList({
  messages,
  directionDetect,
  lastReadIndex,
}: Props) {
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const readHorizonRef: MutableRefObject<HTMLHRElement | null> = useRef(null);

  const grouped = useMemo(() => {
    return _.groupBy(messages, (m) => m.date?.toDateString());
  }, [messages]);

  useEffect(() => {
    if (ref?.current) {
      if (readHorizonRef.current) {
        ref.current.scrollTop = readHorizonRef.current?.offsetTop - 20;
      } else {
        ref.current.scrollTop = ref.current.children[0].clientHeight;
      }
    }
  }, [ref, readHorizonRef, messages]);

  return (
    <Box
      ref={ref}
      sx={{ width: '100%', height: '40vh', overflowY: 'auto', pr: 2 }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {Object.entries(grouped).map(([key, data]) => (
          <React.Fragment key={key}>
            <Divider
              textAlign={'center'}
              sx={{
                background:
                  'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,1) 100%)',
                position: 'sticky',
                top: 0,
                pb: 1,
                fontSize: 18,
              }}
            >
              {key}
            </Divider>
            {data.map((m) => (
              <React.Fragment key={m.date?.getTime()}>
                {lastReadIndex !== undefined && m.index === lastReadIndex + 1 && (
                  <Divider
                    ref={readHorizonRef}
                    id={'read-horizon'}
                    textAlign={'center'}
                    sx={{
                      color: 'red',
                    }}
                  >
                    Unread messages
                  </Divider>
                )}
                <Message
                  message={m}
                  key={m.date?.getTime()}
                  direction={directionDetect(m)}
                />
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
}
