import React from 'react';
import CompanyFilter from './ui/company-filter';
import PlanTypesFilter from './ui/plan-types-filter';
import { Box, Collapse, Typography } from '@mui/material';
import { useDebouncedEffect } from '@coverright/utils';
import SortBySelect from './ui/sort-by-select';
import { useMgFilters } from '../../features';
import { useLazyMedigapCompaniesQuery } from '../../shared/api/medigap';
import * as _ from 'lodash';
import { MedigapPlansFilterInput } from '@coverright/data-access/types/medigap';

export function MgFilters() {
  const { filters, buildHandler } = useMgFilters();
  const [getCompanies, { isSuccess }] = useLazyMedigapCompaniesQuery();

  useDebouncedEffect(
    () => {
      if (filters) {
        getCompanies({
          filters: _.omit(filters, [
            'planCategory',
            'id',
          ]) as MedigapPlansFilterInput,
        });
      }
    },
    800,
    [filters]
  );

  return (
    <Collapse in={isSuccess}>
      <Box
        sx={{
          display: 'flex',
          alignItems: { xs: 'flex-start', lg: 'center' },
          gap: 2,
          flexDirection: { xs: 'column', lg: 'row' },
          background: { xs: 'white', lg: 'none' },
          p: { xs: 3, lg: 0 },
          borderRadius: '16px',
        }}
      >
        <Typography variant={'body2'} sx={{ whiteSpace: 'nowrap' }}>
          Filter By
        </Typography>
        <PlanTypesFilter
          value={filters?.planNames || []}
          onChange={buildHandler('planNames')}
        />
        <CompanyFilter
          value={filters?.companies || []}
          onChange={buildHandler('companies')}
        />
        <SortBySelect />
      </Box>
    </Collapse>
  );
}
