import { useCallback, useState } from 'react';
import * as _ from 'lodash';

export default function useToggle(keys: string[]) {
  const [openedBlocks, setOpenedBlocks] = useState<string[]>([]);

  const toggleAll = useCallback(() => {
    if (keys.length && openedBlocks.length === keys.length) {
      setOpenedBlocks([]);
    } else {
      setOpenedBlocks(keys);
    }
  }, [keys, openedBlocks]);

  const toggleOpen = (benefitName: string) => {
    setOpenedBlocks((prev) => {
      return prev.includes(benefitName)
        ? _.without(prev, benefitName)
        : [...prev, benefitName];
    });
  };

  const exclusiveOpen = (benefitName: string) => {
    setOpenedBlocks((prev) => [..._.without(prev, benefitName), benefitName]);
  };

  return { openedBlocks, toggleAll, toggleOpen, exclusiveOpen };
}
