import { Box, Button, Card, Typography } from '@mui/material';
import { PlayCircle } from '@phosphor-icons/react';
import React from 'react';
import VideoModal from '../video-modal';

export default function QuizVideoModalCard() {
  const [videoOpen, setVideoOpen] = React.useState(false);

  return (
    <Card sx={{ p: 4, maxWidth: { lg: 294 }, minWidth: 294 }}>
      <VideoModal
        title={'What you should know in 30 seconds!'}
        url={'https://www.youtube.com/embed/nXJBLIvo12I'}
        open={videoOpen}
        onClose={() => setVideoOpen(false)}
      />
      <Typography variant={'h4'} className={'fs-24 lh-24'}>
        What to know!
      </Typography>

      <Box
        sx={{ my: 3, width: 1 }}
        component={'img'}
        src={'/plan-finder/assets/img/MGvsMA.png'}
      />

      <Button
        fullWidth
        variant={'contained'}
        onClick={() => setVideoOpen(true)}
        id={'cta-quiz-watch-video-button'}
        sx={{ justifyContent: 'flex-start', px: 2, fontWeight: 500 }}
        startIcon={<PlayCircle size={24} color="#FFF" weight="fill" />}
      >
        Watch Video
      </Button>
    </Card>
  );
}
