import { Conversation, Message } from '@twilio/conversations';
import { ChatMessage } from './types';

export * from './types';

export const mapMessage = (m: Message): ChatMessage => ({
  index: m.index,
  text: m.body,
  author: m.author,
  date: m.dateCreated,
  participantSid: m.participantSid,
});

export const conversationHasNewMessages = (conv?: Conversation): boolean =>
  (conv?.lastMessage?.index || 0) > (conv?.lastReadMessageIndex || 0);
