import { useEffect } from 'react';

export default function useScrollToDrugCost() {
  useEffect(() => {
    if (document.location.hash === '#drug-cost') {
      document
        .getElementById('drug-cost')
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);
}
