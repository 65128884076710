import * as React from 'react';
import { Button } from '@mui/material';
import { MADrugCostBreakdown, PDPDrugCostBreakdown } from '@coverright/widgets';
import { useNavigate, useParams } from 'react-router';
import { useMemo } from 'react';
import { GetPlansYear } from '@coverright/utils';
import { PlanYear } from '@coverright/data-access/medicare';
import { ArrowLeft } from '@phosphor-icons/react';
import { SideLayout, useAppSelector } from '../shared';
import { medicareQuote } from '../entities';
import { V3Preloader } from '@coverright/ui/shared';

function DrugCost(props: { type: 'MA' | 'PDP' }) {
  const quote = useAppSelector(medicareQuote);
  const { id } = useParams<{ id: string }>();

  const year = useMemo(() => {
    let result = quote?.planYear || GetPlansYear();
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('planYear')) {
      result = urlParams.get('planYear') as PlanYear;
    }
    return result;
  }, [quote?.planYear]);

  if (!quote) {
    return <V3Preloader />;
  }

  const breakdownProps = {
    bidId: id!,
    zip: quote.zip,
    countyName: quote.county!,
    planYear: year,
  };

  return (
    <SideLayout title={'Drug Cost Breakdown'} subtitle={<BackButton />}>
      {props.type === 'PDP' && (
        <PDPDrugCostBreakdown {...breakdownProps} clientId={quote.clientId} />
      )}
      {props.type === 'MA' && (
        <MADrugCostBreakdown {...breakdownProps} quoteId={quote.id} />
      )}
    </SideLayout>
  );
}

export const MADrugCost = () => <DrugCost type={'MA'} />;

export const PDPDrugCost = () => <DrugCost type={'PDP'} />;

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      variant={'text'}
      size={'small'}
      id={'drug-cost-back-button'}
      onClick={() => navigate(-1)}
      startIcon={<ArrowLeft size={16} color="#1C434F" weight="regular" />}
    >
      Back
    </Button>
  );
};
