import { Box, Button, Card, Divider, Typography } from '@mui/material';
import {
  IdentificationCard,
  LinkSimple,
  LockSimple,
} from '@phosphor-icons/react';
import React, { useMemo } from 'react';
import Skeletoned from './skeletoned';
import { getCompanyLogo, getMedigapCompanyLogo } from '@coverright/utils';

interface DashboardPlanCardProps {
  isConnected?: boolean;
  plan?: any;
  onConnectClick?: () => void;
  onDisconnectClick?: () => void;
  hideButton?: boolean;
}

export default function DashboardPlanCard(props: DashboardPlanCardProps) {
  const title = useMemo(() => {
    switch (props.plan?.type) {
      case 'MG':
        return 'Medicare Supplement Plan';
      case 'PDP':
        return 'Drug Plan';
      default:
        return 'Medicare Plan';
    }
  }, [props.plan]);
  return (
    <>
      <Card sx={{ p: 3 }}>
        <Typography className={'fs-24'} variant={'h3'}>
          {title}
        </Typography>
        <Divider sx={{ mt: 0.5, mb: 2, borderColor: '#B3B3B3' }} />
        <DashboardPlanCardContent {...props} />
      </Card>
    </>
  );
}

export function DashboardPlanCardContent(props: DashboardPlanCardProps) {
  const { plan, onConnectClick, onDisconnectClick, isConnected, hideButton } =
    props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        gap: 3,
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Skeletoned
          width={450}
          height={40}
          content={
            plan && (
              <Typography className={'fs-24 lh-32 semi-bold'}>
                {plan.planName}
              </Typography>
            )
          }
        />
        <Box sx={{ mt: 1.5 }}>
          {/*<Skeletoned width={125}
                      height={40}
                      content={plan && (isConnected ? <Box onClick={onDisconnectClick} sx={{cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 1}}>
                        <LinkSimpleBreak width={24} height={24} color={'#1C434F'}/>
                        <Typography className={'medium'} sx={{color: '#1C434F'}}>Disconnect plan</Typography>
                      </Box> : <></>)} />*/}
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography className={'fs-18 semi-bold mb-8'}>
            Monthly premium cost
          </Typography>
          <Skeletoned
            width={450}
            height={40}
            content={
              plan && (
                <Typography className={'fs-24 semi-bold'}>
                  {plan.premium}
                </Typography>
              )
            }
          />
          {!hideButton && (
            <Box sx={{ mt: 2 }}>
              <Skeletoned
                width={125}
                height={40}
                content={
                  plan &&
                  (isConnected ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <LinkSimple width={24} height={24} color={'#1C434F'} />
                      <Typography
                        className={'medium'}
                        sx={{ color: '#1C434F' }}
                      >
                        Add another Drug plan
                      </Typography>
                    </Box>
                  ) : (
                    <Button
                      id={'flexpa-connect-dashboard-plan-card'}
                      onClick={onConnectClick}
                      variant={'contained'}
                      startIcon={
                        <LockSimple
                          weight={'fill'}
                          width={24}
                          height={24}
                          color={'#fff'}
                        />
                      }
                    >
                      Unlock Medicare Plan Details
                    </Button>
                  ))
                }
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{ minWidth: 261, display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <Skeletoned
          width={120}
          height={50}
          content={
            plan && (
              <Box
                sx={{
                  backgroundPosition: 'left',
                  height: 33,
                  width: '100%',
                  minWidth: 120,
                  marginTop: 0,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundImage: `url("${
                    plan.type === 'MG'
                      ? getMedigapCompanyLogo(plan.parentOrgName, plan.orgName)
                      : getCompanyLogo(plan.parentOrgName, plan.orgName)
                  }")`,
                }}
              />
            )
          }
        />
        <Skeletoned
          width={125}
          height={40}
          content={
            plan &&
            (plan.isFlexpaIntegrated ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <IdentificationCard width={24} height={24} color={'#1C434F'} />
                <Typography className={'medium'} sx={{ color: '#1C434F' }}>
                  View Medical ID card
                </Typography>
              </Box>
            ) : (
              <></>
            ))
          }
        />
        <div>
          <Typography className={'semi-bold'}>Effective date</Typography>
          <Skeletoned
            width={62}
            height={24}
            content={
              plan && (
                <Typography className={'fs-14'} sx={{ color: '#666666' }}>
                  {plan.effectiveDate}
                </Typography>
              )
            }
          />
        </div>
        <div>
          <Typography className={'semi-bold'}>Renewal date</Typography>
          <Skeletoned
            width={62}
            height={24}
            content={
              plan && (
                <Typography className={'fs-14'} sx={{ color: '#666666' }}>
                  {plan.renewalDate}
                </Typography>
              )
            }
          />
        </div>
      </Box>
    </Box>
  );
}
