import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import { ArrowLeft, ArrowRight, Info } from '@phosphor-icons/react';
import SwipeableViews from 'react-swipeable-views';
import { PlanData } from './quiz-results-plan-data';
import { Tooltip } from '@coverright/ui/inputs';
import * as _ from 'lodash';
import { GetPlansYear, tooltips } from '@coverright/utils';
import { getDeductible } from '@coverright/shared/config';
import { LoadingButton } from '@mui/lab';
import { PlanType } from './utils';
import Benefit from '../benefit';
import { colors } from '@coverright/ui/themes';

interface QuizResultsPresenterProps {
  plans: PlanData[];
  actionButtonLabel: string;
  onActionButtonClick: (plan: PlanType) => void;
  onSelectClick: (plan: PlanType) => void;
  OMCompare: boolean;
  redirecting: boolean;
  hereIsWhy?: JSX.Element;
}

const QuizResultsPresenter = (props: QuizResultsPresenterProps) => {
  const {
    page,
    onNextClick,
    pageSize,
    onPrevClick,
    hasNext,
    hasPrev,
    totalPages,
    showNavigation,
  } = useNavigation(props.plans.length, props.OMCompare);

  const plans = React.useMemo(() => {
    return props.plans.slice(page * pageSize, page * pageSize + pageSize);
  }, [props.plans, page, pageSize]);

  return (
    <Box>
      {showNavigation && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 4,
          }}
        >
          <Box
            onClick={onPrevClick}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              cursor: hasPrev ? 'pointer' : 'default',
            }}
          >
            <ArrowLeft
              size={16}
              color={hasPrev ? '#1C434F' : '#B3B3B3'}
              weight="regular"
            />
            <Typography sx={{ color: hasPrev ? '#1C434F' : '#B3B3B3' }}>
              Previous
            </Typography>
          </Box>
          <Typography sx={{ color: '#666' }}>
            {page + 1}/{totalPages}
          </Typography>
          <Box
            onClick={onNextClick}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              cursor: hasNext ? 'pointer' : 'default',
            }}
          >
            <Typography sx={{ color: hasNext ? '#1C434F' : '#B3B3B3' }}>
              Next
            </Typography>
            <ArrowRight
              size={16}
              color={hasNext ? '#1C434F' : '#B3B3B3'}
              weight="regular"
            />
          </Box>
        </Box>
      )}

      <Box sx={{ width: 1 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {plans.map((plan) => (
            <Box
              key={plan.type}
              sx={{
                flex: 1,
                py: 3,
                px: 2,
                background: plan.type === PlanType.OM ? '#F9E69A' : '#000000',
                borderRadius: '16px 16px 0px 0px',
              }}
            >
              <Typography
                sx={{
                  fontSize: plans.length === 1 ? 32 : 18,
                  lineHeight: 1.5,
                  fontWeight: 600,
                  fontFamily: 'Epilogue',
                  color: plan.type === PlanType.OM ? '#000' : 'white',
                }}
                align={'center'}
              >
                {plan.name}
              </Typography>
            </Box>
          ))}
        </Box>
        {props.plans.length === 1 && (
          <Box sx={{ display: 'flex', gap: 2 }}>
            {plans.map((plan) => (
              <Box
                key={plan.type}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  pt: 3,
                  px: 3,
                  background: '#F8F6F2',
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    pb: 1.5,
                    mb: 2,
                    borderBottom: '1px solid #000',
                  }}
                >
                  <Typography variant={'h3'} className={'fs-24 lh-24'}>
                    Here is why
                  </Typography>
                </Box>
                {props.hereIsWhy}
                <Box
                  sx={{
                    flex: 1,
                    pb: 1.5,
                    mt: 4,
                    borderBottom: '1px solid #000',
                  }}
                >
                  <Typography variant={'h3'} className={'fs-24 lh-24'}>
                    {plan.name}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: 2 }}>
          {plans.map((plan) => (
            <Box
              key={plan.type}
              sx={{
                display: 'flex',
                gap: '10px',
                flex: 1,
                pt: 3,
                px: 3,
                background: '#F8F6F2',
              }}
            >
              {props.plans.length === 1 && (
                <Box sx={{ minWidth: 24 }}>
                  <Info size={24} color="#000" weight="fill" />
                </Box>
              )}
              <Typography>
                <span className={'semi-bold'}>What is this?</span>
                <br /> {plan.whatIsIt}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {plans.map((plan) => (
            <Box
              key={plan.type}
              sx={{
                display: 'flex',
                gap: '10px',
                flex: 1,
                pt: 3,
                px: 3,
                background: '#F8F6F2',
                borderRadius: '0px 0px 16px 16px',
              }}
            >
              {props.plans.length === 1 && (
                <Box sx={{ minWidth: 24 }}>
                  <Info size={24} color="#000" weight="fill" />
                </Box>
              )}
              <Typography>
                <span className={'semi-bold'}>Popularity</span>
                <br /> {plan.popularity}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {plans.map((plan) => (
            <Box
              key={plan.type}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                pb: 4,
                pt: 8,
                px: 3,
                background: '#F8F6F2',
                borderRadius: '0px 0px 16px 16px',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {plan.type !== PlanType.OM && (
                  <LoadingButton
                    loading={props.redirecting}
                    id={'show-pricing-plans-top-button'}
                    onClick={() => props.onSelectClick(plan.type)}
                    className={'fs-24 medium'}
                    variant={'contained'}
                  >
                    Show plan pricing
                  </LoadingButton>
                )}
              </Box>
            </Box>
          ))}
        </Box>
        {!props.OMCompare && (
          <Box sx={{ display: 'flex', gap: 2 }}>
            {plans.map((plan, i) => (
              <Box
                key={plan.type}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flex: 1,
                  mt: 1,
                }}
              >
                {plan.type !== PlanType.OM && (
                  <Button
                    id={'action-button'}
                    sx={{ px: { xs: 0, md: 2 } }}
                    className={'fs-16 medium'}
                    onClick={() => props.onActionButtonClick(plan.type)}
                    endIcon={<ArrowRight size={16} />}
                  >
                    {props.actionButtonLabel}
                  </Button>
                )}
              </Box>
            ))}
          </Box>
        )}

        <Box sx={{ display: 'flex', gap: 2 }}>
          {plans.map((plan, i) => (
            <Box
              key={plan.type}
              sx={{
                flex: 1,
                pb: 1.5,
                mb: 2,
                mt: 7,
                borderBottom: '1px solid #B3B3B3',
              }}
            >
              {!i && (
                <Typography variant={'h3'} className={'fs-24 lh-24'}>
                  This coverage includes
                </Typography>
              )}
            </Box>
          ))}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {plans.map((plan, i) => (
            <Box key={plan.type} sx={{ flex: 1 }}>
              <Stack spacing={2}>
                {plan.coverage.map((c) => (
                  <Benefit
                    key={c.title}
                    value={c.value}
                    label={c.title}
                    subtitle={c.subtitle}
                    tooltip={c.tooltip}
                  />
                ))}
              </Stack>
            </Box>
          ))}
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          {plans.map((plan, i) => (
            <Box
              key={plan.type}
              sx={{
                flex: 1,
                pb: 1.5,
                mb: 2,
                mt: 6,
                borderBottom: '1px solid #B3B3B3',
              }}
            >
              {!i && (
                <Typography variant={'h3'} className={'fs-24 lh-24'}>
                  Key out-of-pocket costs
                </Typography>
              )}
            </Box>
          ))}
        </Box>
        <Stack spacing={2}>
          {plans[0]?.OOPCosts.map((cost) => (
            <Box key={cost.title} sx={{ display: 'flex', gap: 2 }}>
              {plans.map((plan) => {
                const c = plan.OOPCosts.find((co) => co.title === cost.title)!;
                return (
                  <Box key={plan.type} sx={{ flex: 1 }}>
                    <Param
                      value={c.value}
                      title={c.title}
                      tooltip={c.tooltip}
                    />
                  </Box>
                );
              })}
            </Box>
          ))}
        </Stack>

        <Box sx={{ display: 'flex', gap: 2 }}>
          {plans.map((plan, i) => (
            <Box
              key={plan.type}
              sx={{
                flex: 1,
                pb: 1.5,
                mb: 2,
                mt: 6,
                borderBottom: '1px solid #B3B3B3',
              }}
            >
              {!i && (
                <Typography variant={'h3'} className={'fs-24 lh-24'}>
                  Cost
                </Typography>
              )}
            </Box>
          ))}
        </Box>

        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          {plans.map((plan) => (
            <Box
              key={plan.type}
              sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flex: 1,
                  p: 3,
                  background: '#F8F6F2',
                  borderRadius: '16px',
                }}
              >
                <Typography className={'semi-bold mb-16'}>
                  Total Monthly Premium Range
                </Typography>
                <Typography className={'fs-32 semi-bold'}>
                  {plan.monthlyPremium}
                </Typography>
                <Typography className={'mt-4 fs-14'}>per month</Typography>
                <ZeroPercent value={plan.zeroPercent} />
                {[PlanType.DSNP, PlanType.MMP].includes(plan.type) && (
                  <Typography sx={{ color: '#666' }} className={'fs-12'}>
                    typically covered by Medicaid
                  </Typography>
                )}

                {![PlanType.MG, PlanType.HDMG, PlanType.OM].includes(
                  plan.type
                ) && (
                  <Tooltip
                    title={
                      [PlanType.MG, PlanType.HDMG, PlanType.OM].includes(
                        plan.type
                      )
                        ? tooltips.pdpMG
                        : tooltips.pdp
                    }
                    arrow
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        flex: 1,
                        pt: 5,
                      }}
                    >
                      <Typography className={'semi-bold mb-8'}>
                        Prescription Drug Coverage Premium <sup>i</sup>
                      </Typography>
                      <PrescriptionDrugCoveragePremium
                        value={plan.prescriptionDrugCoveragePremium}
                      />
                    </Box>
                  </Tooltip>
                )}
              </Box>

              {[PlanType.MG, PlanType.HDMG, PlanType.OM].includes(
                plan.type
              ) && (
                <Tooltip
                  title={
                    [PlanType.MG, PlanType.HDMG, PlanType.OM].includes(
                      plan.type
                    )
                      ? tooltips.pdpMG
                      : tooltips.pdp
                  }
                  arrow
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      flex: 1,
                      p: 3,
                      '& *': { color: '#FFF' },
                      background: '#006495',
                      borderRadius: '16px',
                    }}
                  >
                    <Typography className={'semi-bold mb-8'}>
                      Prescription Drug Coverage Premium <sup>i</sup>
                    </Typography>
                    <PrescriptionDrugCoveragePremium
                      value={plan.prescriptionDrugCoveragePremium}
                    />
                  </Box>
                </Tooltip>
              )}

              <Tooltip title={tooltips.standardPartBPremium} arrow>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#1E95A0',
                    p: 3,
                    borderRadius: '16px',
                  }}
                >
                  <Typography
                    sx={{ color: 'white' }}
                    className={'fs-14 medium'}
                  >
                    {plan.type === PlanType.OM ? (
                      <>
                        <b>Plus</b> you pay your
                      </>
                    ) : (
                      <>
                        <b>Plus</b> you continue to pay your:
                      </>
                    )}
                  </Typography>
                  <Typography
                    sx={{ color: 'white' }}
                    className={'fs-20 lh-25 medium'}
                  >
                    Standard Part B Premium
                  </Typography>

                  {plan.type === PlanType.DSNP && (
                    <Typography
                      sx={{ color: 'white' }}
                      className={'fs-14 mt-4'}
                    >
                      <span className={'fs-20 lh-25 medium'}>$0</span> typically
                      covered by Medicaid
                    </Typography>
                  )}
                  {plan.type !== PlanType.DSNP && (
                    <Typography
                      sx={{ color: 'white' }}
                      className={'fs-14 mt-4'}
                    >
                      <span className={'fs-20 lh-25 medium'}>
                        {getDeductible(
                          'standardPartBMonthlyPremium',
                          GetPlansYear()
                        )}
                      </span>{' '}
                      or higher depending on your{' '}
                      <span className={'underline medium'}>income</span>
                    </Typography>
                  )}
                </Box>
              </Tooltip>
            </Box>
          ))}
        </Box>

        <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
          {plans.map((plan) => (
            <Box key={plan.type} sx={{ flex: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {plan.type !== PlanType.OM && (
                  <LoadingButton
                    loading={props.redirecting}
                    id={'show-pricing-plans-bottom-button'}
                    onClick={() => props.onSelectClick(plan.type)}
                    className={'fs-24 medium mt-8'}
                    variant={'contained'}
                  >
                    Show plan pricing
                  </LoadingButton>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      {showNavigation && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 4,
          }}
        >
          <Box
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              onPrevClick();
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              cursor: hasPrev ? 'pointer' : 'default',
            }}
          >
            <ArrowLeft
              size={16}
              color={hasPrev ? '#1C434F' : '#B3B3B3'}
              weight="regular"
            />
            <Typography sx={{ color: hasPrev ? '#1C434F' : '#B3B3B3' }}>
              Previous
            </Typography>
          </Box>
          <Typography sx={{ color: '#666' }}>
            {page + 1}/{totalPages}
          </Typography>
          <Box
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              onNextClick();
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              cursor: hasNext ? 'pointer' : 'default',
            }}
          >
            <Typography sx={{ color: hasNext ? '#1C434F' : '#B3B3B3' }}>
              Next
            </Typography>
            <ArrowRight
              size={16}
              color={hasNext ? '#1C434F' : '#B3B3B3'}
              weight="regular"
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default QuizResultsPresenter;

const ZeroPercent = ({ value }: { value?: () => Promise<JSX.Element> }) => {
  const [result, setResult] = React.useState(() =>
    _.isFunction(value) ? <CircularProgress size={20} /> : null
  );

  React.useEffect(() => {
    if (_.isFunction(value)) {
      value().then(setResult);
    }
  }, [value]);

  return result;
};

const PrescriptionDrugCoveragePremium = ({
  value,
}: {
  value: JSX.Element | (() => Promise<JSX.Element>);
}) => {
  const [result, setResult] = React.useState(() =>
    _.isFunction(value) ? <CircularProgress size={20} /> : value
  );

  React.useEffect(() => {
    if (_.isFunction(value)) {
      value().then(setResult);
    }
  }, [value]);

  return result;
};

const Param = (props: {
  title: string;
  value: JSX.Element | string | (() => Promise<string | JSX.Element>);
  tooltip?: string | JSX.Element;
}) => {
  const [result, setResult] = React.useState(() =>
    _.isFunction(props.value) ? <CircularProgress size={20} /> : props.value
  );

  React.useEffect(() => {
    if (_.isFunction(props.value)) {
      props.value().then(setResult);
    }
  }, [props.value]);

  return (
    <div>
      <Tooltip arrow title={props.tooltip || ''}>
        <Typography
          sx={{ width: 'fit-content', mb: 0.25 }}
          className={'semi-bold'}
        >
          {props.title} {props.tooltip && <sup>&nbsp;i</sup>}
        </Typography>
      </Tooltip>
      {typeof result !== 'string' && <>{result}</>}
      {typeof result === 'string' && <Typography>{result}</Typography>}
    </div>
  );
};

const useNavigation = (plansLength: number, OMCompare: boolean) => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const [dePage, setDePage] = React.useState(0);
  const [omPage, setOMPage] = React.useState(0);

  const pageSize = React.useMemo(() => {
    return phone ? 1 : 2;
  }, [phone]);

  const totalPages = React.useMemo(() => {
    return Math.ceil(plansLength / pageSize);
  }, [plansLength, pageSize]);

  const showNavigation = React.useMemo(() => {
    return plansLength > pageSize;
  }, [plansLength, pageSize]);

  const hasPrev = React.useMemo(() => {
    if (OMCompare) {
      return omPage > 0;
    }
    return dePage > 0;
  }, [dePage, totalPages, omPage, OMCompare]);

  const hasNext = React.useMemo(() => {
    if (OMCompare) {
      return omPage < totalPages - 1;
    }
    return dePage < totalPages - 1;
  }, [dePage, totalPages, omPage, OMCompare]);

  React.useEffect(() => {
    if (!OMCompare) {
      setOMPage(0);
    }
  }, [OMCompare]);

  const onPrevClick = React.useCallback(() => {
    if (OMCompare) {
      setOMPage((prev) => (prev > 0 ? prev - 1 : prev));
    } else {
      setDePage((prev) => (prev > 0 ? prev - 1 : prev));
    }
  }, [OMCompare]);

  const onNextClick = React.useCallback(() => {
    if (hasNext) {
      if (OMCompare) {
        setOMPage((prev) => prev + 1);
      } else {
        setDePage((prev) => prev + 1);
      }
    }
  }, [hasNext, OMCompare]);

  const page = React.useMemo(() => {
    return OMCompare ? omPage : dePage;
  }, [OMCompare, omPage, dePage]);

  React.useEffect(() => {
    if (page > totalPages - 1) {
      setDePage(0);
      setOMPage(0);
    }
  }, [page, totalPages]);

  return {
    page,
    onNextClick,
    pageSize,
    onPrevClick,
    hasNext,
    hasPrev,
    totalPages,
    showNavigation,
  };
};
