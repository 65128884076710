import Message from '../ui/message';
import { Button, Collapse } from '@mui/material';
import * as React from 'react';
import { useDebouncedEffect } from '@coverright/utils';
import { useState } from 'react';
import { Tooltip } from '@coverright/ui/inputs';

interface Props {
  isOnlyHMOSelected: boolean;
  isDSNPSelected: boolean;
  isTherePlansWithDoctorsCoverage: boolean;
  planCoversAllDoctorsExists: boolean;
  isPpoAvailable: boolean;
}
export default function (props: Props) {
  const {
    isTherePlansWithDoctorsCoverage,
    planCoversAllDoctorsExists,
    isOnlyHMOSelected,
    isDSNPSelected,
    isPpoAvailable,
  } = props;
  const [open, setOpen] = useState(false);

  useDebouncedEffect(
    () => {
      setOpen(
        isOnlyHMOSelected &&
          !isDSNPSelected &&
          isTherePlansWithDoctorsCoverage &&
          !planCoversAllDoctorsExists &&
          isPpoAvailable
      );
    },
    500,
    [
      isTherePlansWithDoctorsCoverage,
      planCoversAllDoctorsExists,
      isOnlyHMOSelected,
      isDSNPSelected,
      isPpoAvailable,
    ]
  );

  return (
    <Collapse in={open}>
      <Message
        title={'Not all your doctors are covered'}
        description={
          <>
            You are currently only viewing{' '}
            <Tooltip
              placement={'bottom'}
              arrow
              title={
                'An HMO (Health Maintenance Organization) plan is a type of Medicare Advantage plan. HMO plan enrollees must use doctors and hospitals within a plan’s specific network to receive their covered services except for emergency situations.'
              }
            >
              <span className={'underline medium'}>HMO</span>
            </Tooltip>{' '}
            plans, however, it appears that not all your doctors are covered by
            any plan. If you must keep all your doctors we would recommend
            looking at{' '}
            <Tooltip
              placement={'bottom'}
              arrow
              title={
                "A PPO (Preferred Provider Organization) plan is a type of Medicare Advantage plan.   PPO plan enrollees can use doctors and hospitals within a plan’s 'preferred' network to achieve a lower cost of care.  PPO enrollees also have the flexibility to see out-of-network providers, however, you will likely pay a higher copayment or coinsurance for seeing doctors outside the plans’ preferred provider list."
              }
            >
              <span className={'underline medium'}>PPO</span>
            </Tooltip>{' '}
            plans. These plans may cost more overall than a HMO plan.
          </>
        }
        onClose={() => setOpen(false)}
        data-test={'notAllDoctorsWithPpoOpen'}
      />
    </Collapse>
  );
}
