import { getInitialEnrollmentPeriod } from '@coverright/utils';
import moment from 'moment/moment';

export const isIEPActive = () => {
  const IEP = getInitialEnrollmentPeriod();
  if (IEP) {
    return moment().isBetween(IEP.start, IEP.end);
  }
  return false;
};

export const isGEPActive = () =>
  moment().isBetween(
    moment({ month: 0, day: 1 }),
    moment({ month: 2, day: 31 })
  );
export const isAEPActive = () =>
  moment().isBetween(
    moment({ month: 9, day: 15 }),
    moment({ month: 11, day: 7 })
  );
