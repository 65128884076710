export const getPlanName = (planName?: string | null) => {
  switch (planName) {
    case 'HDF':
      return 'Plan HDF';
    case 'HDG':
      return 'Plan HDG';
    case 'MA_SUPP1':
      return 'Supplement 1';
    case 'MA_SUPP1A':
      return 'Supplement 1A';
    case 'MA_CORE':
      return 'Core plan';
    case 'WI_75%':
      return 'Medigap 75% Cost Sharing Plan';
    case 'WI_50%':
      return 'Medigap 50% Cost Sharing Plan';
    case 'WI_BASE':
      return 'Medigap Basic Plan';
    case 'WI_HDED':
      return 'Medigap High Deductible Plan';
    default:
      return 'Plan ' + planName;
  }
};
