import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { maFilters, setMaFilter } from '../../../entities';
import { PlansFilterInput } from '@coverright/data-access/types/medicare';
import { useDebouncedEffect } from '@coverright/utils';
import useSaveFilters from '../api/use-save-ma-filters';

export function useMaFilters() {
  const filters = useAppSelector(maFilters);
  const dispatch = useAppDispatch();
  const saveFilters = useSaveFilters();

  const buildHandler = (key: keyof PlansFilterInput) => (value: any) => {
    dispatch(setMaFilter({ key, value }));
  };

  useDebouncedEffect(
    () => {
      saveFilters(filters);
    },
    800,
    [filters]
  );

  return { filters, buildHandler };
}
