import { Box, Typography } from '@mui/material';
import { WarningCircle } from '@phosphor-icons/react';
import React from 'react';

const DSNPOffModalContent = () => (
  <Box
    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    data-test={'dsnp-off-modal'}
  >
    <Typography
      className={'epilogue fs-24 lh-30 semi-bold mb-16'}
      align={'center'}
    >
      Are you sure you want to see regular Medicare Advantage plans?
    </Typography>
    <WarningCircle
      size={80}
      color="#1C434F"
      className={'mb-16'}
      weight="fill"
    />
    <Typography className={'epilogue fs-20 lh-30 medium mb-8'} align={'center'}>
      This will unselect the Dual-Eligible Special Needs Plans (D-SNP) filter
    </Typography>
    <Typography align={'center'} className={'fs-18 lh-22'}>
      D-SNP plans are special plans designed for Medicare-Medicaid
      ‘dual-eligible’ beneficiaries. <br />
      <br />
      These plans provide you convenience by coordinating all your Medicare and
      Medicaid benefits into one simple health plan (unlike regular Medicare
      Advantage plans).
    </Typography>
  </Box>
);

export default DSNPOffModalContent;
