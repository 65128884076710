import React from 'react';
import { SideLayout, useAppSelector } from '../../shared';
import { MgPlanList } from '../../widgets';
import { BackButton, mgComparisonPlans } from '../../features';
import { routes } from '../../app/router-new';
import { useNavigate } from 'react-router';
import { MgComparisonTable } from './ui/mg-comparison-table';

export default function () {
  const navigate = useNavigate();
  const list = useAppSelector(mgComparisonPlans);

  React.useEffect(() => {
    if (!list.length) {
      navigate(routes.planFinderMG.value);
    }
  }, [list]);

  return (
    <SideLayout
      title={'Compare your next medicare plan with CoverRight'}
      subtitle={
        <BackButton
          label={'Back to All Plans'}
          onClick={() => navigate(routes.planFinderMG.value)}
        />
      }
    >
      <MgComparisonTable />
    </SideLayout>
  );
}
