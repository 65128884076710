import {
  PdpField,
  PdpSort,
  PdpPlansFilterInput,
} from '@coverright/data-access/types/medicare';
import { GetNextPlansYear, GetPlansYear } from '@coverright/utils';
import { useLazyPdpPlanListQuery } from '../../../shared/api/medicare';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';

export default function usePlanList() {
  const [getPlans, { data, isFetching, isUninitialized, isSuccess }] =
    useLazyPdpPlanListQuery();
  const { enqueueSnackbar } = useSnackbar();

  const loadPlans = async (
    page: number,
    sortBy: PdpField,
    zip: string,
    countyName: string,
    filters?: PdpPlansFilterInput,
    clientId?: string
  ) => {
    if (filters) {
      try {
        const response = await getPlans({
          filter: {
            zip,
            countyName,
            planYear: GetPlansYear() as any,
            companies: filters?.companies || [],
          },
          fullYear: GetPlansYear() === GetNextPlansYear(),
          page: {
            size: 8,
            page: page,
          },
          clientId,
          sort: [
            {
              pdpField: sortBy || PdpField.MonthlyCost,
              direction: PdpSort.Asc,
            },
          ],
        });

        if (response.data?.PdpPlans.data) {
          return response.data?.PdpPlans.data;
        } else {
          throw Error('Pdp plan list data is wrong');
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar('Error loading plans', { variant: 'error' });
      }
    } else {
      console.warn('No filters in Pdp list');
    }
    return [];
  };

  const plansTotal = useMemo(() => {
    return data?.PdpPlans.totalElements ?? 0;
  }, [data]);

  const hasMorePlans = React.useMemo(() => {
    return data?.PdpPlans.hasNext;
  }, [data?.PdpPlans.hasNext]);

  return {
    plansTotal,
    loadPlans,
    hasMorePlans,
    isLoading: isFetching || isUninitialized,
    isSuccess,
  };
}
