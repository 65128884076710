import { createSlice } from '@reduxjs/toolkit';
import { fetchBenefits } from '../api/fetch-benefits';
import { BenefitEntity } from '../lib';

type BenefitSlice = {
  benefits: { [key: string]: BenefitEntity[] };
};

const initialState: BenefitSlice = {
  benefits: {},
};

const slice = createSlice({
  name: 'benefit',
  initialState,
  reducers: {},
  selectors: {
    benefits(state) {
      return state.benefits;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBenefits.fulfilled, (state, { payload }) => {
      state.benefits = payload;
    });
  },
});

export const benefitReducer = slice.reducer;

export const { benefits } = slice.selectors;
