import { useConfirm } from '../../components/confirm-dialog-context';
import React from 'react';
import DSNPOffModalContent from './ui/dsnp-off-modal-content';

export function useDsnpOffConfirm() {
  const confirm = useConfirm();

  return () =>
    confirm({
      content: <DSNPOffModalContent />,
      okButtonTitle: 'Yes - I want to see regular MA plans',
      cancelButtonTitle: 'No - return to plans',
    });
}
