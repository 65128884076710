import { MedicareQuoteOutput } from '@coverright/data-access/types/medicare';
import { ComparisonPlan } from '../../../features';
import {
  PlanWithTiers,
  useMedicarePlansByBid as request,
} from '@coverright/data-access/medicare';
import { GetPlansYear } from '@coverright/utils';
import { useMemo } from 'react';

export default function useMedicarePlansByBid(
  quote?: MedicareQuoteOutput | null,
  comparePlans?: ComparisonPlan[],
  onComplete?: (data: any) => void
) {
  const requestPlans = useMemo(() => {
    return quote
      ? comparePlans?.map((plan) => ({
          bidId: plan.id,
          zip: quote.zip,
          countyName: quote.county as string,
          quoteId: quote.id,
          planYear: quote.planYear || GetPlansYear(),
        }))
      : undefined;
  }, [quote, comparePlans]);

  const { plans } = request(requestPlans, onComplete);
  return (
    (comparePlans
      ?.map((comparePlan) => plans.find((p) => p.bidId === comparePlan.id))
      .filter(Boolean) as PlanWithTiers[]) || []
  );
}
