import * as _ from 'lodash';
import Markdown from 'react-markdown';
import { convertExtraBenefitValue } from './convert-extra-benefit-value';
import { BenefitEntity } from '../../../entities';

export function markdownBenefit(benefit: BenefitEntity) {
  const name = benefit.name;
  // let description: JSX.Element | undefined = <Markdown>{benefit.attributes?.description}</Markdown>;
  let description: JSX.Element | undefined;
  const howToActivate = benefit.howToActivate;

  switch (_.lowerCase(name)) {
    case 'dental':
    case 'vision':
    case 'hearing':
    case 'meals':
    case 'otc benefits':
      description = convertExtraBenefitValue(benefit.description);
      break;
    default:
      description = <Markdown>{benefit.description as string}</Markdown>;
  }

  return { name, description, howToActivate };
}
