export const environmentProd = {
  production: true,

  medigapUrl: 'https://medigap.coverright.com/',
  medicareAdvantageUrl: 'https://plans2022-2.coverrightmedicare.com/',
  dashboardUrl: 'https://medicarequiz2.coverright.com/',
  learningUrl: 'https://learning.coverright.com/',
  advisorUrl: 'https://advisors.coverright.com/',
  pdpUrl: 'https://pdp.coverright.com/',
  flexpaUrl: 'https://benefits.coverright.com/',
  offersUrl: 'https://drug-optimizer.coverright.com/',
  profileUrl: 'https://signup.coverright.com/',
  planFinderUrl: 'https://v3.coverright.com/plan-finder/',
  planFinderZ1Url: 'https://v3.coverright.com/plan-finder-z1/',

  filesUrl: 'https://plan-files.ascendmedicare.com',

  clientFilesApiUrl: 'https://client-files.api.coverrightmedicare.com',
  userFlowRestUrl: 'https://api.coverrightmedicare.com/user-flow/api',

  cdnUrl: 'https://cdn.coverright.com/',

  strapiUrl: 'https://content-api.coverright.com',

  flexpaApiPublishableKey: 'pk_test_fUUKcP4fVWf1JITZ9-LGAX_kfXhkcbe6kbrtGmGo4TU'
};
