import Message from '../ui/message';
import { Collapse } from '@mui/material';
import * as React from 'react';
import { useDebouncedEffect } from '@coverright/utils';
import { useState } from 'react';
import { Tooltip } from '@coverright/ui/inputs';

interface Props {
  preferredDoctorsLength: number;
  isDSNPSelected: boolean;
  isPPOSelected: boolean;
}
export default function (props: Props) {
  const { isPPOSelected, preferredDoctorsLength, isDSNPSelected } = props;
  const [open, setOpen] = useState(false);

  useDebouncedEffect(
    () => {
      setOpen(isDSNPSelected && !!preferredDoctorsLength && !isPPOSelected);
    },
    500,
    [isPPOSelected, preferredDoctorsLength, isDSNPSelected]
  );

  return (
    <Collapse in={open}>
      <Message
        title={'Not all your doctors are covered'}
        description={
          <>
            You are currently only viewing{' '}
            <Tooltip
              placement={'bottom'}
              arrow
              title={
                'Special Needs Plans (SNPs) are a type of Medicare Advantage plan that cater to beneficiaries with specific needs. SNPs tailor their plan benefits, provider networks and drug formularies to meet the needs of the specific groups they serve.  Dual-SNPs (D-SNP) serve beneficiaries that are eligible for both Medicare and Medicaid.'
              }
            >
              <span className={'underline medium'}>D-SNP</span>
            </Tooltip>{' '}
            plans. Most{' '}
            <Tooltip
              placement={'bottom'}
              arrow
              title={
                'Special Needs Plans (SNPs) are a type of Medicare Advantage plan that cater to beneficiaries with specific needs. SNPs tailor their plan benefits, provider networks and drug formularies to meet the needs of the specific groups they serve.  Dual-SNPs (D-SNP) serve beneficiaries that are eligible for both Medicare and Medicaid.'
              }
            >
              <span className={'underline medium'}>D-SNP</span>
            </Tooltip>{' '}
            plans require you to use a network, however, you get the benefit of
            a special plan designed to coordinate your Medicare and Medicaid
            benefits into one simple plan with minimal out-of-pocket costs. We
            recommend finding a plan that covers at least your primary care
            doctor. If you must keep all your doctors you can consider a{' '}
            <Tooltip
              placement={'bottom'}
              arrow
              title={
                "A PPO (Preferred Provider Organization) plan is a type of Medicare Advantage plan.   PPO plan enrollees can use doctors and hospitals within a plan’s 'preferred' network to achieve a lower cost of care.  PPO enrollees also have the flexibility to see out-of-network providers, however, you will likely pay a higher copayment or coinsurance for seeing doctors outside the plans’ preferred provider list."
              }
            >
              <span className={'underline medium'}>PPO</span>
            </Tooltip>{' '}
            plan.{' '}
          </>
        }
        onClose={() => setOpen(false)}
        data-test={'noPpoDsnpOpen'}
      />
    </Collapse>
  );
}
