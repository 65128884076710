import { Typography } from '@mui/material';
import * as React from 'react';
import { convertBold, convertList } from '@coverright/utils';
import { PlanFilesOutput } from '@coverright/data-access/types/medicare';
import { PlanYear } from '@coverright/data-access/medicare';
import { environment } from '../environments/environment';

export function planFiles(
  files?: PlanFilesOutput | null,
  year?: PlanYear | null
) {
  if (files) {
    const prefix = (environment.filesUrl as string) + '/';
    const result: PlanFile[] = [];

    for (const key in map) {
      if (files[key as keyof PlanFilesOutput]) {
        result.push({
          label: map[key],
          url: prefix + files[key as keyof PlanFilesOutput],
        });
      }
    }

    return result;
  }
  return [];
}

interface PlanFile {
  url: string;
  label: string;
}

const map: { [key: string]: string } = {
  summaryOfBenefitsUrl: 'Summary of Benefits',
  evidenceOfCoverageDocUrl: 'Evidence of Coverage',
  starRatingDescDocUrl: 'CMS star ratings information',
  nonDiscriminationNoticeDocUrl:
    'Language Assistance / Non-discrimination Notice',
};

type ExtraBenefitValue =
  | undefined
  | null
  | string
  | JSX.Element
  | string[]
  | JSX.Element[];
export function convertExtraBenefitValue(
  value: ExtraBenefitValue
): JSX.Element | undefined {
  if (!value || (Array.isArray(value) && value.length == 0)) {
    return undefined;
  } else if (typeof value === 'string') {
    return <Typography>{value}</Typography>;
  } else if (Array.isArray(value)) {
    return (
      <>
        {value.map((line, i) => (
          <React.Fragment key={i}>
            {typeof line === 'string' && (
              <Typography
                sx={{ minHeight: 24 }}
                dangerouslySetInnerHTML={{
                  __html: convertList(convertBold(line)),
                }}
              ></Typography>
            )}
            {typeof line !== 'string' && <>{line}</>}
          </React.Fragment>
        ))}
      </>
    );
  } else {
    return value;
  }
}
