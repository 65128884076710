import { Video } from '../model/types';
import { Box, styled } from '@mui/material';
import { useVideoThumbnail } from '../../../features';
import { PlayCircle } from '@phosphor-icons/react';
import React from 'react';

export interface VideoThumbnailProps {
  video: Video;
  onClick: () => void;
  minWidth?: number;
  minHeight?: number;
}

export function VideoThumbnail({
  video,
  minHeight = 113,
  minWidth = 200,
  onClick,
}: VideoThumbnailProps) {
  const picture = useVideoThumbnail(video);

  return (
    <Thumbnail onClick={onClick}>
      <Box
        component={'img'}
        sx={{
          minWidth,
          width: 1,
          height: 'auto',
          minHeight,
          borderRadius: '8px',
        }}
        src={picture}
      />
      <Overlay className={'overlay'}>
        <PlayCircle size={72} color="#FFFFFF" weight="duotone" />
      </Overlay>
    </Thumbnail>
  );
}

const Thumbnail = styled(Box)({
  position: 'relative',
  borderRadius: '16px',
  cursor: 'pointer',
  '&:not(:hover) .overlay': { opacity: 0 },
});

const Overlay = styled(Box)({
  transition: 'all .3s',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: '#00000033',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '16px',
});
