import Message from '../ui/message';
import { Button, Collapse } from '@mui/material';
import * as React from 'react';
import { useDebouncedEffect } from '@coverright/utils';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { routes } from '../../../app/router-new';

export default function ({
  preferredDoctorsLength,
  preferredDrugsLength,
}: {
  preferredDoctorsLength: number;
  preferredDrugsLength: number;
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  useDebouncedEffect(
    () => {
      setOpen(!preferredDoctorsLength && !!preferredDrugsLength);
    },
    500,
    [preferredDoctorsLength, preferredDrugsLength]
  );

  return (
    <Collapse in={open}>
      <Message
        title={'You haven’t entered your doctors'}
        description={
          'It’s important to enter your doctors that you want covered before comparing any plans. This allows us to show plans that will cover your doctors.'
        }
        extra={
          <Button
            id={'ma-messages-enter-info-button'}
            sx={{ mt: 1 }}
            onClick={() => navigate(routes.doctorsDrugs.value)}
            size={'small'}
            variant={'outlined'}
          >
            Enter your information
          </Button>
        }
        onClose={() => setOpen(false)}
        data-test={'noDoctorsSelectedOpen'}
      />
    </Collapse>
  );
}
