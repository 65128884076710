import { useAppSelector } from '../../../shared/hooks';
import { medicareQuote } from '../../ma-quote';
import { useLogEvent } from '@coverright/shared/analytics';
import { useSnackbar } from 'notistack';
import { usePatchClientPersonalInfoMutation } from '../../../shared/api/enrollment';
import {
  DrugDeliveryTypeDto,
  PharmacyLocationDistanceOutput,
} from '@coverright/data-access/types/medicare';
import { ClientDrugDeliveryType } from '@coverright/data-access/types/enrollment';

export function useSavePharmacies() {
  const [mutation, { isLoading, error }] = usePatchClientPersonalInfoMutation();
  const quote = useAppSelector(medicareQuote);
  const logEvent = useLogEvent();
  const { enqueueSnackbar } = useSnackbar();

  const save = async (
    pharmacies: PharmacyLocationDistanceOutput[],
    type?: DrugDeliveryTypeDto
  ) => {
    if (quote) {
      if ((window as any).track && pharmacies.length) {
        logEvent('Pharmacies Added', {
          pharmacies: pharmacies,
          location: 'Plan finder',
        });
      }

      try {
        await mutation({
          clientId: quote.clientId,
          preferredPharmacies:
            type === DrugDeliveryTypeDto.Pharmacy
              ? pharmacies?.map((p) => p.npi)
              : [],
          drugDeliveryType: type as unknown as ClientDrugDeliveryType,
        }).unwrap();
        enqueueSnackbar('Pharmacies has been saved', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar('Error saving pharmacies', { variant: 'error' });
      }
    } else {
      throw Error('Medicare quote is not exists');
    }
  };

  return { save, isLoading };
}
