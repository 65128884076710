import { PlanWithTiers } from '@coverright/data-access/medicare';
import {
  DrugTier,
  DrugTierCoverageOutput,
  Plan,
} from '@coverright/data-access/types/medicare';

type AvailableTiers =
  | 'drugsTiers30Preferred'
  | 'drugsTiers30Standard'
  | 'drugsTiers90Preferred'
  | 'drugsTiers90Standard';

export const getCoverageDrugTiers = (
  plans: PlanWithTiers[],
  tierName: AvailableTiers
) => {
  const initial = plans.reduce(
    (previousValue: DrugTierCoverageOutput[], plan: PlanWithTiers) => {
      if (
        (plan?.[tierName]?.initialCoverageDrugTiers?.length || 0) >
        previousValue.length
      ) {
        return plan?.[tierName]
          ?.initialCoverageDrugTiers as DrugTierCoverageOutput[];
      }
      return previousValue;
    },
    [] as DrugTierCoverageOutput[]
  );

  const gap = initial.filter((tier, i) =>
    plans
      .map((p) => p?.[tierName]?.coverageGapDrugTiers[i]?.coverage)
      .some((v) => !!v && v !== '25% coinsurance')
  );
  return [initial, gap];
};

export const getCatastrophicCoverage = (plans: PlanWithTiers[]) => {
  return plans.reduce((previousValue, plan: Plan) => {
    const items = plan?.drugDetails?.catastrophicCoverage[0].items;
    if (items && items.length > previousValue.length) {
      return items;
    }
    return previousValue;
  }, [] as DrugTier[]);
};
