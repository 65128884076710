import React from 'react';
import { Grid, Typography, Card } from '@mui/material';
import { useNavigate } from 'react-router';
import { V3Preloader } from '@coverright/ui/shared';
import { SideLayout, useAppSelector } from '../shared';
import { persistentState, VideoCard } from '../entities';
import { useVideoCategories } from '../features';

export default function (props: any) {
  const categories = useVideoCategories();
  const navigate = useNavigate();
  const state = useAppSelector(persistentState);

  return (
    <SideLayout title={'Learning Center'}>
      {!categories.length && <V3Preloader sx={{ mt: 4 }} />}
      {categories.map((cat) => (
        <Card key={cat.id}>
          <Typography sx={{ fontSize: { xs: 24 }, mb: 2 }} variant={'h3'}>
            {cat.title}
          </Typography>
          <Grid spacing={3} container>
            {cat.videos.map((v) => (
              <Grid
                key={'video-' + v.id}
                xs={12}
                sm={6}
                lg={3}
                item
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <VideoCard
                  onClick={() => navigate('/video/' + v.id)}
                  video={v}
                  completed={state?.videosCompleted?.includes(v.id)}
                />
              </Grid>
            ))}
          </Grid>
        </Card>
      ))}
    </SideLayout>
  );
}
