import { Box, Typography } from '@mui/material';
import { WarningOctagon } from '@phosphor-icons/react';
import React from 'react';

const HasDiscountsAlert = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => (
  <Box
    sx={{
      cursor: 'pointer',
      borderRadius: '16px',
      border: '1px dashed #F9E69A',
      background: '#E8FDEB',
      p: 3,
      maxWidth: 866,
    }}
    onClick={onClick}
    id={'discount-alert-has-discounts'}
  >
    <Box
      data-test={'HasDiscountsAlert'}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <WarningOctagon size={24} color={'#000000'} weight={'fill'} />
        <Typography className={'fs-24 semi-bold lh-32'}>
          Status: Activate new offers
        </Typography>
      </Box>
    </Box>

    <Typography>
      It looks like we’ve found a cheaper drug for you based on the drugs you’ve
      added to your CoverRight profile! Look below to view offers and to
      increase your savings related to drug cost.
    </Typography>
  </Box>
);

export default HasDiscountsAlert;
