import { MarketplaceType } from '../../../shared';

export function getLabel(to: MarketplaceType) {
  switch (to) {
    case 'MG':
      return 'Switch To Medicare Supplement Plans';
    case 'MA':
      return 'Switch To Medicare Advantage Plans';
    default:
      return 'View Prescription Drug Plans';
  }
}
export function getId(to: MarketplaceType) {
  switch (to) {
    case 'MG':
      return 'go-to-mg-button';
    case 'MA':
      return 'go-to-ma-button';
    default:
      return 'go-to-pdp-button';
  }
}
