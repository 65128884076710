import { Box, Typography } from '@mui/material';
import React from 'react';
import { SideLayout } from '../../shared';
import { MgPlanList, MgFilters, MgCategoryDescription } from '../../widgets';
import {
  MgComparisonCard,
  SwitchToMarketplaceButton,
  TakeQuizButton,
  withMgFiltersContext,
} from '../../features';
import SideVideoCta from './ui/side-video-cta';
import useCreateMgQuoteIfNotExists from './api/use-create-mg-quote-if-not-exists';

function PlanFinderMg() {
  useCreateMgQuoteIfNotExists();

  return (
    <SideLayout
      title={'Medicare Supplement Plans'}
      subtitle={<TakeQuizButton />}
      slots={{
        start: (
          <>
            <MgFilters />
            <MgCategoryDescription />
          </>
        ),
        side: (
          <>
            <MgComparisonCard />
            <SideVideoCta />
          </>
        ),
        headerSide: (
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'flex-start',
              justifyContent: 'flex-end',
              gap: 3,
            }}
          >
            <SwitchToMarketplaceButton to={'PDP'} />
            <SwitchToMarketplaceButton to={'MA'} />
          </Box>
        ),
      }}
    >
      <MgPlanList />
    </SideLayout>
  );
}

export default withMgFiltersContext(PlanFinderMg);
