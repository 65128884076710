import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import {
  ComparisonPlan,
  mgComparisonPlans,
  removePlanFromComparison,
} from '../model/slice';
import ComparisonPlanList from './comparison-plan-list';
import { useNavigate } from 'react-router';
import { routes } from '../../../app/router-new';

export function MgComparisonCard() {
  const plans = useAppSelector(mgComparisonPlans);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const removeFromCompare = (plan: ComparisonPlan) =>
    dispatch(removePlanFromComparison({ type: 'MG', plan }));
  if (!plans.length) {
    return null;
  }

  return (
    <ComparisonPlanList
      onCompareClick={() => navigate(routes.planComparisonMG.value)}
      onRemoveClick={removeFromCompare}
      plans={plans}
    />
  );
}
