import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import { ScheduleACallButton } from '../../../../features';

export default function AOECard() {
  return (
    <Card sx={{ p: 4, maxWidth: { lg: 294 }, minWidth: 294 }}>
      <Box sx={{ p: 3, borderRadius: '8px', background: '#F9E69A' }}>
        <Typography>
          Annual Open Enrollment is between October 15 and December 7. Speak to
          a CoverRight advisor to ensure you have the right plan for 2024!
        </Typography>
      </Box>

      <ScheduleACallButton id={'dashboard-info-card-schedule-button'} />
    </Card>
  );
}
