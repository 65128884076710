import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import {
  CreateMedigapQuoteFromMedicareQuoteDocument,
  CreateMedigapQuoteFromMedicareQuoteMutation,
  CreateMedigapQuoteFromMedicareQuoteMutationVariables,
  MedigapQuoteDocument,
  MedigapQuoteQuery,
  MedigapQuoteQueryVariables,
} from './medigap-rtk';
import { getToken } from '@coverright/shared/keycloak';

// Define a service using a base URL and expected endpoints
export const api = createApi({
  reducerPath: 'medigapApi',
  tagTypes: ['MEDIGAP_QUOTE'],
  baseQuery: graphqlRequestBaseQuery({
    url: process.env.NX_GRAPHQL as string,
    prepareHeaders: async (headers) => {
      const token = await getToken().catch(() => {});

      return {
        authorization: token ? `Bearer ${token}` : '',
        ...headers,
      };
    },
  }),
  endpoints: (build) => ({
    createMedigapQuoteFromMedicareQuote: build.mutation<
      CreateMedigapQuoteFromMedicareQuoteMutation,
      CreateMedigapQuoteFromMedicareQuoteMutationVariables
    >({
      query: (variables) => ({
        document: CreateMedigapQuoteFromMedicareQuoteDocument,
        variables,
      }),
      invalidatesTags: ['MEDIGAP_QUOTE'],
    }),
    medigapQuote: build.query<MedigapQuoteQuery, MedigapQuoteQueryVariables>({
      query: (variables) => ({ document: MedigapQuoteDocument, variables }),
      providesTags: ['MEDIGAP_QUOTE'],
    }),
  }),
});
