import { Box, Card, Typography } from '@mui/material';
import { routes } from '../../../../app/router-new';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ChatCard() {
  const navigate = useNavigate();
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => setShowCursor((p) => !p), 600);
    return () => clearInterval(interval);
  }, []);

  return (
    <Card sx={{ p: 4, maxWidth: { lg: 294 }, minWidth: 294 }}>
      <Typography variant={'h4'} className={'fs-24 lh-30'}>
        Have a question about Medicare or retirement?
      </Typography>

      <Box
        sx={{
          p: 3,
          mt: 3,
          borderRadius: '16px',
          boxShadow: '0px 4px 4px 0px #00000040',
          cursor: 'pointer',
        }}
        onClick={() => navigate(routes.chat.value)}
      >
        <Typography className={'fs-14 semi-bold'}>
          Ask us anything! Chat now
        </Typography>
        <Typography
          sx={{ color: showCursor ? undefined : 'white' }}
          className={'fs-14 semi-bold'}
        >
          |
        </Typography>
      </Box>
    </Card>
  );
}
