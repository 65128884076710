import { NavigationItem } from './types';
import {
  MagnifyingGlass,
  Notebook,
  Pill,
  ShieldPlus,
  Stethoscope,
  Video as VideoIcon,
  Chats,
} from '@phosphor-icons/react';
import { routes } from '../../../app/router-new';

export const preEnrollmentItems: NavigationItem[] = [
  {
    label: 'Checklist',
    routes: [routes.toDoList.value],
    icon: Notebook,
  },
  {
    label: 'Doctors & Drugs',
    routes: [routes.doctorsDrugs.value],
    icon: Pill,
  },
  {
    label: 'Plans',
    routes: [
      routes.plans.value,
      routes.planFinder.value,
      routes.planFinderMA.value,
      routes.planComparisonMA.value,
      routes.planDetails.value,
      routes.drugCostPage.value,
      routes.drugCost.value,
      routes.drugCostPDP.value,
      routes.planFinderPDP.value,
      routes.planFinderMG.value,
      routes.planComparisonMG.value,
      routes.quizzes.value,
      routes.quiz.value,
    ],
    icon: MagnifyingGlass,
  },
  {
    label: 'Learn',
    routes: [routes.learn.value, routes.videos.value, routes.video.value],
    icon: VideoIcon,
  },
];

const shared: NavigationItem[] = [
  {
    label: 'Doctors',
    routes: [routes.doctors.value],
    icon: Stethoscope,
  },
  {
    label: 'Drugs',
    routes: [routes.drugs.value],
    icon: Pill,
  },
  {
    label: 'Chat',
    routes: [routes.chat.value],
    icon: Chats,
  },
];

export const postEnrollmentDashboardItems: NavigationItem[] = [
  {
    label: 'Overview',
    routes: [routes.dashboard.value],
    icon: Notebook,
  },
  {
    label: 'Benefits',
    routes: [routes.benefits.value],
    icon: ShieldPlus,
  },
  ...shared,
];

export const postEnrollmentPlansItems: NavigationItem[] = [
  {
    label: 'Plans',
    routes: [
      routes.plans.value,
      routes.planFinder.value,
      routes.planFinderMA.value,
      routes.planComparisonMA.value,
      routes.planDetails.value,
      routes.drugCostPage.value,
      routes.drugCost.value,
      routes.drugCostPDP.value,
      routes.planFinderPDP.value,
      routes.planFinderMG.value,
      routes.planComparisonMG.value,
      routes.quizzes.value,
      routes.quiz.value,
    ],
    icon: MagnifyingGlass,
  },
  {
    label: 'Learn',
    routes: [routes.learn.value, routes.videos.value, routes.video.value],
    icon: VideoIcon,
  },
  ...shared,
];
