import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListItem, transformListItem } from '../../../shared';
import { api, MedigapQuoteQuery } from '../../../shared/api/medigap';
import {
  MedigapPlansField,
  MedigapPlansFilterInput,
} from '@coverright/data-access/types/medigap';

type MgQuoteSlice = {
  quote?: MedigapQuoteQuery['medigapQuote'];
  filters?: MedigapPlansFilterInput;
  sortBy: MedigapPlansField;
  companies?: ListItem[];
  planTypes?: ListItem[];
};

const initialState: MgQuoteSlice = {
  sortBy: MedigapPlansField.MonthlyPremium,
};

const slice = createSlice({
  name: 'mgQuote',
  initialState,
  reducers: {
    setMgFilter(
      state,
      {
        payload,
      }: PayloadAction<{ key: keyof MedigapPlansFilterInput; value: any }>
    ) {
      if (state.filters) {
        // @ts-ignore
        state.filters[payload.key] = payload.value;
      }
    },
    setMgSortBy(state, { payload }: PayloadAction<MedigapPlansField>) {
      state.sortBy = payload;
    },
  },
  selectors: {
    medigapQuote(state) {
      return state.quote;
    },
    mgCompanies(state) {
      return state.companies;
    },
    mgPlanTypes(state) {
      return state.planTypes;
    },
    mgSortBy(state) {
      return state.sortBy;
    },
    mgFilters(state) {
      return state.filters;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.medigapQuote.matchFulfilled,
      (state, { payload }) => {
        const quote = payload.medigapQuote;
        state.quote = quote;
        if (!state.filters && quote?.medigapFilterState) {
          state.filters = {
            ...quote.medigapFilterState,
            companies: quote?.medigapFilterState?.companies || [],
            monthlyPlanPremiumRanges:
              quote?.medigapFilterState?.monthlyPlanPremiumRanges || [],
            planNames: quote?.medigapFilterState?.planNames || [],
          };
        }
      }
    );
    builder.addMatcher(
      api.endpoints.medigapCompanies.matchFulfilled,
      (state, { payload }) => {
        state.companies = transformListItem(
          payload.medigapCompanies.filter((c) => c.count)
        );
      }
    );
  },
});

export const mgQuoteReducer = slice.reducer;

export const { mgCompanies, mgFilters, mgPlanTypes, mgSortBy, medigapQuote } =
  slice.selectors;

export const { setMgFilter, setMgSortBy } = slice.actions;
