import Message from '../ui/message';
import { Collapse } from '@mui/material';
import * as React from 'react';
import { useDebouncedEffect } from '@coverright/utils';
import { useState } from 'react';

export default function ({
  plansLength,
  loading,
}: {
  loading: boolean;
  plansLength: number;
}) {
  const [open, setOpen] = useState(false);

  useDebouncedEffect(
    () => {
      setOpen(loading ? false : plansLength === 0);
    },
    500,
    [plansLength, loading]
  );

  return (
    <Collapse in={open}>
      <Message
        title={'Oops!  There are no plans that fit your criteria.'}
        description={
          'This could be because you have applied too many filters. Try reducing the number of filters (this will not impact your drug or doctor inputs).'
        }
        data-test={'noPlansOpen'}
      />
    </Collapse>
  );
}
