import { FilterItem } from '@coverright/data-access/types/medicare';
import { ListItem } from '../types';

export function transformListItem(
  collection: Omit<FilterItem, '__typename'>[]
): ListItem[] {
  const replace = (name: string) =>
    name.replace('Over-the-counter benefits', 'Over-the-counter Allowance');
  return collection.map((item: any) => ({
    value: item.key,
    label:
      item.count > 0
        ? `${replace(item.name)} (${item.count})`
        : replace(item.name),
    disabled: item.count === 0,
  }));
}
