import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api, PdpPlanListQuery } from '../../../shared/api/medicare';
import { ListItem, transformListItem } from '../../../shared';
import {
  PdpField,
  PdpPlansFilterInput,
} from '@coverright/data-access/types/medicare';

type PdpPlanSlice = {
  plans?: PdpPlanListQuery['PdpPlans'];
  filters?: PdpPlansFilterInput;
  companies: ListItem[];
  sortBy: PdpField;
};

const initialState: PdpPlanSlice = {
  companies: [],
  sortBy: PdpField.MonthlyCost,
};

const slice = createSlice({
  name: 'pdpPlan',
  initialState,
  reducers: {
    setPdpFilter(
      state,
      { payload }: PayloadAction<{ key: keyof PdpPlansFilterInput; value: any }>
    ) {
      if (state.filters) {
        // @ts-ignore
        state.filters[payload.key] = payload.value;
      }
    },
    setPdpSortBy(state, { payload }: PayloadAction<PdpField>) {
      state.sortBy = payload;
    },
  },
  selectors: {
    pdpCompanies(state) {
      return state.companies;
    },
    pdpSortBy(state) {
      return state.sortBy;
    },
    pdpFilters(state) {
      return state.filters;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.pdpCompanies.matchFulfilled,
      (state, { payload }) => {
        state.companies = transformListItem(
          payload.pdpCompanies.filter((c) => c.count)
        );
      }
    );
    builder.addMatcher(
      api.endpoints.pdpQuote.matchFulfilled,
      (state, { payload }) => {
        const quote = payload.pdpQuote;
        if (!state.filters && quote?.countyName && quote?.zip) {
          state.filters = {
            zip: quote.zip,
            countyName: quote.countyName,
          };
        }
      }
    );
  },
});

export const pdpPlanReducer = slice.reducer;

export const { pdpCompanies, pdpSortBy, pdpFilters } = slice.selectors;

export const { setPdpSortBy, setPdpFilter } = slice.actions;
