import { Box, Typography } from '@mui/material';
import { MedigapPlanName } from '@coverright/data-access/types/medigap';
import React from 'react';
import { Tooltip } from '@coverright/ui/inputs';
import { Info } from '@phosphor-icons/react';
import { getPlanName } from '../../lib/lib';

export const Header = ({ planKey }: { planKey: string }) => (
  <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
    <Typography className={'fs-24 bold'}>{getPlanName(planKey)}</Typography>
    <Banner plan={planKey as MedigapPlanName} />
  </Box>
);

const Banner = React.memo(({ plan }: { plan: MedigapPlanName }) => {
  let title = '';
  if (
    [MedigapPlanName.C, MedigapPlanName.F, MedigapPlanName.Hdf].includes(plan)
  ) {
    return (
      <Tooltip
        arrow
        title={
          'This plan is not available to Medicare beneficiaries who became eligible after Jan. 1, 2020'
        }
      >
        <Info size={24} color="#1C434F" weight="duotone" />
      </Tooltip>
    );
  } else {
    switch (plan) {
      case MedigapPlanName.G:
        title = 'Most Popular';
        break;
      case MedigapPlanName.N:
        title = 'Best for affordability';
        break;
      //case MedigapPlanName.F: title = 'Most Comprehensive'; break;
      default:
        title = '';
    }
  }

  if (!title) return null;

  return (
    <Box
      sx={{
        height: 30,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        p: '3px 8px',
        background: '#0A2E86',
        borderRadius: '4px',
      }}
    >
      <Typography
        sx={{ fontWeight: 600, color: '#FFF', fontSize: 12 }}
        className={'self-color'}
      >
        {title}
      </Typography>
      <img src={'assets/img/ribbon.svg'} className={'w-20 h-24'} />
    </Box>
  );
});
