import { createSlice } from '@reduxjs/toolkit';
import { Video, VideoCategoryWithVideos } from './types';
import { fetchVideoCategories } from '../api/fetch-video-categories';
import * as _ from 'lodash';

type VideoCategorySlice = {
  categories: VideoCategoryWithVideos[];
  videos: Video[];
};

const initialState: VideoCategorySlice = {
  categories: [],
  videos: [],
};

const videoCategorySlice = createSlice({
  name: 'videoCategory',
  initialState,
  selectors: {
    videoCategories(state) {
      return state.categories;
    },
    videos(state): Video[] {
      return state.videos || [];
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVideoCategories.fulfilled, (state, { payload }) => {
      state.categories = payload;
      state.videos = _.flatMap(payload, 'videos');
    });
  },
});

export const videoCategoryReducer = videoCategorySlice.reducer;

export const { videoCategories, videos } = videoCategorySlice.selectors;
