import { SkeletonProps } from '@mui/material/Skeleton/Skeleton';
import { Skeleton } from '@mui/material';
import React from 'react';

export default function Skeletoned(
  props: Omit<SkeletonProps, 'content'> & { content?: React.JSX.Element }
) {
  const { content, ...rest } = props;
  return content ? content : <Skeleton {...rest} />;
}
