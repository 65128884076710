import React, { MouseEventHandler, useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { PlusCircle, XCircle } from '@phosphor-icons/react';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import {
  addPlanToComparison,
  ComparisonPlan,
  maComparisonPlans,
  mgComparisonPlans,
  removePlanFromComparison,
} from '../model/slice';
import { useLogEvent } from '@coverright/shared/analytics';

export const CompareToggleButton = ({
  plan,
  type,
}: {
  plan: ComparisonPlan;
  type: 'MA' | 'MG';
}) => {
  const plans = useAppSelector(
    type === 'MA' ? maComparisonPlans : mgComparisonPlans
  );
  const dispatch = useAppDispatch();
  const logEvent = useLogEvent();

  const isInCompare = useMemo(() => {
    return plans.some((p) => p.id === plan.id);
  }, [plan, plans]);

  const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    // we will log here
    e.stopPropagation();

    if (isInCompare) {
      logEvent('click', {
        element_id: 'compare-toggle-button',
        element_title: 'Don’t compare',
      });
      dispatch(removePlanFromComparison({ type, plan }));
    } else {
      logEvent('click', {
        element_id: 'compare-toggle-button',
        element_title: 'Add to compare',
      });
      dispatch(addPlanToComparison({ type, plan }));
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        variant={'text'}
        size={'small'}
        id={'compare-toggle-button'}
        onClick={onClick}
        endIcon={
          isInCompare ? (
            <XCircle size={24} color="#666666" weight="fill" />
          ) : (
            <PlusCircle size={24} color="#1C434F" weight="fill" />
          )
        }
      >
        {isInCompare ? 'Don’t compare' : 'Add to compare'}
      </Button>
    </Box>
  );
};
