import { maSortBy, setMaSortBy } from '../../../entities';
import { Field } from '@coverright/data-access/types/medicare';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { SortBySelect } from '../../../features';

export default function () {
  const sortBy = useAppSelector(maSortBy);
  const dispatch = useAppDispatch();

  return (
    <SortBySelect
      sortBy={sortBy}
      onChange={(v) => dispatch(setMaSortBy(v))}
      options={[
        { label: 'Lower Cost First', value: Field.MonthlyCost },
        { label: 'Star Rating First', value: Field.Rating },
      ]}
    />
  );
}
