import { Box, Button, Typography } from '@mui/material';

interface Props {
  value: string;
  onViewClick: () => void;
}

export default function BenefitItem({ value, onViewClick }: Props) {
  return (
    <Box
      sx={{
        borderRadius: '8px',
        border: '1px solid #778E95',
        width: { sm: 182 },
        height: { sm: 204 },
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        justifyContent: 'space-between',
        p: 2,
      }}
    >
      <Typography className={'fs-16 lh-24 semi-bold'}>{value}</Typography>
      <Button variant={'contained'} size={'small'} onClick={onViewClick}>
        View benefit
      </Button>
    </Box>
  );
}
