import { Video } from '../entities';
import { useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';

export const useVideoThumbnail = (video: Video) => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('lg'));

  return useMemo(() => {
    if (video.picture) {
      const url = phone
        ? video.picture.formats.medium
        : video.picture.formats.thumbnail;
      return 'https://content-api.coverright.com' + url;
    } else {
      return '/plan-finder/assets/img/video.jpg';
    }
  }, [video, phone]);
};
