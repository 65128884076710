import * as _ from 'lodash';
import { MyEnrollmentsQuery } from '../../api/enrollment';
import { EnrollmentType } from '@coverright/data-access/types/enrollment';
import {
  PlanYear,
} from '@coverright/data-access/medicare';
import { api as medicareApi } from '../../api/medicare';
import { GetPlansYear, getQuoteId } from '@coverright/utils';
import moment from 'moment/moment';

//import {store} from "../../../app/store";

export async function getEnrolledPlans(
  dispatch: any,
  myEnrollments?: MyEnrollmentsQuery['myEnrollments']
): Promise<any[]> {
  // const quoteId = store.getState().maQuote.quote?.id;

  if (myEnrollments?.length) {
    // get only latest plans MA or MG + PDP
    let valueEnrollments = myEnrollments.filter(
      (p) => p.type !== EnrollmentType.Mg
    );
    if (valueEnrollments.length) {
      let enrollment = valueEnrollments[0];
      if (valueEnrollments.length > 1) {
        enrollment = _.orderBy(valueEnrollments, 'effectiveDate', 'desc')[0];
      }

      if (enrollment.type === EnrollmentType.Ma) {
        valueEnrollments = [enrollment];
      } else {
        valueEnrollments = valueEnrollments.filter(
          (p) => p.type !== EnrollmentType.Mg
        );
      }
    }

    const promises: Promise<any | undefined>[] = [];

    for (const plan of valueEnrollments) {
      const provider = plan.type === EnrollmentType.Ma ? getMaPlan : getPdpPlan;
      promises.push(provider(dispatch, plan));
    }

    const result = (await Promise.all(promises)).filter(
      Boolean
    ) as any[];

    return _.orderBy(result, 'type');
  }
  return [];
}

async function getMaPlan(
  dispatch: any,
  enrollment: MyEnrollmentsQuery['myEnrollments'][0]
) {
  try {
    const { data } = await dispatch(
      medicareApi.endpoints.maPlan.initiate({
        planYear: GetPlansYear() as any,
        bidId: enrollment.bidId!,
        zip: enrollment.zip!,
        countyName: enrollment.county!,
        quoteId: getQuoteId(), // TODO - refactor
      })
    );
    const plan = data.plan;
    return {
      bidId: plan.bidId,
      orgName: plan.orgName!,
      parentOrgName: plan.parentOrgName!,
      planName: plan.planName!,
      premium: plan.monthlyPremium!,
      renewalDate: moment().add({ year: 1 }).startOf('year').format('MM/DD/YY'),
      type: 'MA',
      county: enrollment.county!,
      effectiveDate: moment(enrollment.effectiveDate).format('MM/DD/YY'),
      enrollmentId: enrollment.id,
      flexpaIntegrationError: '',
      isFlexpaIntegrated: false,
      year: enrollment.planYear as PlanYear,
      zip: enrollment.zip!,
    };
  } catch (e) {
    return undefined;
  }
}

async function getPdpPlan(
  dispatch: any,
  enrollment: MyEnrollmentsQuery['myEnrollments'][0]
) {
  try {
    const { data } = await dispatch(
      medicareApi.endpoints.pdpPlan.initiate({
        planYear: GetPlansYear() as any,
        bidId: enrollment.bidId!,
        zip: enrollment.zip!,
        countyName: enrollment.county!,
      })
    );

    const plan = data.pdpPlan;
    return {
      bidId: plan.bidId,
      orgName: plan.orgName!,
      parentOrgName: plan.parentOrgName!,
      planName: plan.planName!,
      premium: plan.monthlyPremium!,
      renewalDate: moment().add({ year: 1 }).startOf('year').format('MM/DD/YY'),
      type: 'PDP',
      county: enrollment.county!,
      effectiveDate: moment(enrollment.effectiveDate).format('MM/DD/YY'),
      enrollmentId: enrollment.id,
      flexpaIntegrationError: '',
      isFlexpaIntegrated: false,
      year: enrollment.planYear as PlanYear,
      zip: enrollment.zip!,
    };
  } catch (e) {
    return undefined;
  }
}
