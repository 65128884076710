import Message from '../ui/message';
import { Collapse } from '@mui/material';
import * as React from 'react';
import { useDebouncedEffect } from '@coverright/utils';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../app/router-new';

interface Props {
  hasUnsupportedDrugs: boolean;
}
export default function ({ hasUnsupportedDrugs }: Props) {
  const [open, setOpen] = useState(false);

  useDebouncedEffect(
    () => {
      setOpen(hasUnsupportedDrugs);
    },
    500,
    [hasUnsupportedDrugs]
  );

  return (
    <Collapse in={open}>
      <Message
        title={'Not all your drugs are supported'}
        description={
          <>
            Remove unsupported drugs from a{' '}
            <Link to={routes.drugs.value}>list</Link>. Or call a CoverRight
            agent to clarify drug price.
          </>
        }
        onClose={() => setOpen(false)}
        data-test={'hasUnsupportedDrugs'}
      />
    </Collapse>
  );
}
