import React from 'react';
import { SideLayout, useAppSelector } from '../../shared';
import { profile as profileSelector } from '../../entities/profile';
import { V3Preloader } from '@coverright/ui/shared';
import { Box, Typography } from '@mui/material';
import { CheckCircle } from '@phosphor-icons/react';
import { Chat } from '../../widgets';

export default function () {
  const profile = useAppSelector(profileSelector);

  if (!profile) {
    return <V3Preloader />;
  }

  return (
    <SideLayout
      title={
        profile?.firstName ? `Hello, ${profile?.firstName}!` : 'Loading...'
      }
      subtitle={
        'Have a question about Medicare or retirement? Ask our team of licensed agents anything!'
      }
    >
      <Box
        sx={{
          borderRadius: '16px',
          border: '1px dashed #F9E69A',
          background: '#E8ECED',
          p: 3,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <CheckCircle size={24} color={'#0B741C'} weight={'fill'} />
        <Typography className={'fs-24 semi-bold lh-40'}>
          Suggestion: Ask us any question about healthcare or retirement
        </Typography>
      </Box>

      <Chat />
    </SideLayout>
  );
}
