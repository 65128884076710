import * as React from 'react';

export function useShowCloseModal() {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const isShown = sessionStorage.getItem('closeModalShown');
    let mousePos: any;

    function handleMouseOut() {
      if (!isShown && mousePos && mousePos.y < 30) {
        setTimeout(() => {
          if (mousePos.y < 30) {
            setShow(process.env.NX_PRODUCTION === 'true');
            sessionStorage.setItem('closeModalShown', 'true');
          }
        }, 2000);
      }
    }

    function handleMouseMove(event: any) {
      let dot, eventDoc, doc, body, pageX, pageY;

      event = event || window.event; // IE-ism

      // If pageX/Y aren't available and clientX/Y are,
      // calculate pageX/Y - logic taken from jQuery.
      // (This is to support old IE)
      if (event.pageX == null && event.clientX != null) {
        eventDoc = (event.target && event.target.ownerDocument) || document;
        doc = eventDoc.documentElement;
        body = eventDoc.body;

        event.pageX =
          event.clientX +
          ((doc && doc.scrollLeft) || (body && body.scrollLeft) || 0) -
          ((doc && doc.clientLeft) || (body && body.clientLeft) || 0);
        event.pageY =
          event.clientY +
          ((doc && doc.scrollTop) || (body && body.scrollTop) || 0) -
          ((doc && doc.clientTop) || (body && body.clientTop) || 0);
      }

      mousePos = {
        x: event.pageX,
        y: event.pageY,
      };
    }
    if (!isShown) {
      document.onmousemove = handleMouseMove;
      document.onmouseleave = handleMouseOut;
    }
  }, []);

  return show;
}
