import { Card, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { routes } from '../app/router-new';
import * as _ from 'lodash';
import { useVideoCategories } from '../features';
import { Video, VideoThumbnail } from '../entities';

export function VideoGrid() {
  const categories = useVideoCategories();
  const navigate = useNavigate();

  const videos: Video[] = useMemo(() => {
    return _.sampleSize(_.flatMap(categories, 'videos'), 6);
  }, [categories]);

  return (
    <Card>
      <Typography variant={'h3'} sx={{ mb: 3 }}>
        Medicare Learning Center and Prime Time Podcasts
      </Typography>

      <Grid container spacing={3}>
        {videos.map((v) => (
          <Grid item xs={12} sm={6} md={4} key={v.id}>
            <VideoThumbnail
              video={v}
              onClick={() =>
                navigate(routes.video.value.replace(':id', v.id + ''))
              }
            />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
}
