import { Box, Button, Stack, Typography } from '@mui/material';
import {
  DrugTier,
  DrugTierCoverageOutput,
  DrugTierOutput,
  Plan,
} from '@coverright/data-access/types/medicare';
import { ArrowRight } from '@phosphor-icons/react';
import React, { useMemo } from 'react';
import {
  convertBold,
  convertList,
  getCompanyLogo,
  getGapTooltipByTier,
  getInitialTooltipByTier,
} from '@coverright/utils';
import { Tooltip } from '@coverright/ui/inputs';
import { useNavigate } from 'react-router';
import { Routes } from '../../../app/router';
import Benefit from '../../../components/benefit';
import { useLogEvent } from '@coverright/shared/analytics';
import { StarRating } from '@coverright/ui/shared';
import { getDeductible } from '@coverright/shared/config';
import { PlanWithTiers } from '@coverright/data-access/medicare';
import * as _ from 'lodash';
import { planFiles } from '../../../utils';
import { routes } from '../../../app/router-new';
import { CompareToggleButton, SelectPlanButton } from '../../../features';
import { getCatastrophicCoverage, getCoverageDrugTiers } from '../lib/lib';

export default function MaComparisonTable(props: {
  plans: PlanWithTiers[];
  minColumnsNumber?: number;
}) {
  const navigate = useNavigate();

  const goToDetails = (bidId: string) => {
    navigate(routes.planDetails.value.replace(':id', bidId));
  };

  const plans = React.useMemo(() => {
    if (
      !props.minColumnsNumber ||
      props.plans.length === props.minColumnsNumber
    ) {
      return props.plans;
    } else {
      return Array(props.minColumnsNumber)
        .fill(0)
        .map((v, i) => props.plans[i] || undefined);
    }
  }, [props.plans, props.minColumnsNumber]);

  const [preferred30, preferred30Gap] = getCoverageDrugTiers(
    plans,
    'drugsTiers30Preferred'
  );
  const [standard30, standard30Gap] = getCoverageDrugTiers(
    plans,
    'drugsTiers30Standard'
  );
  const [preferred90, preferred90Gap] = getCoverageDrugTiers(
    plans,
    'drugsTiers90Preferred'
  );
  const [standard90, standard90Gap] = getCoverageDrugTiers(
    plans,
    'drugsTiers90Standard'
  );

  const catastrophicCoverage = getCatastrophicCoverage(plans);

  return (
    <Box
      component={'table'}
      sx={{
        '* td': { verticalAlign: 'top' },
        mb: 2,
        tableLayout: 'fixed',
        width: {
          xs: '100%',
          sm: 50 * plans.length + '%',
          md: 33 * plans.length + '%',
        },
        '& td': { width: { xs: '100%', sm: '50%', md: '33%' } },
      }}
      cellSpacing={16}
    >
      <tbody>
        <tr>
          {plans.map((plan, i) => (
            <Box
              component={'td'}
              sx={{
                verticalAlign: 'center',
                background: 'rgba(28,67,79,0.05)',
                borderRadius: '8px',
                p: 1,
              }}
              key={plan?.bidId + i.toString()}
            >
              {!plan?.bidId && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography sx={{ color: '#666' }} className={'bold fs-18'}>
                    Add a Plan to compare
                  </Typography>
                </Box>
              )}
              {!!plan?.bidId && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    alignItems: 'center',
                    py: 3,
                  }}
                >
                  <StarRating plan={plan} />
                  <Box
                    sx={{
                      backgroundPosition: 'center',
                      height: 70,
                      mx: 2,
                      width: 1,
                      maxWidth: 160,
                      marginTop: 0,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      backgroundImage: `url("${getCompanyLogo(
                        plan?.parentOrgName,
                        plan?.orgName
                      )}")`,
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      minHeight: 110,
                      maxHeight: 110,
                    }}
                  >
                    <Typography className={'fs-18 lh-32 bold'} align={'center'}>
                      {plan?.planName}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ fontSize: 32, fontWeight: 600, mb: 3 }}
                    align={'center'}
                  >
                    {plan?.monthlyPremium}{' '}
                    <span className={'regular fs-16'}>/mo</span>
                  </Typography>
                  <Button
                    onClick={() =>
                      navigate(routes.drugCostPage.value + '/' + plan.bidId)
                    }
                    id={'ma-plan-table-view-drug-cost-breakdown-button'}
                    sx={{ border: '1px solid #1C434F', maxWidth: 199 }}
                    className={'medium p-12'}
                    size={'small'}
                    fullWidth
                    variant={'contained'}
                    color={'info'}
                  >
                    Drug Cost Breakdown
                  </Button>
                  <SelectPlanButton
                    id={'ma-plan-table-select-button'}
                    className={'medium p-12'}
                    sx={{ border: '1px solid #1C434F', maxWidth: 199, mt: 0.5 }}
                  >
                    Select plan
                  </SelectPlanButton>
                </Box>
              )}
            </Box>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {plan?.bidId && (
                <CompareToggleButton
                  type={'MA'}
                  plan={{ id: plan.bidId, name: plan.planName || '' }}
                />
              )}
            </td>
          ))}
        </tr>

        {!!plans.some((plan) => plan?.doctorsCoverage?.length) && (
          <tr>
            {plans.map((plan, i) => (
              <Box
                component={'td'}
                sx={{
                  verticalAlign: 'bottom!important',
                  background: 'rgba(28,67,79,0.05)',
                  borderRadius: '8px',
                  p: 2,
                }}
                key={plan?.bidId + i.toString()}
              >
                <Stack spacing={2}>
                  {!i && (
                    <Tooltip
                      arrow
                      title={
                        "Coverage of your doctors under this plan?. In-network and out-of network (if applicable) doctor costs will be subject to the copays or coinsurance outlined as per this plan's policy."
                      }
                    >
                      <Typography className={'fs-18 semi-bold'}>
                        Doctors <sup>i</sup>
                      </Typography>
                    </Tooltip>
                  )}
                  {plan?.doctorsCoverage?.map((doctor, i) => (
                    <React.Fragment key={doctor.npi + '' + i}>
                      <Benefit value={!!doctor.coverage} label={doctor.name} />
                    </React.Fragment>
                  ))}
                </Stack>
              </Box>
            ))}
          </tr>
        )}

        {!!plans.some(
          (plan) =>
            !plan?.missingFormularyInAep && !!plan?.drugsCoverage?.length
        ) && (
          <tr>
            {plans.map((plan, i) => (
              <Box
                component={'td'}
                sx={{
                  verticalAlign: 'bottom!important',
                  background: 'rgba(28,67,79,0.05)',
                  borderRadius: '8px',
                  p: 2,
                }}
                key={plan?.bidId + i.toString()}
              >
                <Stack spacing={2}>
                  {!i && (
                    <Tooltip
                      arrow
                      title={
                        "Coverage of your prescription drugs under this plan?. Covered drugs will be subject to payments of the retail cost up until you meet your deductible, and then copayments or coinsurance based on the drug's tier according to plan policy details."
                      }
                    >
                      <Typography className={'fs-18 semi-bold'}>
                        Drugs <sup>i</sup>
                      </Typography>
                    </Tooltip>
                  )}
                  {plan?.drugsCoverage?.map((drug, i) => (
                    <React.Fragment key={drug.packRxcui + '' + i}>
                      <Benefit
                        value={!!drug.coverage}
                        label={drug.name || 'Drug is missing'}
                      />
                    </React.Fragment>
                  ))}
                </Stack>
              </Box>
            ))}
          </tr>
        )}

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Premium Plan'}
                  value={
                    plan?.monthlyPremium ? plan?.monthlyPremium + ' /mo' : 'N/A'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Est. Drug'}
                  value={plan?.drugsCost ? plan?.drugsCost + ' /mo' : 'N/A'}
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Est. Doctor'}
                  value={plan?.doctorsCost ? plan?.doctorsCost + ' /mo' : 'N/A'}
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Est. avg. total cost'}
                  value={plan?.monthlyCost ? plan?.monthlyCost + ' /mo' : 'N/A'}
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={
                    <>
                      Excl. Standard Part B Premium
                      <br />
                      Excl. Optional benefits
                    </>
                  }
                  value={''}
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Button
                  variant={'text'}
                  size={'small'}
                  id={'view_ma_plan_details'}
                  onClick={() => goToDetails(plan?.bidId)}
                  endIcon={
                    <ArrowRight size={16} color="#1C434F" weight="bold" />
                  }
                >
                  <Typography className={'medium'} sx={{ color: '#1C434F' }}>
                    Learn more
                  </Typography>
                </Button>
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Box
                  sx={{ height: 65, borderBottom: '1px solid #B3B3B3', pt: 3 }}
                >
                  {!i && (
                    <Typography className={'fs-24 semi-bold'}>
                      Plan Highlights
                    </Typography>
                  )}
                </Box>
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Plan Type'}
                  value={plan?.planType ?? 'N/A'}
                  tooltip={
                    'Plan types include Preferred Provider Organizations (PPO) and Health Maintenance Organization (HMO) plans. PPO plans typically have a wider availability of healthcare providers, but often have higher doctor visit copays. HMO plans require you to select a Primary Care Physician, and you will need a referral to see a specialist. However, copays are typically lower than a PPO plan.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Monthly premium'}
                  value={plan?.monthlyPremium ?? 'N/A'}
                  tooltip={
                    'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Many Medicare Advantage plans have a $0 monthly premium.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Part B Giveback'}
                  value={
                    <Benefit
                      label={
                        plan?.partBAmount
                          ? `${plan?.partBAmount} per month`
                          : 'No'
                      }
                      value={!!plan?.partBAmount}
                    />
                  }
                  tooltip={
                    'A "Part B Give Back" is a feature in some Medicare Advantage plans that may help reduce some or all of the cost of a Medicare recipient\'s Part B premium each month.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Annual in-network deductible'}
                  value={
                    plan?.inNetworkDeductableAmount ??
                    'This plan does not have a deductible.'
                  }
                  tooltip={
                    'The amount you must pay out of pocket for your health care from in-network medical services before your coverage begins to pay. '
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Max out-of-pocket'}
                  value={plan?.outOfPocketAmount || 'N/A'}
                  tooltip={
                    'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan?.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Primary Doctor (in-network)'}
                  value={plan?.primaryCarePhysicianShort || 'N/A'}
                  tooltip={
                    'Primary and Specialist doctor copays are the amount you can expect to pay for a regular visit to your primary care physician or a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details. Hospital cost is the total amount you will pay under this plan for a 7 day inpatient hospital stay.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Specialist (in-network)'}
                  value={plan?.physicianSpecialistShort?.join(',')}
                  tooltip={
                    'Primary and Specialist doctor copays are the amount you can expect to pay for a regular visit to your primary care physician or a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details. Hospital cost is the total amount you will pay under this plan for a 7 day inpatient hospital stay.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Inpatient Hospital Care'}
                  value={plan?.hospitalBenefits}
                  tooltip={
                    'Includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Prescription Drug Coverage'}
                  value={plan?.extraBenefits?.drugCoverage ? 'Yes' : 'No'}
                  tooltip={
                    'Part D (Prescription drug coverage) includes coverage for your prescription drugs. Part D plans can be purchased separately to accompany Original Medicare and Medigap plans. Part D plans are also often bundled with Medicare Advantage plans.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Prescription Drug Deductible'}
                  value={
                    plan?.drugDetails?.mrxAltDedAmount ||
                    plan?.drugDetails?.mrxAltNoDedTier
                      ? [
                          plan?.drugDetails?.mrxAltDedAmount || '',
                          plan?.drugDetails?.mrxAltNoDedTier || '',
                        ]
                      : 'No deductible'
                  }
                  tooltip={
                    'The amount you must pay out of pocket for your prescription drugs before your coverage begins to pay. '
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Hospital (In-network)'}
                  value={plan?.firstWeekOfStay || 'N/A'}
                  tooltip={
                    'Primary and Specialist doctor copays are the amount you can expect to pay for a regular visit to your primary care physician or a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details. Hospital cost is the total amount you will pay under this plan for a 7 day inpatient hospital stay.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Box
                  sx={{ height: 65, borderBottom: '1px solid #B3B3B3', pt: 3 }}
                >
                  {!i && (
                    <Tooltip
                      arrow
                      title={
                        'Many Medicare Advantage plans offer additional vision, hearing and dental benefits at no extra cost, such as eye exams and eye glasses, hearing aids and dentures.'
                      }
                    >
                      <Typography className={'fs-24 semi-bold'}>
                        Benefits <sup>i</sup>
                      </Typography>
                    </Tooltip>
                  )}
                </Box>
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Benefit
                    label={'Dental'}
                    value={!!plan?.extraBenefits?.dental}
                  />
                  <Benefit
                    label={'Vision'}
                    value={!!plan?.extraBenefits?.vision}
                  />
                  <Benefit
                    label={'Hearing'}
                    value={!!plan?.extraBenefits?.hearing}
                  />
                  <Benefit
                    label={'Fitness'}
                    value={!!plan?.extraBenefits?.fitness}
                  />
                  <Benefit
                    label={'Insulin savings'}
                    value={!!plan?.extraBenefits?.insulinSavings}
                  />
                  <Benefit label={'OTC benefits'} value={!!plan?.oTC} />
                  <Benefit
                    label={'Telehealth'}
                    value={!!plan?.extraBenefits?.telehealth}
                  />
                  <Benefit
                    label={'Transportation'}
                    value={!!plan?.transportation}
                  />
                </Box>
              )}
            </td>
          ))}
        </tr>

        {/* Drug table */}

        <tr>
          <td colSpan={plans.length}>
            <Box sx={{ height: 65, borderBottom: '1px solid #B3B3B3', pt: 3 }}>
              <Typography className={'fs-24 semi-bold'}>
                Drug Coverage & Costs
              </Typography>
            </Box>
          </td>
        </tr>

        <tr>
          <Box
            component={'td'}
            sx={{ background: '#F4F6F6' }}
            colSpan={plans.length}
          >
            <Tooltip
              arrow
              title={`You pay the full retail price of your drugs up until you meet the deductible level (if applicable).`}
            >
              <Typography
                sx={{ width: 'fit-content' }}
                className={'fs-20 p-16 semi-bold'}
              >
                Deductible phase <sup>i</sup>
              </Typography>
            </Tooltip>
          </Box>
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Deductible amount'}
                  value={
                    plan?.drugDetails?.mrxAltDedAmount &&
                    plan?.drugDetails?.mrxAltNoDedTier
                      ? [
                          plan?.drugDetails?.mrxAltDedAmount,
                          plan?.drugDetails?.mrxAltNoDedTier,
                        ]
                      : '$0'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          <Box
            component={'td'}
            sx={{ background: '#F4F6F6' }}
            colSpan={plans.length}
          >
            <Tooltip
              arrow
              title={`Initial coverage starts after you your deductible (if applicable).  During this phase, your plan pays for a portion retail costs until the combined costs paid by you and your plan reaches ${getDeductible(
                'initialCoverageLimit', plans[0]?.planYear
              )}.`}
            >
              <Typography
                sx={{ width: 'fit-content' }}
                className={'fs-20 p-16 semi-bold'}
              >
                Initial Coverage phase <sup>i</sup>
              </Typography>
            </Tooltip>
          </Box>
        </tr>

        <DrugTiersSection
          tiers={preferred30}
          title={'Preferred Retail Pharmacy cost-sharing (30 day supply)'}
          plans={plans}
          planFieldKey={'drugsTiers30Preferred'}
        />

        <DrugTiersSection
          tiers={standard30}
          title={'Standard retail cost-sharing (30 day supply)'}
          plans={plans}
          planFieldKey={'drugsTiers30Standard'}
        />

        <DrugTiersSection
          tiers={preferred90}
          title={'Preferred mail-order cost-sharing (90 day supply)'}
          plans={plans}
          planFieldKey={'drugsTiers90Preferred'}
        />

        <DrugTiersSection
          tiers={standard90}
          title={'Standard mail-order cost-sharing (90 day supply)'}
          plans={plans}
          planFieldKey={'drugsTiers90Standard'}
        />

        <tr>
          <Box
            component={'td'}
            sx={{ background: '#F4F6F6' }}
            colSpan={plans.length}
          >
            <Tooltip
              arrow
              title={`The Coverage Gap starts after the total drug costs paid by you and the plan reach ${getDeductible(
                'initialCoverageLimit'
              )}, up to the out-of-pocket maximum of ${getDeductible(
                'catastrophicCoverageLimit'
              )}.`}
            >
              <Typography
                sx={{ width: 'fit-content' }}
                className={'fs-20 p-16 semi-bold'}
              >
                Coverage Gap phase <sup>i</sup>
              </Typography>
            </Tooltip>
          </Box>
        </tr>

        <DrugTiersSection
          tiers={preferred30Gap}
          title={'Preferred retail cost-sharing (30 day supply)'}
          plans={plans}
          planFieldKey={'drugsTiers30Preferred'}
          type={'gap'}
          showAlways={!!preferred30}
          alternateValue={(plan) =>
            !plan.extraBenefits.drugCoverage ? 'n.a.' : '25% coinsurance'
          }
        />

        <DrugTiersSection
          tiers={standard30Gap}
          title={'Standard retail cost-sharing (30 day supply)'}
          plans={plans}
          planFieldKey={'drugsTiers30Standard'}
          type={'gap'}
          showAlways={!!standard30}
          alternateValue={(plan) =>
            !plan.extraBenefits.drugCoverage ? 'n.a.' : '25% coinsurance'
          }
        />

        <DrugTiersSection
          tiers={preferred90Gap}
          title={'Preferred mail-order cost-sharing (90 day supply)'}
          plans={plans}
          planFieldKey={'drugsTiers90Preferred'}
          type={'gap'}
          showAlways={!!preferred90}
          alternateValue={(plan) =>
            !plan.extraBenefits.drugCoverage ? 'n.a.' : '25% coinsurance'
          }
        />

        <DrugTiersSection
          tiers={standard90Gap}
          title={'Standard mail-order cost-sharing (90 day supply)'}
          plans={plans}
          planFieldKey={'drugsTiers90Standard'}
          type={'gap'}
          showAlways={!!standard90}
          alternateValue={(plan) =>
            !plan.extraBenefits.drugCoverage ? 'n.a.' : '25% coinsurance'
          }
        />

        <tr>
          <Box
            component={'td'}
            sx={{ background: '#F4F6F6' }}
            colSpan={plans.length}
          >
            <Tooltip
              arrow
              title={`Catastrophic coverage starts when your annual out-of-pocket costs exceed ${getDeductible(
                'catastrophicCoverageLimit', plans[0]?.planYear
              )}.`}
            >
              <Typography
                sx={{ width: 'fit-content' }}
                className={'fs-20 p-16 semi-bold'}
              >
                Catastrophic Coverage phase <sup>i</sup>
              </Typography>
            </Tooltip>
          </Box>
        </tr>

        {catastrophicCoverage.map((tier, i) => (
          <tr key={tier.title}>
            {plans.map((plan, j) => (
              <td key={plan?.bidId + j.toString()}>
                {!!plan?.bidId && (
                  <Param
                    title={tier.title}
                    tooltip={getGapTooltipByTier(
                      [
                        DrugTierOutput.Generic,
                        DrugTierOutput.NonPreferredBrand,
                      ][i]
                    )}
                    value={
                      plan.drugDetails?.catastrophicCoverage[0]?.items[i]
                        ?.values ?? ''
                    }
                  />
                )}
              </td>
            ))}
          </tr>
        ))}

        {/* End Drug table */}

        {/* Medical & Hospital Coverage */}

        <tr>
          <td colSpan={plans.length}>
            <Box sx={{ height: 65, borderBottom: '1px solid #B3B3B3', pt: 3 }}>
              <Typography className={'fs-24 semi-bold'}>
                Medical & Hospital Coverage
              </Typography>
            </Box>
          </td>
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Primary Doctor Visit'}
                  value={plan?.primaryCarePhysician}
                  tooltip={
                    'The amount you can expect to pay for a regular visit to your primary care physician as outlined in your policy details.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Specialist Visit'}
                  value={plan?.physicianSpecialist}
                  tooltip={
                    'The amount you can expect to pay for a visit to a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Preventive Care'}
                  value={plan?.preventiveCare}
                  tooltip={
                    'Typically, periodic health exams that occur on a regular basis for preventive purposes, including routine physicals or annual check-ups.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Emergency Room'}
                  value={plan?.hospitalityDetails.emergencyRoom}
                  tooltip={
                    "Typically, emergency room services include all services provided when a patient visits an emergency room for an emergency condition. An emergency condition is any medical situation that would lead most people to believe that failing to obtain immediate medical care could result in placing the patient's health at serious risk."
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Urgently Needed Services'}
                  value={plan?.hospitalityDetails.urgentServices}
                  tooltip={
                    "Medical care that you get outside of your Medicare health plan's service area for a sudden illness or injury that needs attention but isn't life threatening. If it's not safe to wait until you get home to get care from a plan doctor, then the health plan must pay for the care."
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Ambulance Services'}
                  value={plan?.hospitalityDetails.ambulanceServices}
                  tooltip={
                    'Transport by ambulance to and from your home and a medical facility to receive medical care. Medicare covers a number of different types of ambulance services depending on the seriousness of your medical condition, or whether other methods of transportation could endanger your health.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Inpatient Hospital Care'}
                  value={plan?.hospitalBenefits || 'N/A'}
                  tooltip={
                    'Includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Skilled Nursing Facilities (SNF)'}
                  value={plan?.skilledNursing}
                  tooltip={
                    'A nursing facility with the staff and equipment to give skilled nursing care and, in most cases, skilled rehabilitative services and other related health services.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Outpatient Diagnostic Tests and Therapeutic Services'}
                  value={plan?.outpatientHospitality}
                  tooltip={
                    'Tests done to help a doctor diagnose or rule out a suspected illness, injury, or condition. These tests are ordered by your treating doctor and may include x-rays, blood tests, urinalysis, and tests done on tissue specimens.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Outpatient Hospital and Surgery'}
                  value={plan?.outpatientSurgery}
                  tooltip={
                    'Typically defined as any surgical procedure that does not require an overnight stay in a hospital.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Outpatient Rehabilitation Services'}
                  value={plan?.outpatientRehabilitation}
                  tooltip={
                    'Services to help you regain abilities, such as speech or walking, that have been impaired by an illness or injury. These services take place in an outpatient setting. These services are provided by nurses, as well as by physical, occupational and speech therapists. Examples include working with a physical therapist to help you walk or working with an occupational therapist to help you get dressed.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Outpatient Mental Health Care'}
                  value={plan?.mentalHealth}
                  tooltip={
                    'Typically for services provided by a mental health professional in an outpatient setting.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Home Health Care'}
                  value={plan?.homeHealth}
                  tooltip={
                    'Health care services and supplies that a doctor decides you may receive in your home under a doctor-established plan of care. Medicare only covers home health care on a limited basis as ordered by your doctor.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        {/* End Medical & Hospital Coverage */}

        {/* Extra Benefits */}

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Box
                  sx={{ height: 65, borderBottom: '1px solid #B3B3B3', pt: 3 }}
                >
                  {!i && (
                    <Typography className={'fs-24 semi-bold'}>
                      Extra Benefits
                    </Typography>
                  )}
                </Box>
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Optional supplemental benefits'}
                  value={plan?.optionalSupplementalBenefitsItems}
                  tooltip={
                    'An added benefit option, available with select plans, that a member may choose to elect in addition to the medical benefits covered by their Medicare Advantage plan.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Dental Services'}
                  value={plan?.dental?.benefits || []}
                  tooltip={
                    'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Vision Services'}
                  value={plan?.vision?.benefits || []}
                  tooltip={
                    'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Hearing Services'}
                  value={plan?.hearing?.benefits || []}
                  tooltip={
                    'Medicare Advantage plans often provide built-in hearing benefits for no additional premium. For example: coverage for routine hearing exams and hearing aids.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Transportation Services'}
                  value={plan?.transportation}
                  tooltip={
                    'Limited. Diagnostic hearing and balance exams: 20% of the cost after the Part B deductible'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Fitness Services'}
                  value={plan?.otherDefinedSupplemental?.fitnessBenefit}
                  tooltip={
                    'Medicare Advantage plans often provide built-in fitness benefits or gym membership for no additional premium, for example gym memberships or fitness programs.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Insulin Savings'}
                  value={
                    <Benefit
                      label={''}
                      value={!!plan?.extraBenefits?.insulinSavings}
                    />
                  }
                  tooltip={
                    "Medicare Advantage plans that offer Insulin Savings are part of Medicare's Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit."
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Meals After Hospital Stay'}
                  value={plan?.meal}
                  tooltip={
                    'Healthy food or home deliveries for plan members post discharge from hospital or skilled nursing facility.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Over-the-Counter Allowance'}
                  value={plan?.oTC}
                  tooltip={
                    'A periodical (such as monthly or quarterly) allowance for over-the-counter allowance pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Telehealth Services'}
                  value={plan?.telehealth}
                  tooltip={
                    'Medicare Advantage plans often provide telehealth benefits for no additional premium. These benefits can provide beneficiaries with more accessibility to medical and health-related services, through digital communications technologies, such as mobile phones, tablets and desktop computers.'
                  }
                />
              )}
            </td>
          ))}
        </tr>

        {/* End Extra Benefits */}

        {/* Plan Brochures */}

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Box
                  sx={{ height: 65, borderBottom: '1px solid #B3B3B3', pt: 3 }}
                >
                  {!i && (
                    <Typography className={'fs-24 semi-bold'}>
                      Plan Brochures
                    </Typography>
                  )}
                </Box>
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Plan Brochures'}
                  naLabel={'Not available'}
                  value={planFiles(plan.planFiles).map((item) => (
                    <Box
                      component={'a'}
                      sx={{
                        fontSize: 12,
                        color: 'rgba(28, 67, 79, 1)',
                        display: 'block',
                        textDecoration: 'underline',
                      }}
                      target={'_blank'}
                      href={item.url}
                    >
                      {item.label}
                    </Box>
                  ))}
                />
              )}
            </td>
          ))}
        </tr>

        <tr>
          {plans.map((plan, i) => (
            <td key={plan?.bidId + i.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={'Medicare Brochures'}
                  value={
                    <Box
                      component={'a'}
                      sx={{
                        fontSize: 12,
                        color: 'rgba(28, 67, 79, 1)',
                        textDecoration: 'underline',
                      }}
                      target={'_blank'}
                      href={
                        'https://www.medicare.gov/Pubs/pdf/10050-Medicare-and-You.pdf'
                      }
                    >
                      Medicare & You Handbook (
                      {plan.planYear?.toString().replace('Year', '')})
                    </Box>
                  }
                />
              )}
            </td>
          ))}
        </tr>

        {/* End Plan Brochures */}
      </tbody>
    </Box>
  );
}

interface DrugTiersSectionProps {
  tiers?: DrugTierCoverageOutput[];
  plans: PlanWithTiers[];
  planFieldKey:
    | 'drugsTiers30Preferred'
    | 'drugsTiers30Standard'
    | 'drugsTiers90Preferred'
    | 'drugsTiers90Standard';
  title: string;
  type?: 'initial' | 'gap' | 'catastrofic';
  alternateValue?: (plan: PlanWithTiers) => string;
  showAlways?: boolean;
}

const DrugTiersSection = React.memo((props: DrugTiersSectionProps) => {
  const {
    tiers = [],
    planFieldKey,
    title,
    plans,
    showAlways,
    type = 'initial',
    alternateValue = () => 'n.a.',
  } = props;

  const tierFieldName = useMemo(() => {
    return type === 'gap' ? 'coverageGapDrugTiers' : 'initialCoverageDrugTiers';
  }, [type]);

  if (!showAlways && !tiers.length) {
    return null;
  }

  return (
    <>
      <tr>
        <Box
          component={'td'}
          sx={{ background: '#F3FBFC' }}
          colSpan={plans.length}
        >
          <Typography className={'fs-18 p-16 medium'}>{title}</Typography>
        </Box>
      </tr>
      {tiers.map((tier, i) => (
        <tr key={tier.tierName}>
          {plans.map((plan, j) => (
            <td key={plan?.bidId + j.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={tier.tierName}
                  tooltip={getInitialTooltipByTier(tier.tier)}
                  value={
                    _.get(plan, [planFieldKey, tierFieldName, i, 'coverage']) ??
                    alternateValue(plan)
                  }
                />
              )}
            </td>
          ))}
        </tr>
      ))}
      {type === 'gap' && (
        <tr>
          {plans.map((plan, j) => (
            <td key={plan?.bidId + j.toString()}>
              {!!plan?.bidId && (
                <Param
                  title={`All ${tiers.length ? 'other' : 'drug'} tiers`}
                  value={
                    !plan.extraBenefits.drugCoverage
                      ? 'n.a.'
                      : '25% coinsurance'
                  }
                />
              )}
            </td>
          ))}
        </tr>
      )}
    </>
  );
});

const Param = (props: {
  title: string | JSX.Element;
  value: string | string[] | JSX.Element | JSX.Element[];
  tooltip?: string;
  naLabel?: string;
}) => (
  <Box sx={{ width: 'fit-content' }}>
    <Tooltip arrow title={props.tooltip || ''}>
      <Typography className={'semi-bold'} sx={{ mb: 0.25 }}>
        {props.title} {props.tooltip && <sup>i</sup>}
      </Typography>
    </Tooltip>
    {typeof props.value === 'string' && (
      <Typography>{props.value ?? props.naLabel ?? 'Not covered'}</Typography>
    )}
    {typeof props.value !== 'string' && (
      <>
        {Array.isArray(props.value) && (
          <>
            {!props.value.length && (
              <Typography>{props.naLabel ?? 'Not covered'}</Typography>
            )}
            {props.value.map((line, i) => (
              <React.Fragment key={i}>
                {typeof line === 'string' && (
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: convertList(convertBold(line)),
                    }}
                  ></Typography>
                )}
                {typeof line !== 'string' && <>{line}</>}
              </React.Fragment>
            ))}
          </>
        )}
        {!Array.isArray(props.value) && (
          <>
            {props.value ?? (
              <Typography>{props.naLabel ?? 'Not covered'}</Typography>
            )}
          </>
        )}
      </>
    )}
  </Box>
);
