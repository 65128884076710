import React, { useMemo } from 'react';
import { FilterProps } from '../../../shared';
import {
  getSelectedMgCategoriesByPlans,
  isAllMgCategoriesSelected,
  planCategories,
  PlanCategory,
  PlanFilter,
} from '../../../features';
import {
  getDisplayValue,
  handleCategoryChange,
  isCategorySelected,
  toListItem,
} from '../lib/lib';

export default function PlanTypesFilter({ value, onChange }: FilterProps) {
  const allSelected = isAllMgCategoriesSelected(value);

  const isChecked = (name: PlanCategory | 'all'): boolean =>
    name === 'all'
      ? allSelected
      : !allSelected && isCategorySelected(value)(name);

  function switchCategory(cat: Array<PlanCategory | 'all'>) {
    onChange(handleCategoryChange(cat));
  }

  const selected = useMemo(() => {
    return getSelectedMgCategoriesByPlans(value).map((c) => c.name);
  }, [value]);

  return (
    <PlanFilter
      value={selected}
      onChange={switchCategory}
      label={'Plan type'}
      getDisplayValue={getDisplayValue}
      options={[
        { value: 'all', label: 'All' },
        ...planCategories.map(toListItem),
      ].map((t) => ({ ...t, checked: isChecked(t.value) }))}
      multiple
    />
  );
}
