import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import {
  MedicarePlansAggregationOutput,
  MedicarePlanType,
  QueryMedicarePlansAggregationArgs
} from '@coverright/data-access/types/medicare';
import { client } from '@coverright/data-access/apollo-clients';
import * as React from 'react';

export function useMedicarePlansAggregation(options?: LazyQueryHookOptions<{medicarePlansAggregation: MedicarePlansAggregationOutput}, QueryMedicarePlansAggregationArgs>) {
  return useLazyQuery<{medicarePlansAggregation: MedicarePlansAggregationOutput}, QueryMedicarePlansAggregationArgs>(
    gql(getDrugRequest),
    {
      ...options
    }
  );
}


export function useMedicarePlansAggregationByTypes(types?: MedicarePlanType[], zip?: string, county?: string) {
  const [data, setData] = React.useState<any>();
  const [params, setParams] = React.useState<any>({})
  React.useEffect(() => {
    if (types && types !== params.types && zip && zip !== params.zip && county && county !== params.county ) {
      setParams({types, zip, county})
    }
  }, [types, zip, county]);

  React.useEffect(() => {
    if (params.types && params.zip && params.county) {
      client.query({
        query: gql(getRequestByTypes(params.types, params.zip, params.county)),
        fetchPolicy: 'no-cache'
      }).then(response => {
        setData(response?.data)
      })
    }
  }, [params]);
  return data;
}

const getRequestByTypes = (types: MedicarePlanType[], zip: string, county: string) => {
  let request = `query {`;
  types.forEach(type => {
    request += type + `: medicarePlansAggregation(zip: "${zip}", county: "${county}", planType: ${type}, allowAllStates: true) {
      ${fields}
    }
    `
  })
  request += `DSNP: medicarePlansAggregation(zip: "${zip}", county: "${county}", dsnpOnly: true, allowAllStates: true) {
      ${fields}
    }
    `
  request += `}`
  return request;
}


const fields = `
    doctorMax
    doctorMin
    longTermHospitalStayMax
    longTermHospitalStayMaxDays
    longTermHospitalStayMin
    longTermHospitalStayMinDays
    maxOutOfPocketInNetworkMax
    maxOutOfPocketInNetworkMin
    maxOutOfPocketOutOfNetworkMax
    maxOutOfPocketOutOfNetworkMin
    monthlyPremiumMax
    monthlyPremiumMin
    shortTermHospitalStayMax
    shortTermHospitalStayMaxDays
    shortTermHospitalStayMin
    shortTermHospitalStayMinDays
    dsnpPlansExist
    mmpHmoPlansExist
    tooFewMPDPPlans
    tooFewPPOPlans
    deductibleMax
    deductibleMin
    doctorInNetworkMax
    doctorInNetworkMin
    doctorOutOfNetworkMax
    doctorOutOfNetworkMin
`

const getDrugRequest = `
query ($county: String!, $zip: String!, $planType: MedicarePlanType) {
  medicarePlansAggregation(zip: $zip, county: $county, planType: $planType) {
  ${fields}
  }
}
`;
