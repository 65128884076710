import { Box, Container, Typography } from '@mui/material';
import { SxProps } from '@mui/material';
import * as React from 'react';
import * as _ from 'lodash';
import {
  FacebookLogo,
  LinkedinLogo,
  TwitterLogo,
  YoutubeLogo,
} from '@phosphor-icons/react';
import { useLogEvent } from '@coverright/shared/analytics';

const Footer = () => {
  const logEvent = useLogEvent();

  return (
    <Box
      sx={{
        background: '#1C434F',
        py: '96px',
        width: 1,
        '& *': { color: 'white!important' },
      }}
    >
      <Container maxWidth={'lg'}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 4, md: 0 },
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <img
              src={'/plan-finder/assets/img/Logo-footer.svg'}
              width={164}
              height={31}
            />
            <Typography
              sx={{ fontSize: 16, lineHeight: '24px', mt: 3, maxWidth: 200 }}
            >
              195 Broadway, 4th Floor Brooklyn, New York 11211
            </Typography>
            <Typography
              className={'epilogue'}
              sx={{ fontSize: 16, lineHeight: '24px', mt: 7, fontWeight: 700 }}
            >
              General Customer Support:
            </Typography>
            <Typography sx={{ fontSize: 16, lineHeight: '24px' }}>
              +1 (888) 969-7667 | TTY: 711
            </Typography>
            <Typography className={'epilogue bold'} sx={{ mt: 3 }}>
              Hours:
            </Typography>
            <Typography sx={{ fontSize: 16, lineHeight: '24px' }}>
              Mon-Fri 8AM-10PM (ET)
              <br />
              Sat-Sun 9AM-7PM (ET)
            </Typography>
            <Box sx={{ display: 'flex', gap: 3, my: 7 }}>
              <Box
                id={'facebook-share-button'}
                component={'a'}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  logEvent('click', {
                    element_id: 'facebook-share-button',
                    element_title: 'Facebook',
                  }).then(() =>
                    window.open('https://www.facebook.com/coverrightmedicare')
                  );
                }}
                href={'https://www.facebook.com/coverrightmedicare'}
                target={'_blank'}
              >
                <FacebookLogo size={32} color="#FFFFFF" weight="fill" />
              </Box>
              <Box
                id={'twitter-share-button'}
                component={'a'}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  logEvent('click', {
                    element_id: 'twitter-share-button',
                    element_title: 'Twitter',
                  }).then(() => window.open('https://twitter.com/coverright_'));
                }}
                href={'https://twitter.com/coverright_'}
                target={'_blank'}
              >
                <TwitterLogo size={32} color="#FFFFFF" weight="fill" />
              </Box>
              <Box
                id={'linkedin-share-button'}
                component={'a'}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  logEvent('click', {
                    element_id: 'linkedin-share-button',
                    element_title: 'LinkedIn',
                  }).then(() =>
                    window.open('https://www.linkedin.com/company/coverright')
                  );
                }}
                href={'https://www.linkedin.com/company/coverright'}
                target={'_blank'}
              >
                <LinkedinLogo size={32} color="#FFFFFF" weight="fill" />
              </Box>
              <Box
                id={'youtube-share-button'}
                component={'a'}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  logEvent('click', {
                    element_id: 'youtube-share-button',
                    element_title: 'Youtube',
                  }).then(() =>
                    window.open(
                      'https://www.youtube.com/channel/UCWVqCsXdeLGim-nFtQ6NJAQ'
                    )
                  );
                }}
                href={
                  'https://www.youtube.com/channel/UCWVqCsXdeLGim-nFtQ6NJAQ'
                }
                target={'_blank'}
              >
                <YoutubeLogo size={32} color="#FFFFFF" weight="fill" />
              </Box>
            </Box>
            <Typography
              sx={{ fontSize: 16, lineHeight: '24px', mt: 3, maxWidth: 266 }}
            >
              CoverRight.com is owned and operated by CoverRight Inc. Insurance
              agency services are provided by CoverRight Insurance Services Inc.
            </Typography>
          </div>
          <MenuColumn
            sx={{ display: { xs: 'none', lg: 'flex' } }}
            menus={[menuItems[0]]}
          />
          <MenuColumn
            sx={{ display: { xs: 'none', lg: 'flex' } }}
            menus={[menuItems[1], menuItems[2]]}
          />
          <MenuColumn
            sx={{ display: { xs: 'flex', lg: 'none' } }}
            menus={[menuItems[0], menuItems[1], menuItems[2]]}
          />
          <img
            src={'/plan-finder/assets/img/Badges.png'}
            width={164}
            height={231}
          />
        </Box>
        <Typography sx={{ fontSize: 16, lineHeight: '24px', mt: 4 }}>
          CoverRight Insurance Services Inc. represents Medicare Advantage HMO,
          PPO and PFFS organizations that have a Medicare contract. Enrollment
          depends on the plan’s contract renewal.
        </Typography>
        <Typography sx={{ fontSize: 16, lineHeight: '24px', mt: 4 }}>
          We do not offer every plan available in your area. Any information we
          provide is limited to those plans we do offer in your area. Please
          contact Medicare.gov or 1–800–MEDICARE 24 hours a day/7 days a week to
          get information on all of your options.
        </Typography>
        <Typography sx={{ fontSize: 16, lineHeight: '24px', mt: 4 }}>
          © Copyright {new Date().getFullYear()}. CoverRight Inc. All rights
          reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;

const MenuColumn = React.memo(function MenuColumn({
  menus,
  sx,
}: {
  menus: IMainMenuItem[];
  sx?: SxProps;
}) {
  const logEvent = useLogEvent();
  if (!_.compact(menus).length) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7, ...sx }}>
      {_.compact(menus).map((item?: IMainMenuItem) => (
        <Box
          key={item?.title}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          {
            <Typography className={'epilogue semi-bold'} sx={{ color: '#fff' }}>
              {item?.title}
            </Typography>
          }
          {item?.items?.map((i) => {
            const id = _.kebabCase(i?.title) + '-footer-menu-item';
            return (
              <Box
                key={id}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  logEvent('click', {
                    element_id: id,
                    element_title: i?.title,
                  }).then(() => {
                    if (e.ctrlKey) {
                      window.open(i.url);
                    } else {
                      document.location.href = i.url;
                    }
                  });
                }}
                component={'a'}
                href={i.url}
                sx={{
                  color: '#fff',
                  textDecoration: 'none',
                  fontSize: 16,
                  lineHeight: '24px',
                }}
              >
                {i.title}
              </Box>
            );
          })}
        </Box>
      ))}
    </Box>
  );
});

interface IMainMenuItem {
  title: string;
  subtitle?: string;
  url?: string;
  description?: string;
  items?: { title: string; url: string }[];
}

const menuItems: IMainMenuItem[] = [
  {
    title: 'Learn',
    subtitle: 'Medicare Resources',
    description:
      'Learn about Medicare with CoverRight®.  Review our content to understand the basics and get up to speed with the different parts of Medicare and private plan options available.   ',
    items: [
      {
        title: 'Medicare 101',
        url: 'https://coverright.com/blog/medicare-enrollment/what-is-medicare-101/',
      },
      {
        title: 'Part A - Hospital',
        url: 'https://coverright.com/blog/medicare-enrollment/what-is-medicare-part-a/',
      },
      {
        title: 'Part B - Medical',
        url: 'https://coverright.com/blog/medicare-101/what-is-medicare-part-b/',
      },
      {
        title: 'Part C - Medicare Advantage',
        url: 'https://coverright.com/blog/medicare-advantage/what-is-medicare-part-c/',
      },
      {
        title: 'Part D - Prescription Drug Plan',
        url: 'https://coverright.com/blog/medicare-part-d/what-is-medicare-part-d/',
      },
      {
        title: 'Medicare Supplement (‘Medigap’)',
        url: 'https://coverright.com/blog/medicare-101/medicare-supplement-medigap',
      },
    ],
  },
  {
    title: 'About',
    subtitle: 'About',
    description:
      'We’ve assembled a passionate, diverse and driven team to help make Medicare easy.  At CoverRight®, our mission is to make Medicare more transparent and accessible for America’s 60 million+ Medicare beneficiaries.',
    items: [
      {
        title: 'About Us',
        url: 'https://coverright.com/about-us/',
      },
      {
        title: 'News & Reviews',
        url: 'https://coverright.com/reviews',
      },
      {
        title: 'Careers',
        url: 'https://www.linkedin.com/company/coverright/jobs/',
      },
    ],
  },
  {
    title: 'Legal',
    subtitle: 'Legal',
    description: '',
    items: [
      {
        title: 'Terms of Use',
        url: 'https://coverright.com/coverright-terms-of-use/',
      },
      {
        title: 'Privacy Policy',
        url: 'https://coverright.com/privacy/',
      },
      {
        title: 'Licensing',
        url: 'https://coverright.com/licensing/',
      },
    ],
  },
];
