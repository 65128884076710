import React from 'react';
import { maPlanTypes } from '../../../entities';
import { FilterProps, ListItem, useAppSelector } from '../../../shared';
import { PlanFilter } from '../../../features';

export function PlanTypesFilter({
  value,
  planTypes,
  onChange,
}: FilterProps & { planTypes: ListItem[] }) {
  return (
    <PlanFilter
      value={value}
      onChange={onChange}
      label={'Plan type'}
      data-test={'plan-type-filter'}
      options={planTypes.map((t) => ({
        ...t,
        checked: value?.includes(t.value),
      }))}
      multiple
    />
  );
}

export default function (props: FilterProps) {
  const planTypes = useAppSelector(maPlanTypes);
  return <PlanTypesFilter {...props} planTypes={planTypes} />;
}
