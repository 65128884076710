import React from 'react';
import { V3Preloader } from '@coverright/ui/shared';
import { Box, Card, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import MaComparisonTable from './ui/ma-comparison-table';
import { useAppSelector } from '../../shared/hooks';
import {
  BackButton,
  maComparisonPlans,
  Paginator,
  usePageSize,
} from '../../features';
import { medicareQuote } from '../../entities/ma-quote';
import { SideLayout } from '../../shared';
import { routes } from '../../app/router-new';
import useMedicarePlansByBid from './api/use-medicare-plans-by-bid';
import useClearComparisonPlans from './lib/use-clear-comparison-plans';

export default function () {
  const navigate = useNavigate();

  const list = useAppSelector(maComparisonPlans);
  const quote = useAppSelector(medicareQuote);

  const [page, setPage] = React.useState(0);
  const pageSize = usePageSize();

  const plans = useMedicarePlansByBid(
    quote,
    list,
    useClearComparisonPlans(list)
  );

  const loading = React.useMemo(() => {
    return list?.length !== plans?.length;
  }, [list, plans]);

  const currentPlans = React.useMemo(() => {
    return plans.slice(pageSize * page, pageSize * page + pageSize);
  }, [plans, page, pageSize]);

  React.useEffect(() => {
    if (!list.length) {
      navigate(routes.planFinderMA.value);
    }
  }, [list]);

  return (
    <SideLayout
      title={'Medicare Advantage Plans'}
      subtitle={
        <BackButton
          label={'Back to All Plans'}
          onClick={() => navigate(routes.planFinderMA.value)}
        />
      }
    >
      <Card sx={{ p: 1, flex: 1 }} id={'ma-comparison-card'}>
        <Typography className={'epilogue fs-24 lh-28 semi-bold m-24'}>
          Compare your next medicare plan with CoverRight
        </Typography>

        <Paginator
          onChange={setPage}
          page={page}
          pageSize={pageSize}
          loading={loading}
          total={plans?.length}
        />

        {!!plans.length && (
          <MaComparisonTable plans={currentPlans} minColumnsNumber={pageSize} />
        )}

        {loading && (
          <Box sx={{ pt: 4, pb: 6 }}>
            <V3Preloader />
          </Box>
        )}

        {!loading && plans.length && (
          <Paginator
            onChange={setPage}
            page={page}
            pageSize={pageSize}
            loading={loading}
            total={plans?.length}
            scrollTo={document.getElementById('ma-comparison-card')}
          />
        )}
      </Card>
    </SideLayout>
  );
}
