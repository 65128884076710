import { environment } from '../../../../environments/environment';
import * as _ from 'lodash';

export default async function getStrapiBenefits(
  enrolledPlan: any
): Promise<{ [key: string]: any[] }> {
  return fetch(
    environment.strapiUrl +
      `/api/benefit-descriptions?sort[0]=name:asc&filters[carrier][name][$eqi]=${enrolledPlan.parentOrgName}&populate=*&publicationState=live`,
    {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + process.env.NX_STRAPI_TOKEN,
      },
      cache: 'no-cache',
    }
  )
    .then((res) => res.json())
    .then((data: any) =>
      _.groupBy(data.data, (i) => i.attributes?.benefit?.data?.attributes?.name)
    );
}
