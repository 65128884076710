import * as React from 'react';
import {
  useDsnpOffConfirm,
  useMaFilters,
  usePartDConfirm,
} from '../../../features';
import { ExtraBenefit, SnpType } from '@coverright/data-access/types/medicare';

export default function useFilterBadges(): {
  label: string;
  onDelete: () => void;
}[] {
  const { filters, buildHandler } = useMaFilters();
  const dsnpOffConfirm = useDsnpOffConfirm();
  const partDConfirm = usePartDConfirm();

  return React.useMemo(() => {
    let result =
      filters?.planTypes?.map((label) => ({
        label,
        onDelete: () =>
          buildHandler('planTypes')(
            filters?.planTypes?.filter((c) => c !== label) || []
          ),
      })) || [];

    if (filters?.SNPTypes?.includes(SnpType.DSnp)) {
      result = result.concat([
        {
          label: 'DSNP',
          onDelete: () =>
            dsnpOffConfirm().then((val) => val && buildHandler('SNPTypes')([])),
        },
      ]);
    }

    if (filters?.extraBenefits?.includes(ExtraBenefit.DrugCoverage)) {
      result = result.concat([
        {
          label: 'Drug Coverage',
          onDelete: () =>
            partDConfirm().then(
              (val) => val && buildHandler('extraBenefits')([])
            ),
        },
      ]);
    }

    result = [
      ...result,
      ...(filters?.companies?.map((label) => ({
        label,
        onDelete: () =>
          buildHandler('companies')(
            filters?.companies?.filter((c) => c !== label) || []
          ),
      })) || []),
    ];

    return result;
  }, [filters]);
}
