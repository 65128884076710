import { useLazyMaPlanFilterQuery } from '../../shared/api/medicare';
import React from 'react';
import CompanyFilter from './ui/company-filter';
import PlanTypesFilter from './ui/plan-types-filter';
import { Box, Collapse, styled, Typography } from '@mui/material';
import ExtraBenefitsFilter from './ui/extra-benefits-filter';
import SnpTypesFilter from './ui/snp-types-filter';
import { PlanYear } from '@coverright/data-access/types/medicare';
import { GetNextPlansYear, useDebouncedEffect } from '@coverright/utils';
import { useAppSelector } from '../../shared/hooks';
import { doctorsCheckBoxMessage, drugsCheckBoxMessage } from '../../entities';
import CheckboxFilter from './ui/checkbox-filter';
import SortBySelect from './ui/sort-by-select';
import { useMaFilters, YearToYearSwitch } from '../../features';

export function MaFilters() {
  const { filters, buildHandler } = useMaFilters();
  const [getFilterItems, { isSuccess }] = useLazyMaPlanFilterQuery();
  const doctorsMessage = useAppSelector(doctorsCheckBoxMessage);
  const drugsMessage = useAppSelector(drugsCheckBoxMessage);

  useDebouncedEffect(
    () => {
      if (filters) {
        getFilterItems({ filters });
      }
    },
    800,
    [filters]
  );

  return (
    <Collapse in={isSuccess}>
      <div data-test={'ma-marketplace-filters'}>
        <Container
          sx={{
            gap: 3,
            background: { xs: 'white', md: 'none' },
            p: { xs: 2, sm: 4, md: 0 },
          }}
        >
          <Typography variant={'body2'} sx={{ whiteSpace: 'nowrap' }}>
            Filter By
          </Typography>
          <PlanTypesFilter
            value={filters?.planTypes || []}
            onChange={buildHandler('planTypes')}
          />
          <SnpTypesFilter
            value={filters?.SNPTypes || []}
            onChange={buildHandler('SNPTypes')}
          />
          <ExtraBenefitsFilter
            value={filters?.extraBenefits || []}
            onChange={buildHandler('extraBenefits')}
          />
          <CompanyFilter
            value={filters?.companies || []}
            onChange={buildHandler('companies')}
          />
          <SortBySelect />
        </Container>

        <Container sx={{ justifyContent: 'space-between' }}>
          <Container>
            <CheckboxFilter
              message={doctorsMessage}
              value={filters?.showTopDoctors || false}
              onChange={buildHandler('showTopDoctors')}
            />
            <CheckboxFilter
              message={drugsMessage}
              value={filters?.showTopDrugs || false}
              onChange={buildHandler('showTopDrugs')}
            />
          </Container>

          <YearToYearSwitch
            checked={!filters?.planYear || (filters?.planYear === GetNextPlansYear())}
            sx={{ mt: 1 }}
            onChange={buildHandler('planYear')}
          />
        </Container>
      </div>
    </Collapse>
  );
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
  },
  alignItems: 'center',
  gap: 24,
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
  borderRadius: '16px',
}));
