import React from 'react';
import { Badge, Tab, useMediaQuery, useTheme } from '@mui/material';

interface LinkTabProps {
  label: string;
  href: string;
  active: boolean;
  Icon: any;
  onClick: () => void;
  badge?: boolean;
}

export default function LinkTab(props: LinkTabProps) {
  const { Icon, active, href, label, onClick, badge, ...rest } = props;
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Tab
      data-test={'nav-tab'}
      component="a"
      iconPosition={tablet ? 'top' : 'start'}
      icon={
        <Icon
          size={24}
          className={'w-24 h-24'}
          weight={active ? 'fill' : 'regular'}
        />
      }
      {...rest}
      label={
        <Badge
          variant={'dot'}
          color={'error'}
          overlap={'rectangular'}
          invisible={!badge}
          sx={{ pr: 1 }}
        >
          {label}
        </Badge>
      }
      onClick={onClick}
    />
  );
}
