import React, { PropsWithChildren, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import AgentCard from '../../components/agent-card';

type Props = {
  title: string;
  subtitle?: string | JSX.Element;
  slots?: {
    start?: JSX.Element;
    end?: JSX.Element;
    side?: JSX.Element;
    headerSide?: JSX.Element;
  };
};

export function SideLayout(props: PropsWithChildren<Props>) {
  const subtitle = useMemo(() => {
    switch (typeof props.subtitle) {
      case 'string':
        return <Typography sx={{ mt: 1 }}>{props.subtitle}</Typography>;
      case 'object':
      case 'function':
        return props.subtitle;
      default:
        return null;
    }
  }, [props.subtitle]);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
          mb: 4,
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <div>
          <Typography variant={'h3'}>{props.title}</Typography>
          {subtitle}
        </div>
        {props.slots?.headerSide}
      </Box>

      {props.slots?.start ? (
        <Box sx={{ mb: 3 }}>{props.slots?.start}</Box>
      ) : null}

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          gap: 3,
        }}
      >
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 3 }}>
          {props.children}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            maxWidth: { lg: 294 },
          }}
        >
          {props.slots?.side || null}
          <AgentCard />
        </Box>
      </Box>

      {props.slots?.end ? <Box sx={{ mt: 3 }}>{props.slots?.end}</Box> : null}
    </div>
  );
}
