import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ArrowsInLineHorizontal, XCircle } from '@phosphor-icons/react';
import React from 'react';
import { ComparisonPlan } from '../model/slice';

interface Props {
  plans: ComparisonPlan[];
  onRemoveClick: (plan: ComparisonPlan) => void;
  onCompareClick: () => void;
}

export default function ComparisonPlanList({
  plans,
  onRemoveClick,
  onCompareClick,
}: Props) {
  if (!plans.length) {
    return null;
  }

  return (
    <Card sx={{ p: 4, maxWidth: { lg: 294 }, minWidth: 294 }}>
      <Typography variant={'h4'} className={'fs-24 lh-24'}>
        Compare plans
      </Typography>
      <Typography sx={{ mt: 1, mb: 3, color: '#666' }}>
        Add plans to compare and find out the best plan for you.
      </Typography>

      <Box sx={{ position: 'relative' }}>
        <Stack spacing={1}>
          {!!plans.length &&
            plans.map((plan, i) => (
              <React.Fragment key={plan.id}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography className={'fs-14 epilogue'}>
                    {plan.name}
                  </Typography>
                  <IconButton
                    size={'small'}
                    sx={{ cursor: 'pointer' }}
                    id={'remove-from-compare-button'}
                    onClick={() => onRemoveClick(plan)}
                  >
                    <XCircle size={24} color="#1C434F" weight="fill" />
                  </IconButton>
                </Box>
                {i < plans.length - 1 && (
                  <Divider sx={{ borderBottom: '1px dashed #B3B3B3' }} />
                )}
              </React.Fragment>
            ))}
        </Stack>
      </Box>

      <Button
        fullWidth
        variant={'contained'}
        onClick={onCompareClick}
        id={'ma-see-compare-plans-page-button'}
        sx={{ justifyContent: 'flex-start', px: 2, mt: 3, fontWeight: 500 }}
        disabled={plans.length < 2}
        startIcon={
          <ArrowsInLineHorizontal size={24} color="#FFF" weight="regular" />
        }
      >
        Compare Plans
      </Button>
    </Card>
  );
}
