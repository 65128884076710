import React from 'react';
import { CRModal } from '@coverright/ui/shared';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { X } from '@phosphor-icons/react';
import CelebrationAnimation from '../../../components/animations/CelebrationAnimation';

interface Props {
  open: boolean;
  onClose: (goToNext?: boolean) => void;
  moduleName?: string;
  nextModuleName?: string;
  videosLeft?: number;
  isLastInModule?: boolean;
}

export function WellDoneModal(props: Props) {
  const title = React.useMemo(() => {
    if (!props.isLastInModule) {
      return `Well done! Watch ${props.videosLeft} more videos to complete the ${props.moduleName} module.`;
    } else {
      return `You've completed the ${props.moduleName} Module!`;
    }
  }, [props.moduleName, props.videosLeft, props.isLastInModule]);

  const subTitle = React.useMemo(() => {
    return props.isLastInModule && props.nextModuleName
      ? `Next Module: ${props.nextModuleName}`
      : '';
  }, [props.nextModuleName, props.isLastInModule]);

  return (
    <CRModal
      BackdropProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.25)',
        },
      }}
      width={480}
      height={600}
      paperPadding={0}
      open={props.open}
      onClose={() => props.onClose()}
    >
      <>
        <Box
          sx={{
            display: 'flex',
            width: 1,
            p: 3,
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            flex: 1,
            zIndex: 1,
          }}
        >
          <IconButton size="small" edge="start" onClick={() => props.onClose()}>
            <X size={32} color="#000" />
          </IconButton>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            width: 1,
            justifyContent: 'center',
          }}
        >
          <CelebrationAnimation height={400} width={350} />
        </Box>
        <Typography
          sx={{ px: 3 }}
          className={'epilogue fs-32 lh-48 semi-bold'}
          align={'center'}
        >
          {title}
        </Typography>
        {props.isLastInModule && (
          <Typography
            sx={{ mt: 1, px: 3 }}
            className={'fs-18 lh-32'}
            align={'center'}
          >
            {subTitle}
          </Typography>
        )}
        <Box sx={{ display: 'flex', p: 3, width: 1 }}>
          <Button
            fullWidth
            variant={'contained'}
            color={'primary'}
            onClick={() => props.onClose(true)}
          >
            Watch next {props.isLastInModule ? 'module' : 'video'}
          </Button>
        </Box>
      </>
    </CRModal>
  );
}
