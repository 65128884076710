import { Box, Button, Card, Collapse, Typography } from '@mui/material';
import { MagnifyingGlass, Timer } from '@phosphor-icons/react';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { routes } from '../../../../app/router-new';
import { getAEPStartDate } from '../../../../../utils';

export default function CountdownCard({
  daysBeforeAEP,
  renewalDate,
}: {
  daysBeforeAEP?: number;
  renewalDate?: string;
}) {
  const navigate = useNavigate();

  const planDaysLeft = useMemo(() => {
    return moment(renewalDate).diff(moment(), 'days');
  }, [renewalDate]);

  if (!renewalDate || !daysBeforeAEP) {
    return null;
  }

  return (
    <Card sx={{ p: 4, maxWidth: { lg: 294 }, minWidth: 294 }}>
      {moment().isBefore(getAEPStartDate()) && (
        <Typography variant={'h4'} className={'fs-24 lh-30 mb-24'}>
          {daysBeforeAEP} days remaining before AEP
        </Typography>
      )}

      <Box sx={{ p: 3, borderRadius: '8px', background: '#F9E69A' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
          <Timer size={24} color="#000" weight="fill" />
          <Typography className={'semi-bold'}>Countdown</Typography>
        </Box>
        <Typography>
          Your Medicare Plan has {planDaysLeft} days left. On{' '}
          {moment(renewalDate).format('MMM D, YYYY')}, your insurance provider
          will renew your plan.
        </Typography>
      </Box>

      <Button
        fullWidth
        variant={'contained'}
        onClick={() => navigate(routes.plans.value)}
        id={'countdown-card-button'}
        sx={{ justifyContent: 'flex-start', mt: 3, fontWeight: 500 }}
        startIcon={<MagnifyingGlass size={24} color="#FFF" weight="fill" />}
      >
        Find a New Plan
      </Button>
    </Card>
  );
}
