import Message from '../ui/message';
import { Button, Collapse } from '@mui/material';
import * as React from 'react';
import { useDebouncedEffect } from '@coverright/utils';
import { useState } from 'react';

export default function ({
  preferredDoctorsLength,
  allDoctorsStatus,
}: {
  preferredDoctorsLength: number;
  allDoctorsStatus: boolean;
}) {
  const [open, setOpen] = useState(false);

  useDebouncedEffect(
    () => {
      setOpen(!!preferredDoctorsLength && !allDoctorsStatus);
    },
    500,
    [preferredDoctorsLength, allDoctorsStatus]
  );

  return (
    <Collapse in={open}>
      <Message
        title={
          'We are unable to search the network status for some of your doctors'
        }
        description={
          'It looks like one or more of your doctors is not currently in our network database (this is denoted by a the grey “X” icon next to the doctor’s name). If this doctor is important to you please connect with one of our licensed advisors and they can conduct a more detailed search prior to selecting a plan.'
        }
        onClose={() => setOpen(false)}
        data-test={'noDoctorsStatusOpen'}
      />
    </Collapse>
  );
}
