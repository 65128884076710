import { Box, Button, Typography } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@phosphor-icons/react';
import React from 'react';

interface Props {
  onChange: (newPage: number) => void;
  page: number;
  pageSize: number;
  loading?: boolean;
  total?: number;
  scrollTo?: HTMLElement | null;
}
export function Paginator(props: Props) {
  const { page, pageSize, total = 0, onChange, loading, scrollTo } = props;

  const totalPages = React.useMemo(() => {
    return Math.ceil(total / pageSize);
  }, [total, pageSize]);

  const hasNext = React.useMemo(() => {
    return !loading && page < totalPages - 1;
  }, [page, totalPages, loading]);

  const hasPrev = React.useMemo(() => {
    return !loading && page > 0;
  }, [page, loading]);

  const changePage = (newPage: number) => {
    if (newPage >= 0) {
      if (scrollTo) {
        scrollTo.scrollIntoView({ behavior: 'smooth' });
      }
      onChange(newPage);
    }
  };

  React.useEffect(() => {
    if (page > 0 && page > totalPages - 1) {
      onChange(totalPages - 1);
    }
  }, [page, totalPages]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Button
        variant={'text'}
        size={'small'}
        sx={{ ml: 2 }}
        startIcon={
          <ArrowLeft
            size={16}
            color={hasPrev ? '#1C434F' : '#B3B3B3'}
            weight="regular"
          />
        }
        onClick={() => hasPrev && changePage(page - 1)}
        disabled={!hasPrev}
      >
        <Typography sx={{ color: hasPrev ? '#1C434F' : '#B3B3B3' }}>
          Previous
        </Typography>
      </Button>
      {!loading && (
        <Typography sx={{ color: '#666' }}>
          {page + 1}/{totalPages || 0}
        </Typography>
      )}
      {loading && <Typography sx={{ color: '#666' }}>Loading...</Typography>}
      <Button
        variant={'text'}
        size={'small'}
        sx={{ mr: 2 }}
        endIcon={
          <ArrowRight
            size={16}
            color={hasNext ? '#1C434F' : '#B3B3B3'}
            weight="regular"
          />
        }
        onClick={() => hasNext && changePage(page + 1)}
        disabled={!hasNext}
      >
        <Typography sx={{ color: hasNext ? '#1C434F' : '#B3B3B3' }}>
          Next
        </Typography>
      </Button>
    </Box>
  );
}
