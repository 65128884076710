import { Box, styled, Typography } from '@mui/material';
import { CheckCircle, PlayCircle } from '@phosphor-icons/react';
import React from 'react';
import { VideoThumbnail, VideoThumbnailProps } from './video-thumbnail';

interface Props extends VideoThumbnailProps {
  completed?: boolean;
}

export const VideoCard = (props: Props) => {
  const { video, completed } = props;

  return (
    <Box>
      <VideoThumbnail {...props} />
      <Box sx={{ display: 'flex', my: 1, gap: 0.5 }}>
        {completed && (
          <CheckCircle
            className={'w-24 h-24'}
            size={24}
            color="#0B741C"
            weight="fill"
          />
        )}
        <Typography sx={{ fontSize: { xs: 16 }, mt: '3px' }} variant={'h4'}>
          <b>{video.title}</b>
        </Typography>
      </Box>
      <Typography sx={{ color: '#666666' }}>{video.duration}</Typography>
    </Box>
  );
};
