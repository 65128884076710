import {
  useLazyMaPlanDiscountsQuery,
  useLazyPdpPlanDiscountsQuery,
} from '../../../shared/api/medicare';
import { useEffect, useMemo } from 'react';

export default function useHasDiscounts(
  plan: any,
  quoteId?: string,
  clientId?: string
) {
  const [
    maRequest,
    { data: maData, isLoading: maLoading, startedTimeStamp: maStarted },
  ] = useLazyMaPlanDiscountsQuery();
  const [
    pdpRequest,
    { data: pdpData, isLoading: pdpLoading, startedTimeStamp: pdpStarted },
  ] = useLazyPdpPlanDiscountsQuery();

  useEffect(() => {
    if (plan.zip && plan.county) {
      if (plan.type === 'MA') {
        maRequest({
          countyName: plan.county,
          planYear: plan.year,
          zip: plan.zip,
          bidId: plan.bidId,
          quoteId,
        });
      } else if (plan.type === 'PDP') {
        pdpRequest({
          bidId: plan.bidId,
          clientId,
          countyName: plan.county,
          planYear: plan.year,
          zip: plan.zip,
        });
      }
    }
  }, [plan, quoteId, clientId]);

  const discounts = useMemo(() => {
    if (maData?.maPlanDiscounts?.discountedDrugs?.length) {
      return maData?.maPlanDiscounts?.discountedDrugs?.map((d) => +d.discount);
    }
    if (pdpData?.pdpPlanDiscounts?.discountedDrugs?.length) {
      return pdpData?.pdpPlanDiscounts?.discountedDrugs?.map(
        (d) => +d.discount
      );
    }
    return [];
  }, [maData?.maPlanDiscounts, pdpData?.pdpPlanDiscounts]);

  return {
    hasDiscounts: discounts.some((v) => v > 0),
    loading: (!maStarted && !pdpStarted) || maLoading || pdpLoading,
  };
}
