import { Box, Button, Card, Typography } from '@mui/material';
import { WarningOctagon } from '@phosphor-icons/react';
import * as React from 'react';

interface MessageProps {
  title: string;
  description: string | JSX.Element;
  extra?: JSX.Element;
  onClose?: () => void;
  'data-test'?: string;
}

const Message = (props: MessageProps) => {
  return (
    <Card
      sx={{ mb: 2, borderRadius: '16px', background: '#F9E69A', p: 3 }}
      data-test={props['data-test']}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { md: 'center' },
          mb: 2,
        }}
      >
        <WarningOctagon
          className={'w-32'}
          color={'#000'}
          size={32}
          weight={'fill'}
        />
        <Typography className={'semi-bold fs-24 lh-24'}>
          {props.title}
        </Typography>
      </Box>
      <div>
        {typeof props.description === 'string' && (
          <Typography>{props.description}</Typography>
        )}
        {typeof props.description !== 'string' && <>{props.description}</>}
        <Typography>{props.extra}</Typography>
      </div>

      {props.onClose && (
        <Box
          sx={{
            display: 'flex',
            width: 1,
            mt: 3,
            flexDirection: 'row',
            gap: 1,
            justifyContent: 'flex-end',
          }}
        >
          <Button
            id={'ma-messages-dismiss-button'}
            variant={'outlined'}
            onClick={props.onClose}
          >
            Dismiss
          </Button>
        </Box>
      )}
    </Card>
  );
};
export default Message;
