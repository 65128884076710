import { ListItem } from '../../../shared';
import * as _ from 'lodash';
import {
  planCategories,
  PlanCategory,
  PlanCategoryDescription,
  Value,
} from '../../../features';

export const toListItem = (cat: PlanCategoryDescription): ListItem => ({
  label: `${cat.name} (${cat.plans.join(', ')})`,
  value: cat.name,
});

export const getDisplayValue = (value?: Value) => {
  if (Array.isArray(value) && value.length) {
    const categories = planCategories.filter((c) => value.includes(c.name));

    if (categories.length === 1) {
      return `Plan Type: ${categories[0].name} (${categories[0].plans.join(
        ', '
      )})`;
    }
    return (
      'Plan Type: ' + _.flatMap(categories, (c) => c.plans).join(', ') || ''
    );
  }
  return 'Plan Type: All';
};

export const isCategorySelected =
  (value: string[]) => (cat: PlanCategoryDescription | PlanCategory) =>
    typeof cat === 'string'
      ? planCategories
          .find((c) => c.name === cat)!
          .plans.every((plan) => value.includes(plan))
      : cat.plans.every((plan) => value.includes(plan));

export const handleCategoryChange = (
  chosenCategories: Array<PlanCategory | 'all'>
) => {
  if (!chosenCategories.includes('all')) {
    const categoryDescriptions = planCategories.filter((c) =>
      chosenCategories.includes(c.name)
    );

    if (categoryDescriptions.length !== planCategories.length) {
      return _.flatMap(categoryDescriptions, (c) => c.plans);
    }
  }
  return [];
};
