import React from 'react';
import Lottie from 'react-lottie';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import animationData from './badge-animation.json';

const BadgeAnimation = (props: {
  height?: number;
  width?: number;
  style?: React.CSSProperties;
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    renderer: 'svg',
  };
  return (
    <Lottie
      options={defaultOptions}
      height={props.height || 1334}
      width={props.width || 750}
      isClickToPauseDisabled
      style={props.style}
    />
  );
};
export default BadgeAnimation;
