import { useNavigate, useLocation } from 'react-router';
import React, { useMemo, useState } from 'react';
import { Box, Button, Card, RadioGroup, Typography } from '@mui/material';
import { Radio } from '@coverright/ui/inputs';
import { V3InfoPanel } from '@coverright/ui/shared';
import QuizProgress from '../../components/quiz-progress';
import { getNumbersFromString } from '@coverright/utils';
import { useLogEvent } from '@coverright/shared/analytics';
import { SideLayout, useAppDispatch, useAppSelector } from '../../shared';
import {
  customAnswers as customAnswersSelector,
  CustomAnswerType,
  setCustomAnswer,
} from '../../entities';
import { TakeQuizButton } from '../../features';
import { options, Option } from './lib';
import MedigapPremiumRangesDisclamer from './ui/medigap-premium-ranges-disclamer';
import useSaveCustomAnswers from './api/use-save-custom-answers';

const Q1 = () => {
  const location = useLocation();
  const customAnswers = useAppSelector(customAnswersSelector);
  const logEvent = useLogEvent();
  const dispatch = useAppDispatch();
  const save = useSaveCustomAnswers();

  const questionNum = getNumbersFromString(
    location.pathname.split('/').pop() || ''
  )[0];
  const navigate = useNavigate();

  const value = Object.values(customAnswers)[questionNum - 1]?.value;

  const onNextClick = () => {
    save();
    switch (questionNum) {
      case Object.keys(options).length:
        navigate('/quiz/quiz-results');
        break;
      default:
        navigate('/quiz/q' + (questionNum + 1));
    }
  };

  const onBackClick = () => {
    switch (questionNum) {
      case 1:
        navigate('/quiz');
        break;
      default:
        navigate('/quiz/q' + (questionNum - 1));
    }
  };

  const onRadioClick = (answer: Option) => {
    logEvent('click', {
      element_title: answer.label,
      element_id: 'quiz-answer-' + answer.value,
    });
    dispatch(
      setCustomAnswer({
        type: Object.keys(CustomAnswerType)[
          questionNum - 1
        ] as CustomAnswerType,
        answer: {
          value: answer.value,
          answerText: answer.label,
          questionText: Object.keys(options)[questionNum - 1],
        },
      })
    );
  };

  if (!questionNum) {
    return null;
  }

  return (
    <SideLayout
      title={'Find out what plan type suits you best'}
      subtitle={questionNum === 1 ? undefined : <TakeQuizButton />}
    >
      <Card>
        <QuizProgress total={4} filled={questionNum} />
        <Typography variant={'h4'} className={'fs-24 lh-40 mb-32'}>
          {Object.keys(options)[questionNum - 1]}
        </Typography>

        <RadioGroup sx={{ width: 1, maxWidth: 880 }}>
          {Object.values(options)[questionNum - 1].map((answer, i) => (
            <React.Fragment>
              <Radio
                checked={value === answer.value}
                onChange={() => onRadioClick(answer)}
                label={answer.label}
              />
              {i < Object.values(options)[questionNum - 1].length - 1 && (
                <div className={'h-16'} />
              )}
            </React.Fragment>
          ))}
        </RadioGroup>

        <V3InfoPanel
          sx={{ width: 1, my: 5 }}
          title={'Helpful tip'}
          text={disclamers[questionNum - 1]}
        />

        <Box
          sx={{
            display: 'flex',
            width: 1,
            mt: 4,
            justifyContent: 'space-between',
          }}
        >
          <Button
            id={'back-button'}
            variant={'outlined'}
            color={'primary'}
            onClick={onBackClick}
          >
            Back
          </Button>
          <Button
            id={'continue-button'}
            disabled={value === undefined}
            variant={'contained'}
            color={'primary'}
            onClick={onNextClick}
          >
            Continue
          </Button>
        </Box>
      </Card>
    </SideLayout>
  );
};

export default Q1;

const disclamers = [
  <MedigapPremiumRangesDisclamer />,
  <>
    Staying within a network will minimize your costs.{' '}
    <b>
      Having a network does not necessarily mean you will lose your current
      doctor
    </b>{' '}
    - many doctors will be in-network for one or more private Medicare plans. We
    will help check if your doctors are in-network before enrolling you. If you
    must have flexibility to see doctors outside a network this will cost more
    overall.
  </>,
  'Some Medicare plans include extra benefits such as dental, vision, hearing, fitness, transporation and over-the-counter benefits. The level of coverage depends on the plan and benefit limits and network restrictions may apply. You may be able to find more comprehensive cover by purchasing these separately.',
  <>
    Medicaid is a program that helps with medical costs for people with limited
    income and resources. If you are eligible for Medicaid, there may be special
    plans in your area designed to combine your Medicaid-Medicare benefits into
    one integrated plan. To check if you might be eligible{' '}
    <span
      className={'underline pointer'}
      onClick={() =>
        window.open(
          'https://www.medicare.gov/your-medicare-costs/get-help-paying-costs/medicaid'
        )
      }
    >
      visit this link
    </span>
    .
  </>,
];
