import { VideoCta } from '../../../features';
import React from 'react';

export default function SideVideoCta() {
  return (
    <VideoCta
      video={'https://www.youtube.com/embed/L2_XzpP4sl8'}
      title={'Why Supplement?'}
      id={'cta-mg-watch-video-button'}
      modalTitle={'Why Supplement plans are right for you?'}
      thumb={'/plan-finder/assets/img/MG.png'}
    />
  );
}
