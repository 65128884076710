import React from 'react';
import { useParams } from 'react-router';
import { V3Preloader } from '@coverright/ui/shared';
import {
  PlanWithTiers,
  useMedicarePlan,
} from '@coverright/data-access/medicare';
import { GetNextPlansYear, GetPlansYear, getQuoteId } from '@coverright/utils';
import { PlanYear } from '@coverright/data-access/types/medicare';
import { useSnackbar } from 'notistack';
import PlanDetails from './ui/plan-details';
import { useAppSelector } from '../../shared';
import { medicareQuote } from '../../entities';

export default function () {
  const { id: planId } = useParams<{ id: string }>();
  const quote = useAppSelector(medicareQuote);
  const { planYear, id, zip, county } = quote || {};
  const [getPlan, { data, loading, called, error }] = useMedicarePlan();
  const { enqueueSnackbar } = useSnackbar();
  const [plan, setPlan] = React.useState<PlanWithTiers>();

  React.useEffect(() => {
    if (!getQuoteId() && zip && county) {
      const urlParams = new URLSearchParams(window.location.search);
      let year = GetPlansYear();
      if (urlParams.has('planYear')) {
        year = urlParams.get('planYear') as PlanYear;
      }
      getPlan({
        variables: {
          zip,
          bidId: planId!,
          quoteId: getQuoteId(),
          countyName: county,
          planYear: year,
          fullYear: year === GetNextPlansYear(),
        },
      });
    }
  }, []);

  React.useEffect(() => {
    if (id && zip && county) {
      let year = GetPlansYear();
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('planYear')) {
        year = urlParams.get('planYear') as PlanYear;
      }
      getPlan({
        variables: {
          zip,
          bidId: planId!,
          quoteId: id,
          countyName: county,
          planYear: year,
          fullYear: year === GetNextPlansYear(),
        },
      });
    }
  }, [planYear, id]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setPlan(data?.plan);
  }, [data]);

  React.useEffect(() => {
    if (error) {
      enqueueSnackbar('Something wrong. Please try again later.', {
        variant: 'error',
      });
    }
  }, [error]);

  return (
    <div>
      {(loading || !called) && <V3Preloader sx={{ mt: 4 }} />}
      {!!plan && <PlanDetails plan={plan} />}
    </div>
  );
}
