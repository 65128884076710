import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  Field,
  PdpField,
  PdpPlan,
  PdpSort,
  Plan,
  PlanYear,
  Sort,
} from '@coverright/data-access/types/medicare';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  AppActionTypes,
  AppContext,
  PdpQuoteContext,
  QuoteContext,
} from '@coverright/shared/contexts';
import { GetPlansYear, getStoredUser } from '@coverright/utils';
import { SelectInput } from '@coverright/ui/inputs';
import {
  useMedicarePlans,
  usePDPPlans,
} from '@coverright/data-access/medicare';
import { CRModal } from '@coverright/ui/shared';
import { LoadingButton } from '@mui/lab';

function PlanSelector<T>({
  onSelect,
  type,
  zip,
  countyName,
}: {
  onSelect: (value: T) => void;
  type: 'MA' | 'PDP';
  zip?: string | null;
  countyName?: string | null;
}) {
  const [loading, setLoading] = useState(false);

  const [getMaPlans, maPlansData] = useMedicarePlans();
  const [getPdpPlans, pdpPlansData] = usePDPPlans();

  const [carrier, setCarrier] = React.useState('');
  const [plan, setPlan] = React.useState('');
  const [plans, setPlans] = React.useState<any[]>([]);

  const [getCompanies, companiesData] = useLazyQuery(
    gql(type === 'MA' ? companiesQuery : pdpCompaniesQuery)
  );

  useEffect(() => {
    if (zip && countyName && type) {
      if (type === 'MA') {
        getCompanies({
          variables: {
            filter: {
              zip,
              countyName,
              planYear: GetPlansYear(),
            },
          },
        });
      } else if (type === 'PDP') {
        getCompanies({
          variables: {
            filter: {
              zip,
              countyName,
              planYear: GetPlansYear(),
            },
          },
        });
      }
    }
  }, [getCompanies, type, zip, countyName]);

  React.useEffect(() => {
    if (zip && countyName && carrier) {
      if (type === 'MA') {
        getMaPlans({
          variables: {
            page: {
              size: 1000,
              page: 0,
            },
            sort: [{ direction: Sort.Asc, field: Field.Name }],
            filter: {
              zip,
              countyName,
              planYear: GetPlansYear(),
              companies: [carrier],
            },
          },
        }).then((data) => setPlans(data.data?.plans?.data || []));
      } else {
        getPdpPlans({
          variables: {
            page: {
              size: 1000,
              page: 0,
            },
            sort: [{ direction: PdpSort.Asc, pdpField: PdpField.Name }],
            filter: {
              zip,
              countyName,
              planYear: GetPlansYear(),
              companies: [carrier],
            },
          },
        }).then((data) => setPlans(data.data?.PdpPlans?.data || []));
      }
    }
  }, [carrier, type, zip, countyName]);

  const companies: string[] = useMemo(() => {
    const array =
      companiesData.data?.companies || companiesData.data?.pdpCompanies || [];
    return array.filter((c: any) => c.count).map((c: any) => c.name);
  }, [companiesData.data]);

  const onSelectClick = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 3000);
    onSelect(plans.find((p) => p.bidId === plan)!);
  };

  return (
    <Box>
      <SelectInput
        fullWidth
        hideTick
        loading={!!companiesData.loading}
        data-test={'carrier'}
        label={'Insurance Carrier'}
        value={carrier}
        placeholder={'Select carrier'}
        onChange={(event) => {
          setPlan('');
          setCarrier(event.target.value as string);
        }}
      >
        {companies.map((c) => (
          <MenuItem key={c} value={c}>
            {c}
          </MenuItem>
        ))}
      </SelectInput>
      <Collapse in={!!carrier}>
        <SelectInput
          label={'Plan name'}
          hideTick
          loading={!!maPlansData.loading || !!pdpPlansData.loading}
          placeholder={'Select plan'}
          value={plan}
          disabled={
            !plans.length || maPlansData.loading || pdpPlansData.loading
          }
          onChange={(event) => {
            setPlan(event.target.value as string);
          }}
        >
          {plans.map((p) => (
            <MenuItem key={p.bidId} value={p.bidId}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: 1,
                  pr: 5,
                }}
              >
                <Box
                  component={'span'}
                  sx={{
                    textOverflow: 'ellipsis',
                    maxWidth: 382,
                    overflow: 'hidden',
                  }}
                >
                  {p.planName}
                </Box>
                <span>{p.monthlyPremium}</span>
              </Box>
            </MenuItem>
          ))}
        </SelectInput>
      </Collapse>
      <Collapse in={!!plan}>
        <LoadingButton
          variant={'contained'}
          loading={loading}
          onClick={onSelectClick}
        >
          Select plan
        </LoadingButton>
      </Collapse>
    </Box>
  );
}

export function MAPlanSelector(props: {
  onClose: () => void;
  open: boolean;
  zip?: string | null;
  county?: string | null;
}) {
  const [{}, dispatch] = React.useContext(AppContext);

  const onSelect = (value: Plan) => {
    dispatch({
      type: AppActionTypes.SAVE_STATE,
      payload: {
        stateKey: 'overrideMAEnrollment',
        value,
      },
    });
    setTimeout(props.onClose, 1000);
  };
  return (
    <CRModal
      BackdropProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.25)',
        },
      }}
      width={650}
      paperPadding={'32px'}
      open={props.open}
    >
      <>
        <Typography
          className={'epilogue fs-32 lh-48 mt-16 mb-24 semi-bold'}
          align={'center'}
        >
          Select your Medicare Plan
        </Typography>
        <PlanSelector
          onSelect={onSelect}
          type={'MA'}
          zip={props.zip}
          countyName={props.county}
        />
      </>
    </CRModal>
  );
}

export function PDPPlanSelector(props: {
  onClose: () => void;
  open: boolean;
  zip?: string | null;
  county?: string | null;
}) {
  const [{}, dispatch] = React.useContext(AppContext);

  const onSelect = (value: PdpPlan) => {
    dispatch({
      type: AppActionTypes.SAVE_STATE,
      payload: {
        stateKey: 'overridePDPEnrollment',
        value,
      },
    });
    setTimeout(props.onClose, 1000);
  };

  return (
    <CRModal
      BackdropProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.25)',
        },
      }}
      width={650}
      paperPadding={'32px'}
      open={props.open}
    >
      <>
        <Typography
          className={'epilogue fs-32 lh-48 mt-16 mb-24 semi-bold'}
          align={'center'}
        >
          Select your Drug Plan
        </Typography>
        <PlanSelector
          onSelect={onSelect}
          type={'PDP'}
          zip={props.zip}
          countyName={props.county}
        />
      </>
    </CRModal>
  );
}

const companiesQuery = `
query($filter: PlansFilterInput!) {
    companies(filter: $filter) {
      name
      count
    }
}
`;

const pdpCompaniesQuery = `
query($filter: PdpPlansFilterInput!) {
    pdpCompanies(filter: $filter) {
      name
      count
    }
}
`;
