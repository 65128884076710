import React, { useEffect, useState } from 'react';
import {
  PlanYear,
  useMedicarePlan,
  usePdpPlan,
} from '@coverright/data-access/medicare';
import {
  EnrollmentListItemOutput,
  EnrollmentType,
  useMyEnrollmentsLazyQuery,
} from '@coverright/data-access/types/enrollment';
import { enrollmentClient } from '@coverright/data-access/apollo-clients';
import { useMedigapPlan } from '@coverright/data-access/medigap';
import { GetPlansYear, getQuoteId } from '@coverright/utils';
import moment from 'moment';
import * as _ from 'lodash';
import { AppActionTypes, AppContext } from '@coverright/shared/contexts';

export default function useGetMyEnrollments(hasEnrollments: boolean) {
  const [enrollments, setEnrollments] = useState<
    Partial<EnrollmentListItemOutput>[]
  >([]);
  const [enrolledPlans, setEnrolledPlans] = useState<any[]>([]);
  const [plansLoaded, setPlansLoaded] = useState(false);
  const [state, dispatch, loaded] = React.useContext(AppContext);
  const [getMyEnrollments] = useMyEnrollmentsLazyQuery({
    client: enrollmentClient,
  });
  const [getMAPlan] = useMedicarePlan();
  const [getPdpPlan] = usePdpPlan();
  const [getMgPlan] = useMedigapPlan();

  const getPlan = React.useCallback(
    (
      enrollment: Partial<EnrollmentListItemOutput>
    ): Promise<any | void> => {
      const { overrideMAEnrollment, overridePDPEnrollment } = state.state;
      const common = {
        county: enrollment.county!,
        effectiveDate: moment(enrollment.effectiveDate).format('MM/DD/YY'),
        enrollmentId: enrollment.id,
        flexpaIntegrationError: '',
        isFlexpaIntegrated: false,
        year: enrollment.planYear as PlanYear,
        zip: enrollment.zip!,
      };
      switch (enrollment.type) {
        case EnrollmentType.Ma: {
          return getMAPlan({
            variables: {
              planYear: GetPlansYear(),
              bidId: enrollment.bidId!,
              zip: enrollment.zip!,
              countyName: enrollment.county!,
              quoteId: getQuoteId(),
            },
          })
            .then((res) => res.data!.plan)
            .then((plan) => {
              if (plan && overrideMAEnrollment) {
                dispatch({
                  type: AppActionTypes.SAVE_STATE,
                  payload: {
                    stateKey: 'overrideMAEnrollment',
                    value: undefined,
                  },
                });
              }
              return {
                ...common,
                bidId: plan.bidId,
                orgName: plan.orgName!,
                parentOrgName: plan.parentOrgName!,
                planName: plan.planName!,
                premium: plan.monthlyPremium!,
                renewalDate: moment()
                  .add({ year: 1 })
                  .startOf('year')
                  .format('MM/DD/YY'),
                type: 'MA',
              };
            })
            .catch((e) => {
              if (overrideMAEnrollment) {
                return {
                  ...common,
                  bidId: overrideMAEnrollment.bidId!,
                  orgName: overrideMAEnrollment.orgName!,
                  parentOrgName: overrideMAEnrollment.parentOrgName!,
                  planName: overrideMAEnrollment.planName!,
                  premium: overrideMAEnrollment.monthlyPremium!,
                  renewalDate: '-',
                  effectiveDate: '-',
                  type: 'MA',
                };
              }
              return;
            });
        }

        case EnrollmentType.Pdp: {
          return getPdpPlan({
            variables: {
              planYear: GetPlansYear(),
              bidId: enrollment.bidId!,
              zip: enrollment.zip!,
              countyName: enrollment.county!,
            },
          })
            .then((res) => res.data!.PdpPlan)
            .then((plan) => {
              if (plan && overridePDPEnrollment) {
                dispatch({
                  type: AppActionTypes.SAVE_STATE,
                  payload: {
                    stateKey: 'overridePDPEnrollment',
                    value: undefined,
                  },
                });
              }
              return {
                ...common,
                bidId: plan.bidId,
                orgName: plan.orgName!,
                parentOrgName: plan.parentOrgName!,
                planName: plan.planName!,
                premium: plan.monthlyPremium!,
                renewalDate: moment()
                  .add({ year: 1 })
                  .startOf('year')
                  .format('MM/DD/YY'),
                type: 'PDP',
              };
            })
            .catch((e) => {
              if (overridePDPEnrollment) {
                return {
                  ...common,
                  bidId: overridePDPEnrollment.bidId!,
                  orgName: overridePDPEnrollment.orgName!,
                  parentOrgName: overridePDPEnrollment.parentOrgName!,
                  planName: overridePDPEnrollment.planName!,
                  premium: overridePDPEnrollment.monthlyPremium!,
                  renewalDate: '-',
                  effectiveDate: '-',
                  type: 'PDP',
                };
              }
              return;
            });
        }

        default: {
          return getMgPlan({
            variables: {
              key: enrollment.mgPlanKey!,
            },
          })
            .then((res) => res.data!.medigapPlan)
            .then((plan) => ({
              ...common,
              bidId: plan.key,
              orgName: plan.orgName!,
              parentOrgName: plan.parentOrgName!,
              planName: `${plan.title} ${plan.planName!} Plan`,
              premium: plan.monthlyPremium!,
              renewalDate: moment(enrollment.effectiveDate)
                .add({ year: 1 })
                .format('MM/DD/YY'),
              type: 'MG',
            }));
        }
      }
    },
    [state]
  );

  const process = async () => {
    setPlansLoaded(false);
    const data = await getMyEnrollments();
    let assignedEnrollments = data.data?.myEnrollments;

    if (assignedEnrollments) {
      // get only latest plans MA or MG + PDP
      const valueEnrollments = assignedEnrollments.filter(
        (p) => p.type !== EnrollmentType.Mg
      );
      if (valueEnrollments.length) {
        let enrollment = valueEnrollments[0];
        if (valueEnrollments.length > 1) {
          enrollment = _.orderBy(valueEnrollments, 'effectiveDate', 'desc')[0];
        }

        if (enrollment.type === EnrollmentType.Ma) {
          assignedEnrollments = [enrollment];
        } else {
          assignedEnrollments = assignedEnrollments.filter(
            (p) => p.type !== EnrollmentType.Ma
          );
        }
      }

      setEnrollments(assignedEnrollments);
    }

    try {
      if (assignedEnrollments) {
        const result = [];
        for (const enrollment of assignedEnrollments) {
          const plan = await getPlan(enrollment);
          if (plan) {
            result.push(plan);
          }
        }
        setEnrolledPlans(_.orderBy(result, 'type'));
        setPlansLoaded(true);
      }
    } catch (e) {
      setPlansLoaded(true);
    }
  };

  useEffect(() => {
    if (hasEnrollments && loaded) {
      process();
    }
  }, [hasEnrollments, loaded]);

  return { enrollments, enrolledPlans, plansLoaded, refresh: process };
}
