import { Collapse, Typography } from '@mui/material';
import React from 'react';
import usePlansCount from './api/use-plans-count';

interface Props {
  zip?: string;
  county?: string | null;
  state?: string | null;
  type: 'MA' | 'PDP';
}

export function PlansCountRepresentDisclamer({
  zip,
  county,
  state,
  type,
}: Props) {
  const { maPlansCount, maOrgCount, pdpPlansCount, pdpOrgCount, loading } =
    usePlansCount(zip);

  return (
    <Collapse in={!loading}>
      {type === 'MA' && (
        <Typography className={'mv-16'}>
          Currently we represent {maOrgCount} organizations which offer{' '}
          {maPlansCount} products in {zip}, {county} County, {state}.
        </Typography>
      )}
      {type === 'PDP' && (
        <Typography className={'mv-16'}>
          Currently we represent {pdpOrgCount} organizations which offer{' '}
          {pdpPlansCount} products in {zip}, {county} County, {state}.
        </Typography>
      )}
    </Collapse>
  );
}
