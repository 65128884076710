import { MedigapPlanName } from '@coverright/data-access/types/medigap';
import * as _ from 'lodash';

export enum PlanCategory {
  MostPopular = 'Most Popular',
  CoreBenefits = 'Core benefits',
  HighDeductible = 'High deductible',
}

export type PlanCategoryDescription = {
  name: PlanCategory;
  plans: MedigapPlanName[];
};

export const planCategories: PlanCategoryDescription[] = [
  {
    name: PlanCategory.MostPopular,
    plans: [MedigapPlanName.F, MedigapPlanName.G, MedigapPlanName.N],
  },
  //  {name: PlanCategory.Comprehensive, plans: [MedigapPlanName.C, MedigapPlanName.D, MedigapPlanName.F, MedigapPlanName.G]},
  {
    name: PlanCategory.HighDeductible,
    plans: [MedigapPlanName.Hdf, MedigapPlanName.Hdg],
  },
  //  {name: PlanCategory.CostShare, plans: [MedigapPlanName.K, MedigapPlanName.L, MedigapPlanName.M, MedigapPlanName.N]},
  {
    name: PlanCategory.CoreBenefits,
    plans: [MedigapPlanName.A, MedigapPlanName.B],
  },
];

export const isAllMgCategoriesSelected = (plans: string[] = []) =>
  !plans.length ||
  getSelectedMgCategoriesByPlans(plans).length === planCategories.length;

export const getSelectedMgCategoriesByPlans = (plans: string[] = []) =>
  planCategories.filter(isCategorySelected(plans));

export const isCategorySelected =
  (value: string[]) => (cat: PlanCategoryDescription | PlanCategory) =>
    typeof cat === 'string'
      ? planCategories
          .find((c) => c.name === cat)!
          .plans.every((plan) => value.includes(plan))
      : cat.plans.every((plan) => value.includes(plan));
