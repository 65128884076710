import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { PlanYear } from '@coverright/data-access/types/medicare';
import React from 'react';
import { UserTestContext } from '@coverright/shared/contexts';
import { SxProps } from '@mui/material';
import { GetNextPlansYear, GetPlansYear } from '@coverright/utils';

interface Props {
  checked: boolean;
  onChange: (value: PlanYear) => void;
  sx?: SxProps;
}

export function YearToYearSwitch({ checked, onChange, sx }: Props) {
  const { config } = React.useContext(UserTestContext);

  if (!config.compare_year_to_year) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
      <Typography className={'mr-12'}>{new Date().getFullYear()}</Typography>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={(e) =>
              onChange(e.target.checked ? GetNextPlansYear() : GetPlansYear())
            }
          />
        }
        label={new Date().getFullYear() + 1}
      />
    </Box>
  );
}
