import React from 'react';
import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/material';
import { CircleWavyQuestion } from '@phosphor-icons/react';

export function V3InfoPanel({text, title, sx}: {title: string, text: string | JSX.Element, sx?: SxProps}) {
  return <Box sx={{p: 3, backgroundColor: '#F0F7FA', borderRadius: '16px',  ...sx}}>
    <Box sx={{display: 'flex', gap: 1, alignItems: 'center', mb: 1}}>
      <CircleWavyQuestion size={24} color="#19605D" weight="duotone" />
      <Typography className={'fs-16 lh-24 semi-bold'}>{title}</Typography>
    </Box>
    {typeof text === 'string' && <Typography className={'fs-16 lh-24'}>{text}</Typography>}
    {typeof text !== 'string' && <>{text}</>}
  </Box>
}
