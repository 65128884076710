import React, { useContext, useEffect, useState } from 'react';
import InputArea from './ui/input-area';
import MessageList from './ui/message-list';
import { Box, Card, Typography } from '@mui/material';
import { ChatContext } from './model/chat-context';
import { ChatMessage, mapMessage } from './lib';
import { JSONObject, Message } from '@twilio/conversations';
import { useAppSelector } from '../../shared';
import { profile as profileSelector } from '../../entities/profile';

interface Props {
  messages: ChatMessage[];
  onSubmit: (text: string) => void;
  directionDetect: (m: ChatMessage) => 'outgoing' | 'incoming';
  lastReadIndex?: number;
}

export function Chat({
  messages,
  onSubmit,
  directionDetect,
  lastReadIndex,
}: Props) {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        p: '24px 8px 8px 24px',
      }}
    >
      <MessageList
        messages={messages}
        directionDetect={directionDetect}
        lastReadIndex={lastReadIndex}
      />
      <InputArea onSubmit={onSubmit} />
    </Card>
  );
}

export default function () {
  const profile = useAppSelector(profileSelector);
  const { participantSid, conversation } = useContext(ChatContext);
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  const addMessageHandler = async (m: Message) => {
    await conversation?.setAllMessagesRead();
    setMessages((prev) => [
      ...prev.filter((i) => i.index !== m.index),
      mapMessage(m),
    ]);
  };

  useEffect(() => {
    if (conversation) {
      setTimeout(() => {
        conversation.setAllMessagesRead();
      }, 3000);
      conversation.on('messageAdded', addMessageHandler);
      conversation.getMessages().then((p) => {
        setMessages(p.items.map(mapMessage));
      });
    }
    return () => {
      conversation?.off('messageAdded', addMessageHandler);
    };
  }, [conversation]);

  if (!conversation) {
    return (
      <Typography sx={{ textAlign: 'center', mt: 3 }} variant={'h4'}>
        Loading...
      </Typography>
    );
  }

  const sendMessage = async (message: string) => {
    if (conversation) {
      const attributes: JSONObject = {
        firstName: profile?.firstName || '',
        lastName: profile?.lastName || '',
        email: profile?.email || '',
      };
      const index = (conversation?.lastMessage?.index ?? -1) + 1;
      await conversation.advanceLastReadMessageIndex(index);
      setMessages((prev) => [
        ...prev,
        {
          author: '',
          date: new Date(),
          text: message,
          participantSid: participantSid || null,
          index,
        },
      ]);
      await conversation.sendMessage(message, attributes);
    }
  };

  return (
    <Chat
      messages={messages}
      directionDetect={(m) =>
        m.participantSid === participantSid ? 'outgoing' : 'incoming'
      }
      lastReadIndex={conversation.lastReadMessageIndex ?? -1}
      onSubmit={sendMessage}
    />
  );
}

export * from './model/chat-context';
