import { Stack } from '@mui/material';
import * as React from 'react';
import { areComponentPropsEqual } from '@coverright/utils';
import {
  MedicareQuoteOutput,
  PlansFilterInput,
} from '@coverright/data-access/types/medicare';
import NoPlans from './messages/no-plans';
import NoDoctorsDrugs from './messages/no-doctors-drugs';
import NoDoctors from './messages/no-doctors';
import NoDoctorStatus from './messages/no-doctor-status';
import NoDrugs from './messages/no-drugs';
import ChangeBenefits from './messages/change-benefits';
import NotAllDoctorsWithPpo from './messages/not-all-doctors-with-ppo';
import NotAllDoctorsWithoutPpo from './messages/not-all-doctors-without-ppo';
import NotAllDoctorsCovered from './messages/not-all-doctors-covered';
import Expensive from './messages/expensive';
import StarRating from './messages/star-rating';
import { MaPlanListQuery } from '../../shared/api/medicare';
import useMessagesVars from './lib/use-messages-vars';
import NotAllDrugsCovered from './messages/not-all-drugs-covered';
import NoPpoDsnp from './messages/no-ppo-dsnp';
import NotSupportedDrugs from './messages/not-supported-drugs';

interface Props {
  isPpoAvailable: boolean;
  isTherePlanWithFullDrugCoverage: boolean;
  totalPlans: number;
  debounceTime?: number;
  planCoversAllDoctorsExists: boolean;
  isOnlyHMOSelected: boolean;
  isDSNPSelected: boolean;
  isTherePlansWithDoctorsCoverage: boolean;
  plansLength: number;
  preferredDoctorsLength: number;
  preferredDrugsLength: number;
  isPPOSelected: boolean;
  lowestPlanPrice: number;
  isFirstVisit: boolean;
  isExtraBenefitsSelected: boolean;
  allDoctorsStatus: boolean;
  loading: boolean;
  hasUnsupportedDrugs: boolean;
}

export function MaMessages(props: Props) {
  React.useEffect(() => {
    return () => {
      localStorage.setItem('visited', 'true');
    };
  }, []);

  if (props.loading) {
    return null;
  }

  return (
    <Stack data-test={'ma-messages'}>
      <NoPlans {...props} />
      <NoDoctorsDrugs {...props} />
      <NoDoctorStatus {...props} />
      <NoDoctors {...props} />
      <NoDrugs {...props} />
      <ChangeBenefits {...props} />
      <NotAllDoctorsWithPpo {...props} />
      <NotAllDoctorsWithoutPpo {...props} />
      <NotAllDrugsCovered {...props} />
      <Expensive {...props} />
      <StarRating {...props} />
      <NoPpoDsnp {...props} />
      <NotSupportedDrugs {...props} />
    </Stack>
  );
}

interface MessagesProps {
  quote: MedicareQuoteOutput;
  plans: MaPlanListQuery['plans']['data'];
  filters?: PlansFilterInput;
  loading?: boolean;
}

export default React.memo(function (props: MessagesProps) {
  const { quote, filters, plans, loading } = props;
  const vars = useMessagesVars(quote, plans, filters);

  return <MaMessages {...vars} loading={!!loading} />;
}, areComponentPropsEqual);
