import { useAppSelector } from '../../../shared';
import { profile as profileSelector } from '../../../entities/profile';
import {
  useLazyPdpQuoteQuery,
  useSavePdpQuoteMutation,
} from '../../../shared/api/medicare';
import { getCId } from '@coverright/utils';
import { pdpQuote } from '../../../entities';
import { useEffect } from 'react';

export default function useCreatePdpQuoteIfNotExists() {
  const profile = useAppSelector(profileSelector);
  const quote = useAppSelector(pdpQuote);
  const [saveQuote] = useSavePdpQuoteMutation();
  const [getQuote] = useLazyPdpQuoteQuery();

  const createQuote = () => {
    saveQuote({
      input: {
        id: profile?.pdpQuoteId || undefined,
        cId: getCId(),
        insulinSavings: false,
        countyName: quote?.countyName || profile?.countyName,
        zip: quote?.zip || profile?.zip,
      },
    })
      .unwrap()
      .then((res) => getQuote({ id: res.savePdpQuote.id }));
  };

  useEffect(() => {
    if (profile && profile?.quoteId && !profile?.pdpQuoteId) {
      createQuote();
    }
  }, [profile]);

  useEffect(() => {
    if (quote && (!quote?.zip || !quote?.countyName)) {
      createQuote();
    }
  }, [quote]);
}
