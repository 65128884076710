import { createAsyncThunk } from '@reduxjs/toolkit';
import { BenefitEntity, mapBenefit } from '../lib';
import getStrapiBenefits from './get-strapi-benefits';
import { api as medicareApi } from '../../../shared/api/medicare';
import { GetPlansYear } from '@coverright/utils';

export const fetchBenefits = createAsyncThunk(
  'benefit/fetchBenefits',
  async (
    { quoteId, enrolledPlan }: { enrolledPlan: any; quoteId?: string },
    { dispatch }
  ): Promise<{ [key: string]: BenefitEntity[] }> => {
    const strapiBenefits = await getStrapiBenefits(enrolledPlan);
    const { data } = await dispatch(
      medicareApi.endpoints.maPlan.initiate({
        bidId: enrolledPlan.bidId,
        planYear: GetPlansYear() as any,
        zip: enrolledPlan.zip!,
        countyName: enrolledPlan.county!,
        quoteId,
      })
    );

    const plan = data?.plan;

    if (plan) {
      const result: { [key: string]: BenefitEntity[] } = {};
      Object.keys(strapiBenefits).forEach((key) => {
        result[key] = strapiBenefits[key].map((item) => mapBenefit(item, plan));
      });
      return result;
    }

    return {};
  }
);
