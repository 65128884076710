import { Box } from '@mui/material';
import React from 'react';
import { MADrugCostBreakdown } from './ma-drug-cost-breakdown';
import { PDPDrugCostBreakdown } from './pdp-drug-cost-breakdown';
import { Props } from '../lib/types';
import useScrollToDrugCost from '../lib/use-scroll-to-drug-cost';

export enum PlanType {
  Ma = 'MA',
  Mg = 'MG',
  Pdp = 'PDP'
}

type DrugCostBreakdownProps = Props & {
  quoteId?: string;
  clientId?: string;
  type: PlanType;
};

export function DrugCostBreakdown(props: DrugCostBreakdownProps) {
  const { type, quoteId, clientId, ...params } = props;
  useScrollToDrugCost();

  return (
    <Box id={'drug-cost'} sx={{ flex: 1 }}>
      {type === PlanType.Ma && (
        <MADrugCostBreakdown {...params} quoteId={quoteId} />
      )}
      {type === PlanType.Pdp && (
        <PDPDrugCostBreakdown {...params} clientId={clientId} />
      )}
    </Box>
  );
}
