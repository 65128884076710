import { MutationHookOptions, useMutation } from '@apollo/client';
import {gql} from "@apollo/client";
import { enrollmentClient } from '@coverright/data-access/apollo-clients';
import { UrgentCallTypeDto } from '@coverright/data-access/types/enrollment';

export function useRequestUrgentCall(options?: MutationHookOptions<{requestUrgentCall: boolean}, {urgentCallType: UrgentCallTypeDto}>) {
  return useMutation<{requestUrgentCall: boolean}, {urgentCallType: UrgentCallTypeDto}>(
    gql(saveEnrollmentMutation),
    {
      client: enrollmentClient,
      ...options
    }
  );
}

const saveEnrollmentMutation = `
mutation($urgentCallType: UrgentCallTypeDto!) {
  requestUrgentCall(urgentCallType: $urgentCallType)
}
`;
