import { MedicareQuoteQuery } from '../../../shared/api/medicare';
import { Pill, Prescription } from '@phosphor-icons/react';
import { Typography } from '@mui/material';
import React from 'react';

export const getTitle = (hasEnrollment?: boolean) =>
  hasEnrollment === undefined
    ? 'Loading...'
    : hasEnrollment
    ? 'View Drug Pricing & Offers'
    : 'Drugs in-network';

export const getTabs = (quote: MedicareQuoteQuery['medicareQuote']) => [
  {
    icon: Pill,
    label: (
      <div>
        <Typography
          variant={'body2'}
          className={'medium epilogue'}
          align={'left'}
        >
          Search drug
        </Typography>
        <Typography
          sx={{ fontFamily: 'Epilogue', color: '#1C434F' }}
          align={'left'}
        >
          {quote?.preferredDrugs?.length || 0} added
        </Typography>
      </div>
    ),
  },
  {
    icon: Prescription,
    label: (
      <div>
        <Typography
          variant={'body2'}
          className={'medium epilogue'}
          align={'left'}
        >
          Your Pharmacy
        </Typography>
        <Typography
          sx={{ fontFamily: 'Epilogue', color: '#1C434F' }}
          align={'left'}
        >
          {quote?.preferredPharmacies?.length || 0} added
        </Typography>
      </div>
    ),
  },
];

export function getTabIndex() {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('tab')) {
    if (urlParams.get('tab') === 'drugs') {
      return 0;
    } else if (urlParams.get('tab') === 'pharmacy') {
      return 1;
    }
  }
  return 0;
}
