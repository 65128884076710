import { Box, IconButton, Typography } from '@mui/material';
import * as React from 'react';
import { Phone, X } from '@phosphor-icons/react';
import { useLogEvent } from '@coverright/shared/analytics';
import { CRModal } from '@coverright/ui/shared';
import { useShowCloseModal } from './hooks/use-show-close-modal';
import { ScheduleACallButton } from '../calendly';

export const CloseModal = () => {
  const open = useShowCloseModal();
  const [innerOpen, setOpen] = React.useState(false);
  const logEvent = useLogEvent();

  React.useEffect(() => {
    if (open) {
      logEvent('onclose_modal_opened', {
        element_id: 'close-modal',
        element_title: 'Close modal',
      });
    }
    setOpen(open);
  }, [open]);

  const onClose = () => {
    logEvent('onclose_modal_closed', {
      element_id: 'close-modal',
      element_title: 'Close modal',
    });
    setOpen(false);
  };

  return (
    <CRModal
      width={765}
      open={innerOpen}
      onClose={onClose}
      modalScroll
      paperPadding={0}
      BackdropProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      <>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mr: 1 }}>
          <IconButton size={'small'} color="inherit" onClick={onClose}>
            <X size={24} color="#000000" weight="regular" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 3,
            alignItems: 'center',
            p: 3,
            position: 'relative',
          }}
        >
          <img
            src={'assets/img/girl.jpg'}
            alt="logo"
            width={400}
            height={400}
          />
          <Box>
            <Typography sx={{ fontWeight: 700, fontSize: 20 }}>
              Wait! We have free Medicare agents ready to help you find the
              right plan!
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: 16, mt: 2, mb: 4 }}>
              Our free CoverRight service has dedicated agents that will meet
              your needs when it comes to Medicare.
            </Typography>
            <ScheduleACallButton onClose={onClose} />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 3 }}>
              <Phone size={24} color="1C434F" weight="fill" />
              <Box
                id={'header-phone-number'}
                component={'a'}
                onClick={() => {
                  logEvent('click', {
                    element_id: 'close-modal-phone-number',
                    element_title: '(888) 969-7667 | TTY: 711',
                  });
                }}
                href={'tel:+18882915580'}
                sx={{
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  textDecoration: 'none',
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                  (888) 969-7667 | TTY: 711
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    </CRModal>
  );
};
