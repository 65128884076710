import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import { MedigapPremiumRange, QueryMedigapPremiumRangesArgs } from '@coverright/data-access/types/medigap';

export function useMedigapPremiumRanges() {
  return useLazyQuery<IMedigapPremiumRangeResponse, QueryMedigapPremiumRangesArgs>(gql(premiumRangesQuery))
}

export interface IMedigapPremiumRangeResponse {
  medigapPremiumRanges: MedigapPremiumRange[],
}

const premiumRangesQuery = `
  query($zip: String!, $tobacco: Boolean!, $age: Int!, $gender: CsgGender!) {
    medigapPremiumRanges(zip: $zip, tobacco: $tobacco, age: $age, gender: $gender) {
      planName
      rangeDescription
    }
  }
`;
