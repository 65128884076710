import { Box } from '@mui/material';
import React from 'react';
import { SideLayout } from '../../shared';
import { MaPlanList, MaFilters } from '../../widgets';
import {
  MaComparisonCard,
  SwitchToMarketplaceButton,
  TakeQuizButton,
} from '../../features';
import SideVideoCta from './ui/side-video-cta';

export default function () {
  return (
    <SideLayout
      title={'Medicare Advantage Plans'}
      subtitle={<TakeQuizButton />}
      slots={{
        start: <MaFilters />,
        side: (
          <>
            <MaComparisonCard />
            <SideVideoCta />
          </>
        ),
        headerSide: (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <SwitchToMarketplaceButton to={'MG'} />
          </Box>
        ),
      }}
    >
      <MaPlanList />
    </SideLayout>
  );
}
