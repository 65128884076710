import { CheckSquare, Square } from '@phosphor-icons/react';
import { Box, Typography } from '@mui/material';
import React from 'react';

interface Props {
  message?: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export default function CheckboxFilter({ message, value, onChange }: Props) {
  if (!message) {
    return null;
  }

  return (
    <Box
      onClick={() => onChange(!value)}
      sx={{
        display: 'flex',
        gap: 0.5,
        alignItems: 'center',
        mt: 2,
        cursor: 'pointer',
      }}
    >
      {value && <CheckSquare size={24} color={'#1C434F'} />}
      {!value && <Square size={24} color={'#1C434F'} />}
      <Typography className={'medium'} color={'#1C434F'}>
        {message}
      </Typography>
    </Box>
  );
}
