import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import App from './app/app';
import { SnackbarProvider } from 'notistack';
import { initTrackEvent } from '@coverright/shared/analytics';
import { Provider as HttpProvider } from 'use-http';
import { Provider as ReduxProvider } from 'react-redux';
import { environment } from './environments/environment';
import { store } from './app/app/store';
import { initKeycloak, KeycloakProvider } from '@coverright/shared/keycloak';
import { initSentry } from '@coverright/shared/config';

initSentry(environment.production, "https://d4f5af392fdf371924bd08d8817eb5b4@o4507526906511360.ingest.us.sentry.io/4507526909460480", [
  /*"localhost",*/ /^https:\/\/v3\.coverright\.com\/plan-finder/,
]);

const root = createRoot(document.getElementById('root') as HTMLElement);

initApp().then(() => {
  root.render(
    <ReduxProvider store={store}>
      <BrowserRouter basename={'/plan-finder'}>
        <SnackbarProvider
          preventDuplicate
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          maxSnack={3}
          autoHideDuration={6000}
        >
          <HttpProvider url={environment.userFlowRestUrl}>
            <KeycloakProvider>
              <App />
            </KeycloakProvider>
          </HttpProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
});

async function initApp() {
  await initKeycloak({
    onLoad: 'login-required',
  });
  initTrackEvent('plan-finder');
}
