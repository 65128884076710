import { MarketplaceType, setMarketplaceType } from '../../shared';
import { Button } from '@mui/material';
import React from 'react';
import { useAppDispatch } from '../../shared/hooks';
import { getId, getLabel } from './lib/lib';
import { useNavigate } from 'react-router';
import { routes } from '../../app/router-new';

export function SwitchToMarketplaceButton({ to }: { to: MarketplaceType }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <Button
      id={getId(to)}
      sx={{ width: { xs: 1, md: 'inherit' } }}
      variant={'contained'}
      color={'info'}
      onClick={() => {
        dispatch(setMarketplaceType(to));
        navigate(routes.planFinder.value);
      }}
    >
      {getLabel(to)}
    </Button>
  );
}
