import * as React from 'react';
import { usePdpFilters } from '../../../features';

export default function useFilterBadges(): {
  label: string;
  onDelete: () => void;
}[] {
  const { filters, buildHandler } = usePdpFilters();

  return React.useMemo(() => {
    return (
      filters?.companies?.map((label) => ({
        label,
        onDelete: () =>
          buildHandler('companies')(
            filters?.companies?.filter((c) => c !== label) || []
          ),
      })) || []
    );
  }, [filters]);
}
