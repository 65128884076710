import { Box, Chip } from '@mui/material';
import * as React from 'react';
import { Backspace } from '@phosphor-icons/react';

interface Props {
  badges: {
    label: string;
    onDelete: () => void;
  }[];
}

export function FilterBadges({ badges }: Props) {
  return (
    <Box sx={{ flexWrap: 'wrap', display: 'flex', gap: 1 }}>
      {badges.map(({ label, onDelete }) => (
        <Chip
          sx={{
            borderRadius: '3px',
            fontSize: 16,
            backgroundColor: '#2C697C4D',
          }}
          key={label}
          label={label}
          onDelete={onDelete}
          data-test={'filter-badge'}
          deleteIcon={<Backspace size={24} weight={'fill'} color={'#1C434F'} />}
        />
      ))}
    </Box>
  );
}
