import { useSaveMedicareQuoteMutation } from '../../../shared/api/medicare';
import { useAppDispatch, useAppSelector } from '../../../shared';
import { medicareQuote, resetCustomAnswers } from '../../../entities';

export default function useResetQuizAnswers() {
  const [save, { isLoading }] = useSaveMedicareQuoteMutation();
  const quote = useAppSelector(medicareQuote);
  const dispatch = useAppDispatch();

  return {
    resetQuizAnswers() {
      dispatch(resetCustomAnswers());
      return save({
        data: {
          customAnswers: JSON.stringify({}),
          id: quote?.id,
        },
      });
    },
    isLoading,
  };
}
